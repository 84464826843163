.Glossary__toolbar___1S2Ju {
  height: var(--navBarHeightPx);
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  background-color: var(--color-navbarBackground);
  border-bottom: 1px solid var(--color-ervGrey);
}

.Glossary__backArrow___1tiwa {
  position: absolute;
  top: 50%;
  left: 3%;
  color: white;
  cursor: pointer;
}

.Glossary__title___3I7SS {
  margin: var(--navBarMargin);
  max-height: var(--navBarMaxHeight);
  text-align: var(--navBarTextAlign);
  font-size: var(--navBarFontSize);
  font-weight: var(--navBarFontWeight);
  padding-top: var(--navBarPaddingTop);
  line-height: var(--navBarLineHeight);
  overflow: var(--navBarOverflow);
  vertical-align: var(--navBarVerticalAlign);
  letter-spacing: -0.4px;
  max-width: var(--navBarMaxWidth);
  width: 500px;
  color: white;
}