.Chat__chat___2tx2z {
  width: 100%;
  background-color: #ededed;
  padding: 20px;
}

.Chat__downloadContainer___14I7c {
  padding-top: 10px;
  left: 15px;
  bottom: 15px;
  color: var(--color-chatColor);
  font-size: 12px;
  display: inline-block;
  position: absolute;
}

.Chat__chatToggleContainer___1usO2 {
  padding-top: 10px;
  margin-left: 75%;
  width: 23%;
  color: var(--color-chatColor);
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.Chat__chatToggle___3qo1Q {
  display: block;
  padding-bottom: 10px;
}

.Chat__switchColors___giYOj {
  color: var(--color-chatColor) !important;
}

.Chat__switchColorsDeactivated___2VseJ {
  color: #535353 !important;
}

.Chat__barColor___3pQmR {
  background-color: var(--color-chatColor) !important;
}

.Chat__barColorDeactivated___P3niQ {
  background-color: #535353 !important;
}

.Chat__downloadButton___24eSG {
  margin-right: 5px;
}