.DrupalListNugget__navigationContainer___7Etjw {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-top: 50px;
}

.DrupalListNugget__buttonLeft___ZH5Mw {
  font-size: 30px;
  left: 0;
  bottom: 0;
  display: inline-block;
  position: absolute;
  color: #9b9b9b;
}

.DrupalListNugget__arrowIcon___1akb4 {
  padding: 2px;
}

.DrupalListNugget__buttonRight___2pyey {
  font-size: 30px;
  right: 0;
  bottom: 0;
  display: inline-block;
  position: absolute;
  color: #9b9b9b;
}

.DrupalListNugget__bulletContainer___yvlE6 {
  font-size: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  display: inline-block;
  position: absolute;
}

.DrupalListNugget__nuggetBullet___3sZJu {
  display: inline-block;
  height: 7px;
  width: 7px;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  margin: 3px;
}

.DrupalListNugget__questionHeader___1zNMB {
  width: 100%;
  font-size: 15px;
  color: #4a4a4a !important;
}

.DrupalListNugget__listItem___3mgDY {
  width: 100%;
  padding: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  min-height: 60px;
  margin: 10px 0;
}

.DrupalListNugget__listTitle___3ARa2 {
  color: var(--color-navbarItemColor);
  margin: 0 0.5em;
}

.DrupalListNugget__listArrow___1UgzW {
  content: "\a";
  border-style: solid;
  border-width: 10px 0px 10px 15px;
  border-color: transparent transparent transparent var(--color-navbarItemColor);
}

.DrupalListNugget__checkIcon___1HwZL {
  height: 50px;
  width: 50px;
  color: white;
  fill: white;
  margin-left: auto;
}

.DrupalListNugget__list___3m-ts {
  width: 94%;
  margin: 0 3%;
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

.DrupalListNugget__infoContainer___231AY {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.DrupalListNugget__infoPage___sYxgU {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.DrupalListNugget__tileArrow___YmkHT {
  position: absolute;
  top: 50%;
  right: 0;
  background-color: var(--color-navbarBackground);
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}