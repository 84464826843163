.Counter__button___3EJX6 {
  display: inline-block !important;
  color: white;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-border-radius: 50% !important;
     -moz-border-radius: 50% !important;
          border-radius: 50% !important;
  min-width: 15% !important;
}

.Counter__points___1nFVS {
  display: inline-block !important;
  font-size: 15px;
  color: black;
  width: 50%;
  padding: 5px !important;
}