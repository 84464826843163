.DrupalQDQuestionEditorNugget__editButton___2ETOD {
  float: right;
}

.DrupalQDQuestionEditorNugget__textField___1yN41 {
  margin-right: 5%;
}

.DrupalQDQuestionEditorNugget__expandIcon___1pnO9 {
  -webkit-transition: none !important;
  -o-transition: none !important;
  -moz-transition: none !important;
  transition: none !important;
  -webkit-transform: translateY(-50%) rotate(0deg) !important;
     -moz-transform: translateY(-50%) rotate(0deg) !important;
      -ms-transform: translateY(-50%) rotate(0deg) !important;
       -o-transform: translateY(-50%) rotate(0deg) !important;
          transform: translateY(-50%) rotate(0deg) !important;
}

.DrupalQDQuestionEditorNugget__addFeaturesButton___1de3b {
  font-size: 40px;
  color: var(--color-tileFontColor);
}