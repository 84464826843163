/*
.nestedFlipTilesContainer {
  height: 100%;
  width: 100%;
}
 */
.ErvNestedTiles__outerPage___21BJm {
  height: 100%;
  width: 100%;
}

.ErvNestedTiles__container___1Lhk7 {
  position: relative;
  float: left;
}

.ErvNestedTiles__demoAnimated___1LHCd {
  max-height: 0 !important;
}

.ErvNestedTiles__tileContainer___2wzqh {
  position: relative;
  display: block;
}

.ErvNestedTiles__imgContainer___2PD_K {
  width: 100%;
  margin-bottom: -10vh;
  z-index: 0;
  position: relative;
}

.ErvNestedTiles__imgContainerErgo___1bPrV {
  width: 100%;
  z-index: 0;
  position: relative;
}

.ErvNestedTiles__open___DaEtT {
  -webkit-transition: width 800ms, height 800ms, -webkit-border-radius 800ms, -webkit-transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1);
  transition: width 800ms, height 800ms, -webkit-border-radius 800ms, -webkit-transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1);
  -o-transition: width 800ms, height 800ms, border-radius 800ms, -o-transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1);
  -moz-transition: width 800ms, height 800ms, border-radius 800ms, transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1), -moz-border-radius 800ms, -moz-transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1);
  transition: width 800ms, height 800ms, border-radius 800ms, transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1);
  transition: width 800ms, height 800ms, border-radius 800ms, transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1), -webkit-border-radius 800ms, -moz-border-radius 800ms, -webkit-transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1), -moz-transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1), -o-transform 800ms cubic-bezier(0.6, -0.2, 0.59, 1);
  will-change: transform, width, height, border-radius;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

.ErvNestedTiles__openFast___LWOUm {
  -webkit-transition: width 400ms, height 400ms, -webkit-border-radius 400ms, -webkit-transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1);
  transition: width 400ms, height 400ms, -webkit-border-radius 400ms, -webkit-transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1);
  -o-transition: width 400ms, height 400ms, border-radius 400ms, -o-transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1);
  -moz-transition: width 400ms, height 400ms, border-radius 400ms, transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1), -moz-border-radius 400ms, -moz-transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1);
  transition: width 400ms, height 400ms, border-radius 400ms, transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1);
  transition: width 400ms, height 400ms, border-radius 400ms, transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1), -webkit-border-radius 400ms, -moz-border-radius 400ms, -webkit-transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1), -moz-transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1), -o-transform 400ms cubic-bezier(0.6, -0.2, 0.59, 1);
  will-change: transform, width, height, border-radius;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

.ErvNestedTiles__move___1PErH {
  -webkit-transition: width 200ms, height 200ms ease-in-out, -webkit-transform 200ms !important;
  transition: width 200ms, height 200ms ease-in-out, -webkit-transform 200ms !important;
  -o-transition: width 200ms, height 200ms ease-in-out, -o-transform 200ms !important;
  -moz-transition: transform 200ms, width 200ms, height 200ms ease-in-out, -moz-transform 200ms !important;
  transition: transform 200ms, width 200ms, height 200ms ease-in-out !important;
  transition: transform 200ms, width 200ms, height 200ms ease-in-out, -webkit-transform 200ms, -moz-transform 200ms, -o-transform 200ms !important;
  will-change: transform, width, height;
}

.ErvNestedTiles__close___5eGyb {
  -webkit-transition: width 800ms, height 800ms, -webkit-border-radius 800ms, -webkit-transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2);
  transition: width 800ms, height 800ms, -webkit-border-radius 800ms, -webkit-transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2);
  -o-transition: width 800ms, height 800ms, border-radius 800ms, -o-transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2);
  -moz-transition: width 800ms, height 800ms, border-radius 800ms, transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2), -moz-border-radius 800ms, -moz-transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2);
  transition: width 800ms, height 800ms, border-radius 800ms, transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2);
  transition: width 800ms, height 800ms, border-radius 800ms, transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2), -webkit-border-radius 800ms, -moz-border-radius 800ms, -webkit-transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2), -moz-transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2), -o-transform 800ms cubic-bezier(0.59, 0, 0.4, 1.2);
  will-change: transform, width, height, border-radius;
}

.ErvNestedTiles__closeFast___2lGJt {
  -webkit-transition: width 400ms, height 400ms, -webkit-border-radius 400ms, -webkit-transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2);
  transition: width 400ms, height 400ms, -webkit-border-radius 400ms, -webkit-transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2);
  -o-transition: width 400ms, height 400ms, border-radius 400ms, -o-transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2);
  -moz-transition: width 400ms, height 400ms, border-radius 400ms, transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2), -moz-border-radius 400ms, -moz-transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2);
  transition: width 400ms, height 400ms, border-radius 400ms, transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2);
  transition: width 400ms, height 400ms, border-radius 400ms, transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2), -webkit-border-radius 400ms, -moz-border-radius 400ms, -webkit-transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2), -moz-transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2), -o-transform 400ms cubic-bezier(0.59, 0, 0.4, 1.2);
  will-change: transform, width, height, border-radius;
}

.ErvNestedTiles__iosFix___2Fa2u {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.ErvNestedTiles__tile___JGL2q {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  z-index: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.ErvNestedTiles__tile___JGL2q .ErvNestedTiles__front___3VxVC, .ErvNestedTiles__tile___JGL2q .ErvNestedTiles__back___EzyV4 {
  display: block;
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100%;
}
.ErvNestedTiles__tile___JGL2q .ErvNestedTiles__back___EzyV4 {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  background-color: var(--color-tileBackgroundColor);
  -webkit-transition: background-color 1500ms;
  -o-transition: background-color 1500ms;
  -moz-transition: background-color 1500ms;
  transition: background-color 1500ms;
}
.ErvNestedTiles__tile___JGL2q .ErvNestedTiles__backIE___2dHW3 {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  background-color: var(--color-tileBackgroundColor);
  z-index: -1;
  -webkit-transition: background-color 1500ms;
  -o-transition: background-color 1500ms;
  -moz-transition: background-color 1500ms;
  transition: background-color 1500ms;
}

.ErvNestedTiles__tileOpen___1F1LG {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;
}

.ErvNestedTiles__tilesButtonsExtra___2cDET {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 10001;
}

.ErvNestedTiles__tilesButtonsClose___2Zn3z {
  position: absolute;
  top: 30px;
  right: 60px;
  z-index: 1;
  width: 0;
  height: 0;
  opacity: 1;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ErvNestedTiles__tilesButtonsClose___2Zn3z.ErvNestedTiles__hiddenByOpacity___3PzgE {
  opacity: 0;
  -webkit-transition: opacity 100ms;
  -o-transition: opacity 100ms;
  -moz-transition: opacity 100ms;
  transition: opacity 100ms;
}
.ErvNestedTiles__tilesButtonsClose___2Zn3z.ErvNestedTiles__posAbs___2oiw1 {
  position: absolute;
}

.ErvNestedTiles__closeButton___26DWW {
  background-color: #555555;
  color: #FFFFFF;
  z-index: 2;
  padding: 6px 9px;
  -webkit-border-radius: 40px;
     -moz-border-radius: 40px;
          border-radius: 40px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 15px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvNestedTiles__background-color1___IrDpn {
  background-color: var(--color-tileColors-1-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color1___IrDpn.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-1-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color1___IrDpn.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-1-hsl-dark);
}

@-webkit-keyframes ErvNestedTiles__pulse-glow1___3Stsg {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-1-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-1-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-1-hsl);
  }
}
.ErvNestedTiles__background-glow1___2kh8K {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: ErvNestedTiles__pulse-glow1___3Stsg;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.ErvNestedTiles__background-color2___1QA7v {
  background-color: var(--color-tileColors-2-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color2___1QA7v,
.ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color2___1QA7v.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-2-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color2___1QA7v,
.ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color2___1QA7v.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-2-hsl-dark);
}

@-webkit-keyframes ErvNestedTiles__pulse-glow2___3LsxE {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-2-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-2-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-2-hsl);
  }
}
.ErvNestedTiles__background-glow2___1vNKc {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: ErvNestedTiles__pulse-glow2___3LsxE;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.ErvNestedTiles__background-color3___1ijBQ {
  background-color: var(--color-tileColors-3-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color3___1ijBQ,
.ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color3___1ijBQ.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-3-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color3___1ijBQ,
.ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color3___1ijBQ.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-3-hsl-dark);
}

@-webkit-keyframes ErvNestedTiles__pulse-glow3___2HIjE {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-3-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-3-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-3-hsl);
  }
}
.ErvNestedTiles__background-glow3___1YYIb {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: ErvNestedTiles__pulse-glow3___2HIjE;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.ErvNestedTiles__background-color4___VvUKr {
  background-color: var(--color-tileColors-4-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color4___VvUKr,
.ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color4___VvUKr.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-4-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color4___VvUKr,
.ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color4___VvUKr.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-4-hsl-dark);
}

@-webkit-keyframes ErvNestedTiles__pulse-glow4___ihwqa {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-4-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-4-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-4-hsl);
  }
}
.ErvNestedTiles__background-glow4___xnjo_ {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: ErvNestedTiles__pulse-glow4___ihwqa;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.ErvNestedTiles__background-color5___1KoJ3 {
  background-color: var(--color-tileColors-5-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color5___1KoJ3,
.ErvNestedTiles__front___3VxVC .ErvNestedTiles__background-color5___1KoJ3.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-5-hsl);
}

.ErvNestedTiles__deactivated___1gh25 .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color1___IrDpn,
.ErvNestedTiles__read___VND2a .ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color5___1KoJ3,
.ErvNestedTiles__back___EzyV4 .ErvNestedTiles__background-color5___1KoJ3.ErvNestedTiles__read___VND2a {
  background-color: var(--color-tileColors-5-hsl-dark);
}

@-webkit-keyframes ErvNestedTiles__pulse-glow5___4zT56 {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-5-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-5-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-5-hsl);
  }
}
.ErvNestedTiles__background-glow5___6b9n_ {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: ErvNestedTiles__pulse-glow5___4zT56;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.ErvNestedTiles__ripple___3i3kg {
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  -webkit-transform: translate3d(-50%, -50%, 0);
     -moz-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  position: absolute;
  opacity: 1;
}

.ErvNestedTiles__rippleEffect___HRU8f {
  -webkit-animation: ErvNestedTiles__rippleDrop___1uvmg 230ms ease-out;
     -moz-animation: ErvNestedTiles__rippleDrop___1uvmg 230ms ease-out;
       -o-animation: ErvNestedTiles__rippleDrop___1uvmg 230ms ease-out;
          animation: ErvNestedTiles__rippleDrop___1uvmg 230ms ease-out;
}

@-webkit-keyframes ErvNestedTiles__rippleDrop___1uvmg {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@-moz-keyframes ErvNestedTiles__rippleDrop___1uvmg {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@-o-keyframes ErvNestedTiles__rippleDrop___1uvmg {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes ErvNestedTiles__rippleDrop___1uvmg {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
.ErvNestedTiles__activateIcon___2Ukec {
  z-index: 33;
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvNestedTiles__noTilesText___J4Ld0 {
  font-size: 20px;
  width: 100%;
  text-align: center;
  padding-top: 50px;
  height: 100vh;
  background-color: white;
}

.ErvNestedTiles__grayscale___1LITZ {
  -webkit-filter: grayscale(1) brightness(0.95);
          filter: grayscale(1) brightness(0.95);
}

.ErvNestedTiles__opaqueContainer___2Yrzz {
  opacity: 0.2 !important;
}

.ErvNestedTiles__overlay___D3_a_ {
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  opacity: 0;
  background-color: #a8a8a8;
  -webkit-transition: opacity 800ms;
  -o-transition: opacity 800ms;
  -moz-transition: opacity 800ms;
  transition: opacity 800ms;
}

.ErvNestedTiles__contentContainer___3g8LJ {
  width: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.ErvNestedTiles__contentContainer___3g8LJ.ErvNestedTiles__overflowHidden___1aYNK {
  overflow-y: hidden;
}
.ErvNestedTiles__contentContainer___3g8LJ.ErvNestedTiles__overflowVisible___1CzQN {
  overflow-y: auto;
}

.ErvNestedTiles__withBottomBar___3bsdo {
  height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .ErvNestedTiles__withBottomBar___3bsdo {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .ErvNestedTiles__withBottomBar___3bsdo {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.ErvNestedTiles__withoutBottomBar___2e7-L {
  height: -webkit-calc(100vh - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--navBarHeightPx));
  height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .ErvNestedTiles__withoutBottomBar___2e7-L {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .ErvNestedTiles__withoutBottomBar___2e7-L {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.ErvNestedTiles__fullScreenTile___28zVd {
  height: -webkit-calc(100vh - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--navBarHeightPx));
  height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .ErvNestedTiles__fullScreenTile___28zVd {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant) - 30px);
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant) - 30px);
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant) - 30px);
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .ErvNestedTiles__fullScreenTile___28zVd {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env) - 30px);
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env) - 30px);
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env) - 30px);
  }
}

.ErvNestedTiles__tileContainerSmall___3QBZN {
  display: inline-block;
  position: relative;
  width: -webkit-calc(100% - 50px);
  width: -moz-calc(100% - 50px);
  width: calc(100% - 50px);
  float: right;
}

.ErvNestedTiles__progressBarContainer___3pxK- {
  display: inline-block;
  position: relative;
  width: 50px;
  float: left;
  margin-top: 50px;
  will-change: height;
  -webkit-transition: height 500ms;
  -o-transition: height 500ms;
  -moz-transition: height 500ms;
  transition: height 500ms;
}

.ErvNestedTiles__progressBar___7VkKU {
  width: 1px;
  background-color: var(--basecolor-grey);
  margin: 0 auto;
  will-change: height;
  -webkit-transition: height 500ms;
  -o-transition: height 500ms;
  -moz-transition: height 500ms;
  transition: height 500ms;
}

.ErvNestedTiles__progressBubbleContainer___1QxxR {
  background-color: var(--color-backgroundColor);
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  position: absolute;
  overflow: hidden;
}

.ErvNestedTiles__progressBubble___mLhzx {
  margin: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--basecolor-grey);
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
}
.ErvNestedTiles__progressBubble___mLhzx svg {
  width: 14px !important;
  height: 14px !important;
  margin: 2px !important;
}

.ErvNestedTiles__done___3qGrL {
  background-color: var(--basecolor-grey) !important;
}

.ErvNestedTiles__progressStarted___3NQHr {
  background-color: var(--basecolor-grey);
  width: 50%;
  margin-left: 50%;
  height: 100%;
}

.ErvNestedTiles__progressDone___35ClG {
  background-color: var(--basecolor-grey) !important;
  color: white !important;
}

.ErvNestedTiles__dateContainerWithProgress___2t2fK {
  width: -webkit-calc(100% - 115px);
  width: -moz-calc(100% - 115px);
  width: calc(100% - 115px);
  height: 30px;
  position: relative;
  float: left;
}

.ErvNestedTiles__dateContainer___gQkUC {
  width: -webkit-calc(100% - 55px);
  width: -moz-calc(100% - 55px);
  width: calc(100% - 55px);
  height: 30px;
  position: relative;
  float: left;
}

.ErvNestedTiles__dateLine___p32hI {
  background-color: var(--basecolor-grey);
  position: relative;
  top: 50%;
  height: 1px;
  width: 100%;
  margin: 0 auto;
}

.ErvNestedTiles__date___3aJCv {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--basecolor-grey);
  font-size: 12px;
  background-color: var(--color-backgroundColor);
  padding: 10px;
}

.ErvNestedTiles__nextArrow___1ih0H {
  position: absolute;
  top: 50%;
  z-index: 100;
  left: 5px !important;
  height: 30px !important;
  width: 30px !important;
  -webkit-transform: translateY(-50%) !important;
     -moz-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
       -o-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}

.ErvNestedTiles__backArrow___2G9L4 {
  position: absolute;
  top: 50%;
  z-index: 100;
  right: 0px !important;
  height: 30px !important;
  width: 30px !important;
  -webkit-transform: translateY(-50%) !important;
     -moz-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
       -o-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}

.ErvNestedTiles__arrowContainerLeft___3gbFT {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  background-color: white;
  opacity: 50%;
  position: absolute;
  left: -35px;
  top: -webkit-calc(1000px + 50% - 70px);
  top: -moz-calc(1000px + 50% - 70px);
  top: calc(1000px + 50% - 70px);
  z-index: 500;
}

.ErvNestedTiles__arrowContainerRight___C4lWM {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  background-color: white;
  opacity: 50%;
  position: absolute;
  right: -35px;
  top: -webkit-calc(1000px + 50% - 70px);
  top: -moz-calc(1000px + 50% - 70px);
  top: calc(1000px + 50% - 70px);
  z-index: 500;
}

.ErvNestedTiles__scrollIndicator___cD0pq {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  position: fixed;
  background: white;
  overflow: hidden;
  bottom: 10px;
  z-index: 200;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 50%;
}

.ErvNestedTiles__firstArrow___G3OY4 {
  position: absolute;
  -webkit-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s ease-in-out infinite;
     -moz-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s ease-in-out infinite;
       -o-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s ease-in-out infinite;
          animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s ease-in-out infinite;
  width: 74%;
  text-align: center;
  margin: 10px auto;
  font-size: 30px;
  overflow: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 7px;
  color: var(--color-buttonBackground);
}

.ErvNestedTiles__secondArrow___3mIk- {
  position: absolute;
  -webkit-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s 2s ease-in-out infinite;
     -moz-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s 2s ease-in-out infinite;
       -o-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s 2s ease-in-out infinite;
          animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s 2s ease-in-out infinite;
  width: 74%;
  text-align: center;
  margin: 10px auto;
  font-size: 30px;
  overflow: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0px;
  color: var(--color-buttonBackground);
}

.ErvNestedTiles__thirdArrow___2X8le {
  position: absolute;
  -webkit-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s 2.5s ease-in-out infinite;
     -moz-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s 2.5s ease-in-out infinite;
       -o-animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s 2.5s ease-in-out infinite;
          animation: ErvNestedTiles__arrow-movement___1lGoe 1.5s 2.5s ease-in-out infinite;
  width: 74%;
  text-align: center;
  margin: 10px auto;
  font-size: 30px;
  overflow: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -7px;
  color: var(--color-buttonBackground);
}

@-webkit-keyframes ErvNestedTiles__arrow-movement___1lGoe {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes ErvNestedTiles__arrow-movement___1lGoe {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes ErvNestedTiles__arrow-movement___1lGoe {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ErvNestedTiles__arrow-movement___1lGoe {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ErvNestedTiles__extraPaddingContainer___3Ty9R {
  height: var(--bottomBarHeightPx);
  width: 100%;
  float: left;
}