.QRCodeLogin__container___HRUrO {
  display: block;
  margin: 20px auto;
}

.QRCodeLogin__headline___1p4V4 {
  font-weight: bold;
  text-align: center;
}

.QRCodeLogin__infotext___3-W2n {
  text-align: center;
  color: #424242;
}

.QRCodeLogin__wrapper___26ebf {
  margin: 0px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.QRCodeLogin__progressText___PkfFt {
  text-align: center;
  margin-top: 20px;
}

.QRCodeLogin__startButton___Y1aGq {
  display: block;
  margin: 20px auto;
  width: 100%;
}

.QRCodeLogin__refreshButton___1ajcg {
  display: block;
  margin: 20px auto;
}

.QRCodeLogin__errorMessage___3TCae {
  text-align: center;
}