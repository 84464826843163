:not(.ptr-loading) .ptr-element {
  -webkit-transform: translate3d(0, -50px, 0);
     -moz-transform: translate3d(0, -50px, 0);
          transform: translate3d(0, -50px, 0);
}
.ptr-element {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.refresh-view {
  height: 100%;
  overflow-y: auto;
  -ms-touch-action: pan-y !important;
      touch-action: pan-y !important;
  -ms-overflow-style: none;
}
.refresh-view::-webkit-scrollbar {
  display: none;
}
.ptr-refresh .ptr-element .genericon {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
   -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
       transform: rotate(270deg);
}
.ptr-element .genericon {
  opacity: 0.6;
  font-size: 34px;
  width: auto;
  height: auto;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
       transform: rotate(90deg);
  margin-top: 5px;
}
.loading {
  text-align: center;
  opacity: 0.4;
  margin: 12px 0 0 5px;
  display: none;
}
.ptr-loading .loading {
  display: block;
}
.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
   -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
       transform: scale(0.3);
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  -webkit-animation: ptr-loading 0.4s infinite alternate;
  -moz-animation: ptr-loading 0.4s infinite alternate;
    -o-animation: ptr-loading 0.4s infinite alternate;
       animation: ptr-loading 0.4s infinite alternate;
}
.loading-ptr-1 {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s !important;
    -o-animation-delay: 0s !important;
       animation-delay: 0s !important;
}
.loading-ptr-2 {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s !important;
    -o-animation-delay: 0.2s !important;
       animation-delay: 0.2s !important;
}
.loading-ptr-3 {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s !important;
    -o-animation-delay: 0.4s !important;
       animation-delay: 0.4s !important;
}
@-webkit-keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}
@-moz-keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    -moz-transform: translateY(0) scale(0.3);
         transform: translateY(0) scale(0.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
         transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}
@-o-keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    -o-transform: translateY(0) scale(0.3);
       transform: translateY(0) scale(0.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    -o-transform: scale(1);
       transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}
@keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    -moz-transform: translateY(0) scale(0.3);
      -o-transform: translateY(0) scale(0.3);
         transform: translateY(0) scale(0.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
      -o-transform: scale(1);
         transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}
.ptr-loading .refresh-view, .ptr-reset .refresh-view, .ptr-loading .ptr-element, .ptr-reset .ptr-element {
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ptr-reset .refresh-view {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
       transform: translate3d(0, 0, 0);
}
.ptr-loading .refresh-view {
  -webkit-transform: translate3d(0, 30px, 0);
  -moz-transform: translate3d(0, 30px, 0);
       transform: translate3d(0, 30px, 0);
}
.mdl-textfield__label:after {
  background-color: var(--color-textfieldCaption) !important;
}
.mdl-textfield--floating-label.is-focused .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  color: var(--color-textfieldCaption) !important;
  top: 24px !important;
}
.mdl-checkbox.is-checked.kesiCheckBox .mdl-checkbox__box-outline {
  border: 2px solid #0066b3 !important;
}
.mdl-checkbox.is-checked.kesiCheckBox .mdl-checkbox__tick-outline {
  background: #0066b3 !important;
  -webkit-mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iY2xpcCI+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Ik0gMCwwIDAsMSAxLDEgMSwwIDAsMCB6IE0gMC44NTM0Mzc1LDAuMTY3MTg3NSAwLjk1OTY4NzUsMC4yNzMxMjUgMC40MjkzNzUsMC44MDM0Mzc1IDAuMzIzMTI1LDAuOTA5Njg3NSAwLjIxNzE4NzUsMC44MDM0Mzc1IDAuMDQwMzEyNSwwLjYyNjg3NSAwLjE0NjU2MjUsMC41MjA2MjUgMC4zMjMxMjUsMC42OTc1IDAuODUzNDM3NSwwLjE2NzE4NzUgeiIKICAgICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bWFzayBpZD0ibWFzayIgbWFza1VuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgbWFza0NvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICA8cGF0aAogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiCiAgICAgICAgIHN0eWxlPSJmaWxsOiNmZmZmZmY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0CiAgICAgd2lkdGg9IjEiCiAgICAgaGVpZ2h0PSIxIgogICAgIHg9IjAiCiAgICAgeT0iMCIKICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KPC9zdmc+Cg==") !important;
          mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iY2xpcCI+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Ik0gMCwwIDAsMSAxLDEgMSwwIDAsMCB6IE0gMC44NTM0Mzc1LDAuMTY3MTg3NSAwLjk1OTY4NzUsMC4yNzMxMjUgMC40MjkzNzUsMC44MDM0Mzc1IDAuMzIzMTI1LDAuOTA5Njg3NSAwLjIxNzE4NzUsMC44MDM0Mzc1IDAuMDQwMzEyNSwwLjYyNjg3NSAwLjE0NjU2MjUsMC41MjA2MjUgMC4zMjMxMjUsMC42OTc1IDAuODUzNDM3NSwwLjE2NzE4NzUgeiIKICAgICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bWFzayBpZD0ibWFzayIgbWFza1VuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgbWFza0NvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICA8cGF0aAogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiCiAgICAgICAgIHN0eWxlPSJmaWxsOiNmZmZmZmY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0CiAgICAgd2lkdGg9IjEiCiAgICAgaGVpZ2h0PSIxIgogICAgIHg9IjAiCiAgICAgeT0iMCIKICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KPC9zdmc+Cg==") !important;
}

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  font-family: var(--fontFamily), sans-serif;
  line-height: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  overflow: hidden;
}

body {
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

[data-reactroot] {
  overflow: hidden;
}

.base__spinner___Csj0o {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 0;
  right: 0;
}

.base__pageScroll___1dJa6 {
  padding-top: 64px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-background-size: cover !important;
     -moz-background-size: cover !important;
          background-size: cover !important;
}

.base__pageNoScroll___31Dow {
  padding-top: 64px;
  height: 100%;
  overflow: hidden;
  -webkit-background-size: cover !important;
     -moz-background-size: cover !important;
          background-size: cover !important;
}

.base__scrollbarMinWidth___1PoZz::-webkit-scrollbar {
  width: 0;
  height: 0;
}

h1 {
  font-family: var(--fontFamily);
}

div {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

a {
  color: #0d47a1;
}

a:visited {
  color: #424242;
}

:focus {
  outline: none !important;
}