.Quiz__content___3NXbx {
  margin: 0 6px 6px 6px;
}

.Quiz__placeHolder___2Sf-L {
  height: 10px;
}

.Quiz__contentHome___2aI2x {
  text-align: center;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 300;
  height: 100%;
}

.Quiz__contentHomeScroll___1QxVq {
  /*padding-top: 40px;*/
  text-align: center;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 300;
  overflow: auto;
  height: 100%;
}

.Quiz__rank___nmDDk {
  font-weight: 800;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.Quiz__greeting___1ltsM {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  padding: 40px 10px 20px 10px;
}

.Quiz__innerHeader___3QX6N {
  font-size: 14px;
  font-weight: 300;
  padding: 4% 0 0;
  width: 98%;
  text-align: left;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  max-height: 8%;
}

.Quiz__scrollArrow___1RMqI {
  position: absolute;
  bottom: 10px;
  right: 7%;
  font-size: 0.85em;
  font-style: italic;
}

.Quiz__backText___3csdq {
  margin-left: -2px;
  font-size: 16px;
  padding-right: 11px;
  vertical-align: middle;
}

.Quiz__backButton___3u312 {
  cursor: pointer;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  padding: 5px;
}

.Quiz__innerContent___2kTeh {
  margin-top: 50px;
}

.Quiz__innerContentScroll___2hY4A {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.Quiz__homeText___3LoNp {
  color: #FFFFFF;
}

.button-home {
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
  margin-bottom: 40px;
  border: 1px var(--color-buttonBorderSecondary) solid;
  color: var(--color-buttonCaptionSecondary);
}

/*
.pointDiffToNextPlayer {
  font-size: 14px;
  padding: 10px 10px 20px 10px;
}

.info {
  padding-left: 10px;
  padding-right: 10px;
}

.quizHomeFooter {
  //display: block;
  //position: absolute;
  bottom: 0;
  height: 100px;
  background-color: transparent;
  width: 100%;
  pointer-events: none;
}

.logo {
  width: 50px;
  margin: auto;
  display: block;
}

.frame_training_mode {
  height: 100%;
}

.bottom_training_mode {
  background-color: #892C36;
  height: 36px;
  color: #f2f2f2;
  text-align: center;
  font-size: 18px;
  line-height: 36px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
*/
/* Change React-MDL colors for radio buttons*/
.mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #69635D;
}

.mdl-radio__inner-circle {
  top: 4px !important;
  left: 0 !important;
  width: 19px !important;
  height: 19px !important;
  z-index: 2 !important;
  -webkit-transition-duration: initial !important;
     -moz-transition-duration: initial !important;
       -o-transition-duration: initial !important;
          transition-duration: initial !important;
}

.mdl-radio__ripple-container .mdl-ripple {
  background: #69635D;
}

.mdl-radio__outer-circle {
  width: 20px !important;
  height: 20px !important;
}

/* Change React-MDL colors for checkboxes */