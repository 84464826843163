.QuizRoundOverview__answerStatusContainer___HDWoR {
  margin: 1px;
  display: inline-block;
}

.QuizRoundOverview__answerStatusContainerJumping___3914o {
  margin: 1px;
  display: inline-block;
  -webkit-animation: QuizRoundOverview__jump___38DkV 0.6s linear infinite;
     -moz-animation: QuizRoundOverview__jump___38DkV 0.6s linear infinite;
       -o-animation: QuizRoundOverview__jump___38DkV 0.6s linear infinite;
          animation: QuizRoundOverview__jump___38DkV 0.6s linear infinite;
}

.QuizRoundOverview__questionNum___1S0B8 {
  text-align: center;
}

.QuizRoundOverview__answerStatus___nJVcV {
  /*background: #FFFFFF;
  border: 1px solid #cccccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;*/
  padding: 2px;
  position: relative;
}
.QuizRoundOverview__answerStatus___nJVcV .QuizRoundOverview__empty___2lPV- {
  padding: 8px;
}
.QuizRoundOverview__answerStatus___nJVcV .QuizRoundOverview__correct___2b1pu, .QuizRoundOverview__answerStatus___nJVcV .QuizRoundOverview__wrong___3zF1E {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  padding-top: 1px;
  position: relative;
}
@media screen and (max-height: 620px) {
  .QuizRoundOverview__answerStatus___nJVcV .QuizRoundOverview__correct___2b1pu, .QuizRoundOverview__answerStatus___nJVcV .QuizRoundOverview__wrong___3zF1E {
    font-size: 14px;
    width: 2.5vh;
    height: 2.5vh;
  }
}

.QuizRoundOverview__icon___2v41L {
  font-size: 20px !important;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@media screen and (max-height: 620px) {
  .QuizRoundOverview__icon___2v41L {
    font-size: 16px !important;
    font-size: 2.3vh !important;
  }
}

.QuizRoundOverview__fixedFooter___24wSF, .QuizRoundOverview__smallFixedFooter___Whv42 {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 5%;
  left: 0;
}

.QuizRoundOverview__button___3a7Y6 {
  width: 85%;
  max-width: 450px;
}

.QuizRoundOverview__columns___cDyEU {
  width: 44%;
  display: inline-block;
  text-align: center;
  color: var(--color-buttonBackground);
  height: 100%;
}

.QuizRoundOverview__midColumn___2L7cv {
  width: 12%;
  font-size: 0.9em;
}
.QuizRoundOverview__midColumn___2L7cv.QuizRoundOverview__inKesi___31l7g {
  position: relative;
  top: -10px;
}

.QuizRoundOverview__userName___1nPUi {
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
  max-height: 50%;
}

.QuizRoundOverview__userNameText___3GoR- {
  display: block;
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
}

.QuizRoundOverview__thumb___3NRrs {
  width: 40px;
  height: 40px;
  /*border: 1px solid #ccc;*/
  -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
          border-radius: 50px;
  /*margin: 7px 0px;*/
  overflow: hidden;
  /*vertical-align: middle;*/
  display: inline-block;
}
.QuizRoundOverview__thumb___3NRrs img {
  max-width: 40px;
}

.QuizRoundOverview__score___uNaP1 {
  font-weight: bold;
  width: 40%;
  position: relative;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

.QuizRoundOverview__score___uNaP1::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.QuizRoundOverview__scoreText___3aCZv {
  position: absolute;
  font-size: 2.6rem;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QuizRoundOverview__scoreInfo___XTpsu {
  position: absolute;
  font-size: 0.6rem;
  left: 50%;
  bottom: 10%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.QuizRoundOverview__smallFeedbackOverlayIcon___3NHm_ {
  font-size: 18px;
  color: #FFFFFF;
  -webkit-background-size: contain !important;
     -moz-background-size: contain !important;
          background-size: contain !important;
  width: 17.6%;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
   -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
       transform: translateX(-50%);
  text-align: center;
  vertical-align: middle;
}
.QuizRoundOverview__smallFeedbackOverlayIcon___3NHm_.QuizRoundOverview__leagueMode___3VL2Q {
  width: 260px;
  max-width: 40vw;
  height: 160px;
  -webkit-border-radius: 15px;
     -moz-border-radius: 15px;
          border-radius: 15px;
}

.QuizRoundOverview__smallFeedbackOverlayIcon___3NHm_::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.QuizRoundOverview__feedbackOverlayIcon___1Gokf {
  font-size: 18px;
  color: #FFFFFF;
  -webkit-background-size: contain !important;
     -moz-background-size: contain !important;
          background-size: contain !important;
  width: 130px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
   -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
       transform: translateX(-50%);
  text-align: center;
  vertical-align: middle;
}
.QuizRoundOverview__feedbackOverlayIcon___1Gokf.QuizRoundOverview__leagueMode___3VL2Q {
  width: 260px;
  max-width: 40vw;
  height: 160px;
  -webkit-border-radius: 15px;
     -moz-border-radius: 15px;
          border-radius: 15px;
}

.QuizRoundOverview__feedbackOverlayIcon___1Gokf::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.QuizRoundOverview__innerFeedbackContainer___1Jh0R {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QuizRoundOverview__text_league___21kIV {
  font-size: 16px;
}

.QuizRoundOverview__overviewContainer___2XPQn {
  padding: 0 3%;
  height: 90%;
  height: -webkit-calc(100% - 30px);
  height: -moz-calc(100% - 30px);
  height: calc(100% - 30px);
}
.QuizRoundOverview__overviewContainer___2XPQn.QuizRoundOverview__browser___35UL- {
  max-width: 500px;
  margin: auto;
  height: auto;
}

.QuizRoundOverview__row___2DXX1 {
  height: 17%;
  max-height: 17%;
  overflow: hidden;
}

.QuizRoundOverview__rowBrowser___vgmoi {
  height: 6vh;
  max-height: 40px;
  overflow: hidden;
}

.QuizRoundOverview__lastRowBrowser___1QElo {
  height: 7vh;
  max-height: 50px;
  overflow: hidden;
}

.QuizRoundOverview__lastRow___piODK {
  height: 18%;
  max-height: 18%;
}

.QuizRoundOverview__rowContainer___mhv7M {
  height: 60%;
}

.QuizRoundOverview__header___3mISU {
  height: 100%;
}

.QuizRoundOverview__headerContainer___QDhNy {
  height: 40%;
  min-height: 170px;
  overflow: hidden;
}

.QuizRoundOverview__sequenceOuter___2fgHz {
  position: relative;
}

.QuizRoundOverview__sequence___1MwWC {
  width: 54px;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 1px;
  position: absolute;
  height: 24px;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  background-color: white;
}

.QuizRoundOverview__answerContainer___1ln6O {
  display: inline-block;
  position: relative;
  z-index: 5;
}

@-webkit-keyframes QuizRoundOverview__wave___KDcb0 {
  0%, 60%, 100% {
    -webkit-transform: initial;
            transform: initial;
  }
  30% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@-moz-keyframes QuizRoundOverview__wave___KDcb0 {
  0%, 60%, 100% {
    -moz-transform: initial;
         transform: initial;
  }
  30% {
    -moz-transform: translateY(-10px);
         transform: translateY(-10px);
  }
}

@-o-keyframes QuizRoundOverview__wave___KDcb0 {
  0%, 60%, 100% {
    -o-transform: initial;
       transform: initial;
  }
  30% {
    -o-transform: translateY(-10px);
       transform: translateY(-10px);
  }
}

@keyframes QuizRoundOverview__wave___KDcb0 {
  0%, 60%, 100% {
    -webkit-transform: initial;
       -moz-transform: initial;
         -o-transform: initial;
            transform: initial;
  }
  30% {
    -webkit-transform: translateY(-10px);
       -moz-transform: translateY(-10px);
         -o-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}
@-webkit-keyframes QuizRoundOverview__jump___38DkV {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  20% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  80% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes QuizRoundOverview__jump___38DkV {
  0% {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
  20% {
    -moz-transform: translate3d(0, -5px, 0);
         transform: translate3d(0, -5px, 0);
  }
  50% {
    -moz-transform: translate3d(0, -20px, 0);
         transform: translate3d(0, -20px, 0);
  }
  80% {
    -moz-transform: translate3d(0, -5px, 0);
         transform: translate3d(0, -5px, 0);
  }
  100% {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes QuizRoundOverview__jump___38DkV {
  0% {
    transform: translate3d(0, 0, 0);
  }
  20% {
    transform: translate3d(0, -5px, 0);
  }
  50% {
    transform: translate3d(0, -20px, 0);
  }
  80% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes QuizRoundOverview__jump___38DkV {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  20% {
    -webkit-transform: translate3d(0, -5px, 0);
       -moz-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -20px, 0);
       -moz-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  80% {
    -webkit-transform: translate3d(0, -5px, 0);
       -moz-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.QuizRoundOverview__innerIcon___1MJ59 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100% !important;
  width: 100% !important;
}