.DrupalLecture__headerContainer___1kz2l {
  min-height: var(--headerMinHeight);
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 5%;
}

.DrupalLecture__headerContainerFullScreen___22pHI {
  min-height: var(--headerMinHeight);
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-left: 5%;
  padding-right: 5%;
}

.DrupalLecture__titleContainer___3DKPH {
  display: block;
  height: 90%;
  width: 100%;
  z-index: 5;
  position: relative;
}

.DrupalLecture__lectureFrame___yafnW {
  width: 100vw;
  -webkit-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
  will-change: opacity;
  -webkit-overflow-scrolling: touch;
}

.DrupalLecture__innerFrame___2Cmcd {
  position: relative;
  left: 0px;
  right: 0px;
  max-width: var(--app-maxWidth);
  min-height: 100%;
  margin: auto;
  background-color: var(--color-tileBackgroundColor);
  overflow-x: visible;
}

.DrupalLecture__innerFrameMobile___1X7pi {
  left: 0;
  right: 0;
  max-width: 100%;
  margin: auto;
  background-color: var(--color-tileBackgroundColor);
}

.DrupalLecture__headerTitle___-m5qx {
  display: block;
  font-size: 27px;
  line-height: 30px;
}

.DrupalLecture__headerDay___2dJYl {
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: var(--basecolor-black);
}

.DrupalLecture__headerSubtitle___aD-WQ {
  margin-top: 20px;
  display: block;
  font-size: 20px;
}

.DrupalLecture__innerTitleContainer___2oedS {
  display: inline-block;
  width: 80%;
  float: left;
}

.DrupalLecture__innerTitleContainerCentered___bki4Z {
  display: inline-block;
  width: 100%;
  float: left;
  text-align: center;
}

.DrupalLecture__fullWidth___2VYQe {
  width: 100%;
}

.DrupalLecture__headerIcon___WHplb {
  display: inline-block;
  width: 20%;
  float: right;
}

.DrupalLecture__jumpButtons___1IomO {
  display: block;
  position: absolute;
  bottom: 0;
  min-height: 25px;
  height: 10%;
  padding: 0 10%;
  margin-bottom: 5px;
  width: 100%;
  font-size: 1.1em;
  z-index: 5;
}

.DrupalLecture__rightJumpButtons___1NaXm {
  position: absolute;
  right: 10%;
  top: -3px;
  height: 100%;
}

.DrupalLecture__leftJumpButtons___37ckw {
  position: absolute;
  left: 10%;
  top: 0;
  height: 100%;
}

.DrupalLecture__time___1Z_-9 {
  display: inline-block;
}

.DrupalLecture__singleJumpButton___3zGSg {
  margin-left: 30px;
  display: inline-block;
}

.DrupalLecture__readIcon___3Gm5b {
  margin-left: 30px;
  position: relative;
  display: inline-block;
  font-size: 20px;
  bottom: 3px;
}

.DrupalLecture__favIcon___3IlYN {
  margin-left: 30px;
  display: inline-block;
  vertical-align: top;
}
.DrupalLecture__favIcon___3IlYN img {
  height: 24px;
}

.DrupalLecture__jumpToTop___mE2f0 {
  background-color: #9b9b9b;
  width: 50px;
  height: 50px;
  margin: 20px auto;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  will-change: background-color;
  -webkit-transition: background-color 1000ms;
  -o-transition: background-color 1000ms;
  -moz-transition: background-color 1000ms;
  transition: background-color 1000ms;
  position: relative;
  cursor: pointer;
}

.DrupalLecture__jumpToTopIcon___1w6vu {
  color: #FFFFFF;
  font-size: 50px;
  will-change: transform;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  -o-transition: -o-transform 1000ms;
  -moz-transition: transform 1000ms, -moz-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate3d(-50%, -55%, 0);
     -moz-transform: translate3d(-50%, -55%, 0);
          transform: translate3d(-50%, -55%, 0);
}

.DrupalLecture__jumpToTopExplanation___22SmH {
  position: absolute;
  left: -300%;
  top: 50%;
  color: #3b3b3b;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
       transform: translate3d(-50%, -50%, 0);
  max-width: 500px;
}

@media only screen and (max-width: 1023px) {
  .DrupalLecture__jumpToTopExplanation___22SmH {
    left: -170%;
  }
}
@media only screen and (max-width: 600px) {
  .DrupalLecture__jumpToTopExplanation___22SmH {
    left: -100%;
  }
}
.DrupalLecture__likeContainer___1Lm5u {
  width: 90%;
  height: 50px;
  margin: 0 5%;
  position: relative;
}

.DrupalLecture__svgLikeButton___3Aazr {
  display: inline-block;
  width: 45px;
  height: auto;
  position: absolute;
  right: 65px;
}

.DrupalLecture__likeButton___3jTCj {
  color: #7a7a7a;
  font-size: 30px;
  display: inline-block;
  position: absolute;
  right: 65px;
}

.DrupalLecture__likeButtonActivated___IBkTH {
  color: var(--color-navbarBackground);
  font-size: 30px;
  display: inline-block;
  position: absolute;
  right: 65px;
}

.DrupalLecture__likeText___3F7lz {
  display: inline-block;
  font-size: 17px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.DrupalLecture__extraPadding___1fp1p {
  padding-left: -webkit-calc((100vw - 1280px) / 2);
  padding-left: -moz-calc((100vw - 1280px) / 2);
  padding-left: calc((100vw - 1280px) / 2);
  padding-right: -webkit-calc((100vw - 1280px) / 2);
  padding-right: -moz-calc((100vw - 1280px) / 2);
  padding-right: calc((100vw - 1280px) / 2);
  padding-bottom: 25px;
}

.DrupalLecture__extraPaddingMobileBrowser___EsMwu {
  padding-bottom: 280px;
}