.NavigationBar__navbar___3tmKJ {
  height: 44px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  right: 0;
  will-change: background-color;
  -webkit-transition: background-color 500ms ease-in-out;
  -o-transition: background-color 500ms ease-in-out;
  -moz-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .NavigationBar__navbar___3tmKJ {
    top: var(--safe-area-top-constant);
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .NavigationBar__navbar___3tmKJ {
    top: var(--safe-area-top-env);
  }
}

.NavigationBar__navbarQuiz_____6w3 {
  height: 44px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  right: 0;
  will-change: background-color;
  -webkit-transition: background-color 500ms ease-in-out;
  -o-transition: background-color 500ms ease-in-out;
  -moz-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;
}

.NavigationBar__navbarBackgroundImageContainer___23BCb {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.NavigationBar__navbarBackgroundImage___dYn0K {
  width: 100%;
  height: 100%;
}

.NavigationBar__navbarLogo___3yzDx {
  width: auto;
  position: absolute;
  left: var(--navBarLogoLeft);
  height: var(--navBarLogoHeight);
  top: var(--navBarLogoTop);
  z-index: 1;
}
.NavigationBar__navbarLogo___3yzDx img {
  -webkit-box-shadow: var(--navBarLogoBoxShadow);
     -moz-box-shadow: var(--navBarLogoBoxShadow);
          box-shadow: var(--navBarLogoBoxShadow);
}

.NavigationBar__navbarLogoRight___2D_fA {
  width: auto;
  position: absolute;
  left: 90%;
  height: var(--navBarLogoHeight);
  top: var(--navBarLogoTop);
  z-index: 1;
}

.NavigationBar__title___grD0l {
  margin: var(--navBarMargin);
  max-height: var(--navBarMaxHeight);
  text-align: var(--navBarTextAlign);
  font-size: var(--navBarFontSize);
  font-weight: var(--navBarFontWeight);
  padding-top: -webkit-calc(var(--navBarPaddingTop) + 10px);
  padding-top: -moz-calc(var(--navBarPaddingTop) + 10px);
  padding-top: calc(var(--navBarPaddingTop) + 10px);
  line-height: var(--navBarLineHeight);
  overflow: var(--navBarOverflow);
  vertical-align: var(--navBarVerticalAlign);
  letter-spacing: -0.4px;
  width: 300px;
  width: var(--navBarTitleWidth);
  z-index: 1;
  position: relative;
}

.NavigationBar__titleImage___3Fo3W {
  max-height: 80%;
  height: var(--navBarLogoHeight);
  text-align: var(--navBarTextAlign);
  font-size: var(--navBarFontSize);
  font-weight: var(--navBarFontWeight);
  padding-top: var(--navBarIconPaddingTop);
  line-height: var(--navBarLineHeight);
  overflow: var(--navBarOverflow);
  vertical-align: var(--navBarVerticalAlign);
  letter-spacing: -0.4px;
  max-width: var(--navBarMaxWidth);
  margin: var(--navBarMargin);
  margin-top: var(--navBarMarginTop);
  z-index: 1;
}

.NavigationBar__ervLogo___ssdC4 {
  max-height: 30px;
  margin-top: 15px;
  width: auto;
  line-height: inherit;
  z-index: 1;
}

.NavigationBar__count___3taZ7 {
  position: absolute;
  height: 28px;
  width: 28px;
  padding: 0 10px;
  top: 30px;
  -webkit-border-radius: 28px;
     -moz-border-radius: 28px;
          border-radius: 28px;
  z-index: 1;
}

.NavigationBar__specificSettingsIconContainer___3V0he {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-left: 10px;
}

.NavigationBar__specificSettingsIcon___1_b07 {
  width: 100%;
  height: 100%;
  margin-top: 7px;
}

.NavigationBar__icon___3n3FX {
  display: inline-block;
  font-size: 25px;
  width: auto;
  line-height: 25px;
  vertical-align: bottom;
  margin-left: 10px;
  cursor: pointer;
  z-index: 1;
}
.NavigationBar__icon___3n3FX img {
  height: 25px;
  fill: var(--color-navbarItemColor);
}
.NavigationBar__icon___3n3FX svg path, .NavigationBar__icon___3n3FX svg rect, .NavigationBar__icon___3n3FX svg polygon, .NavigationBar__icon___3n3FX svg circle {
  fill: var(--color-navbarItemColor);
  height: 25px;
  width: auto;
  margin-top: 5px;
}

.NavigationBar__farRightImage___2-19W {
  position: absolute;
  right: 10px;
  top: 5px;
  width: auto;
  vertical-align: top;
}
.NavigationBar__farRightImage___2-19W img {
  height: 40px;
}
.NavigationBar__farRightImage___2-19W svg path, .NavigationBar__farRightImage___2-19W svg rect, .NavigationBar__farRightImage___2-19W svg polygon, .NavigationBar__farRightImage___2-19W svg circle {
  height: 40px;
  width: auto;
}

.NavigationBar__settingsIcon___1cKgD {
  vertical-align: bottom;
  z-index: 1;
}
.NavigationBar__settingsIcon___1cKgD svg path, .NavigationBar__settingsIcon___1cKgD svg rect, .NavigationBar__settingsIcon___1cKgD svg polygon, .NavigationBar__settingsIcon___1cKgD svg circle {
  fill: var(--color-ervBlue);
}

.NavigationBar__innerCount___37FPt {
  font-size: 16px;
  color: #FFFFFF;
  -webkit-transform: translate3d(-35%, 15%, 0);
     -moz-transform: translate3d(-35%, 15%, 0);
          transform: translate3d(-35%, 15%, 0);
  text-align: center;
  width: 28px;
  overflow: var(--navBarOverflow);
  vertical-align: var(--navBarVerticalAlign);
}

#NavigationBar__burger___1WIXk {
  display: inline-block;
  height: 35px;
  width: auto;
  padding: 7px;
  bottom: 20px;
  position: absolute;
  z-index: 1;
}

#NavigationBar__back___pAw0U {
  display: inline-block;
  position: relative;
  height: 35px;
  width: 50px;
  padding-left: 7px;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  z-index: 1;
}
#NavigationBar__back___pAw0U .NavigationBar__backIcon___i1BUj {
  position: relative !important;
  font-size: var(--navBarBackIconSize) !important;
  top: var(--navBarBackIconTop) !important;
}
#NavigationBar__back___pAw0U .NavigationBar__backText___1xT0r {
  text-transform: capitalize;
  margin-left: 5px;
  letter-spacing: -0.4px;
  font-weight: 400;
  font-size: 18px;
}

.NavigationBar__backSvg___2Wg4G {
  left: 0;
  width: 25px;
  top: 8px;
  position: absolute;
  z-index: 1;
}

.NavigationBar__userSvgIcon___8fUzz {
  width: 25px;
  z-index: 1;
}

/* #burger2 {
  position: absolute;
  height: 35px;
  width: auto;
  padding: 7px;
  top: 24px;
  left: 10px;
} */
#NavigationBar__refresh___3pJGa {
  position: absolute;
  height: 28px;
  width: auto;
  padding: 7px;
  top: 27px;
  right: 0;
  z-index: 1;
}

.NavigationBar__tcSettings___3U7mf {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 1;
}

.NavigationBar__warningsIcon___csy8J {
  position: absolute;
  right: 50px;
  top: 12px;
}

.NavigationBar__homeLogoLeft___2NS0P {
  position: absolute;
  top: 25px;
  will-change: left;
  -webkit-transition: left 300ms;
  -o-transition: left 300ms;
  -moz-transition: left 300ms;
  transition: left 300ms;
  z-index: 3;
}

.NavigationBar__closeButton___2SoOl {
  position: absolute;
  right: 10px;
  top: 40px;
  width: 40px;
  height: 40px;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .NavigationBar__closeButton___2SoOl {
    top: -webkit-calc(var(--safe-area-top-constant));
    top: -moz-calc(var(--safe-area-top-constant));
    top: calc(var(--safe-area-top-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .NavigationBar__closeButton___2SoOl {
    top: -webkit-calc(var(--safe-area-top-env));
    top: -moz-calc(var(--safe-area-top-env));
    top: calc(var(--safe-area-top-env));
  }
}
.NavigationBar__closeButton___2SoOl svg {
  width: 100% !important;
  height: 100% !important;
}
.NavigationBar__closeButton___2SoOl svg path, .NavigationBar__closeButton___2SoOl svg rect, .NavigationBar__closeButton___2SoOl svg polygon, .NavigationBar__closeButton___2SoOl svg circle {
  fill: var(--color-navbarItemColor);
}