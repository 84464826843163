.DrupalFormNugget__info___7cOOi {
  font-size: 20px;
  margin-top: 25%;
  margin-left: 10%;
  position: relative;
  width: 80%;
  text-align: center;
}

.DrupalFormNugget__sendButton___1Nhyx {
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
     -moz-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
       -o-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
  position: absolute;
}

.DrupalFormNugget__error___vau8x {
  color: red;
  font-size: 20px;
  margin-left: 10%;
  position: relative;
  width: 80%;
  text-align: center;
}

.DrupalFormNugget__muiColor___1hMlw {
  color: var(--color-navbarBackground) !important;
}