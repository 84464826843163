.FullscreenOverlay__root___3FxtJ {
  z-index: 34998 !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.FullscreenOverlay__rootIE___3032M {
  z-index: 3000001 !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.FullscreenOverlay__paper___2pFEn {
  background-color: var(--color-ervBackground);
}