.QuizTournamentCodeEntry__mainWrapper___2F1Fu {
  font-family: sans-serif;
  padding: 20px 20px 20px 20px;
  word-wrap: break-word;
  height: -webkit-calc(100vh - 64px);
  height: -moz-calc(100vh - 64px);
  height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
}

.QuizTournamentCodeEntry__subWrapper___3-tyP {
  text-align: center;
  position: relative;
  top: 10%;
}

.QuizTournamentCodeEntry__heading___27RHy {
  color: #0F3764;
  font-size: 20px;
  clear: both;
  text-align: center;
  position: relative;
  top: 10%;
}

.QuizTournamentCodeEntry__mainHeading___1RLnV {
  color: #0092b7;
  font-size: medium;
  font-weight: bold;
}

.QuizTournamentCodeEntry__subHeading___GcUOb {
  margin-top: 10%;
  font-weight: bold;
}

.QuizTournamentCodeEntry__buttonShake___XLzs9 {
  -webkit-animation-duration: 5s;
     -moz-animation-duration: 5s;
       -o-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
     -moz-animation-iteration-count: infinite;
       -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in;
     -moz-animation-timing-function: ease-in;
       -o-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-name: QuizTournamentCodeEntry__shakeAnim___3vbAA;
     -moz-animation-name: QuizTournamentCodeEntry__shakeAnim___3vbAA;
       -o-animation-name: QuizTournamentCodeEntry__shakeAnim___3vbAA;
          animation-name: QuizTournamentCodeEntry__shakeAnim___3vbAA;
}

@-webkit-keyframes QuizTournamentCodeEntry__shakeAnim___3vbAA {
  0% {
    left: 0;
  }
  1% {
    left: -5px;
  }
  2% {
    left: 7px;
  }
  3% {
    left: -10px;
  }
  4% {
    left: 10px;
  }
  5% {
    left: -7px;
  }
  6% {
    left: 5px;
  }
  7% {
    left: 0;
  }
}

@-moz-keyframes QuizTournamentCodeEntry__shakeAnim___3vbAA {
  0% {
    left: 0;
  }
  1% {
    left: -5px;
  }
  2% {
    left: 7px;
  }
  3% {
    left: -10px;
  }
  4% {
    left: 10px;
  }
  5% {
    left: -7px;
  }
  6% {
    left: 5px;
  }
  7% {
    left: 0;
  }
}

@-o-keyframes QuizTournamentCodeEntry__shakeAnim___3vbAA {
  0% {
    left: 0;
  }
  1% {
    left: -5px;
  }
  2% {
    left: 7px;
  }
  3% {
    left: -10px;
  }
  4% {
    left: 10px;
  }
  5% {
    left: -7px;
  }
  6% {
    left: 5px;
  }
  7% {
    left: 0;
  }
}

@keyframes QuizTournamentCodeEntry__shakeAnim___3vbAA {
  0% {
    left: 0;
  }
  1% {
    left: -5px;
  }
  2% {
    left: 7px;
  }
  3% {
    left: -10px;
  }
  4% {
    left: 10px;
  }
  5% {
    left: -7px;
  }
  6% {
    left: 5px;
  }
  7% {
    left: 0;
  }
}
.QuizTournamentCodeEntry__textField___1ptXA {
  min-width: 300px !important;
  margin-bottom: 40px !important;
  margin-top: 40px !important;
}