.UserInfo__userInfoContainer___3FNrc {
  height: -webkit-calc(100vh - 64px);
  height: -moz-calc(100vh - 64px);
  height: calc(100vh - 64px);
  width: -webkit-calc(100% + 20px);
  width: -moz-calc(100% + 20px);
  width: calc(100% + 20px);
  overflow: auto;
  background-color: white;
}

.UserInfo__userDataContainer___ZqPZU {
  width: 80%;
  margin: 0 auto;
}

.UserInfo__userDataOuterContainer___hJb5R {
  padding-top: 20px;
}

.UserInfo__bookedCoursesContainer___2lFHt {
  width: -webkit-calc(80% + 20px);
  width: -moz-calc(80% + 20px);
  width: calc(80% + 20px);
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.UserInfo__list___3Xly7 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
  line-height: 10px;
  margin: auto;
  max-width: 1000px;
  width: 80%;
}

.UserInfo__sublist___2YhpG {
  margin-bottom: 0px;
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
  border-bottom: 1px solid var(--color-tableDivider);
}

.UserInfo__titleSublist___1AVaG {
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

.UserInfo__listItem___hOKm7 {
  padding-left: 12%;
  height: 64px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--color-tableDivider);
  line-height: 64px;
  cursor: pointer;
}

.UserInfo__listItemMobile___nmv91 {
  padding-left: 50px;
  height: 40px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--color-tableDivider);
  line-height: 64px;
}

.UserInfo__listItem___hOKm7:last-child {
  border-bottom: none;
}

.UserInfo__arrowIcon___3jNQU {
  width: 30px !important;
  height: 30px !important;
  color: black;
  margin-top: 3px;
}

.UserInfo__openArrowIcon___2_9h9 {
  width: 30px !important;
  height: 30px !important;
  color: black;
  margin-top: 3px;
  -webkit-transform: rotate(90deg);
     -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
       -o-transform: rotate(90deg);
          transform: rotate(90deg);
}

.UserInfo__courselistItem___3P63x {
  padding-left: 0px;
  height: 64px;
  background-color: var(--color-spocListItem);
  font-weight: bold;
  width: 100%;
  position: relative;
  line-height: 64px;
  cursor: pointer;
}

.UserInfo__courselistItemMobile___2GsTj {
  padding-left: 0px;
  height: 40px;
  background-color: var(--color-spocListItem);
  font-weight: bold;
  width: 100%;
  position: relative;
  line-height: 64px;
}

.UserInfo__spoclistItem___2yQPc {
  padding-left: 10px;
  height: 64px;
  background-color: var(--color-spocListItem);
  font-weight: bold;
  width: 100%;
  position: relative;
  line-height: 64px;
  cursor: pointer;
}

.UserInfo__spoclistItemMobile___lJPjO {
  padding-left: 10px;
  height: 40px;
  background-color: var(--color-spocListItem);
  font-weight: bold;
  width: 100%;
  position: relative;
  line-height: 64px;
}

.UserInfo__listTitle___FjScD {
  padding-left: 10px;
  height: 20px;
  width: 100%;
  font-weight: bold;
  position: relative;
  line-height: 20px;
  text-align: left;
}

.UserInfo__column___3DwXf {
  margin-left: 15px;
  text-align: left;
  position: relative;
  display: inline-block;
  width: 70%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  height: 100%;
  vertical-align: top;
}

.UserInfo__spocColumn___332hp {
  margin-left: 15px;
  text-align: left;
  position: relative;
  display: inline-block;
  width: 70%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  height: 100%;
  vertical-align: top;
}

.UserInfo__spocTitle___3ccyO {
  font-weight: 900;
  font-size: 14px;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.UserInfo__spocTitleMobile___1_EtJ {
  font-weight: 900;
  font-size: 10px;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.UserInfo__title___2lA2w {
  font-size: 14px;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.UserInfo__titleMobile___FdOD2 {
  font-size: 10px;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.UserInfo__imageColumn___iksvG {
  width: 15%;
  text-align: center;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.UserInfo__spocImageColumn___-fcTR {
  width: 10%;
  text-align: center;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.UserInfo__spocArrowColumn___3dzFg {
  width: 10%;
  text-align: center;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.UserInfo__readColumn___1Aaj- {
  width: 10%;
  text-align: center;
  right: 0px;
  position: absolute;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.UserInfo__titleReadColumn___i19R3 {
  width: 10%;
  text-align: center;
  right: 0px;
  position: relative;
  display: inline-block;
  font-weight: 400;
  top: -5px;
  height: 100%;
  vertical-align: top;
}

.UserInfo__titleColumn___3JpNl {
  text-align: left;
  position: relative;
  display: inline-block;
  width: 65%;
  font-weight: 400;
  height: 100%;
  vertical-align: top;
}

.UserInfo__icon___2Q5fq {
  position: absolute;
  top: 50%;
  width: 26px;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.UserInfo__background___1hFOU {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 42px;
  height: 42px;
}

.UserInfo__backgroundMobile___1CqwJ {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22px;
  height: 22px;
}

.UserInfo__percentageCircle___3SN5O {
  position: absolute;
  top: 0;
  left: 0;
}

.UserInfo__iconContainer___1SWJP {
  height: 50px;
  width: 50px;
  -webkit-transform: translateY(15%);
     -moz-transform: translateY(15%);
      -ms-transform: translateY(15%);
       -o-transform: translateY(15%);
          transform: translateY(15%);
  margin: 0 auto;
}

.UserInfo__iconContainerMobile___3D7Ak {
  height: 30px;
  width: 30px;
  -webkit-transform: translateY(15%);
     -moz-transform: translateY(15%);
      -ms-transform: translateY(15%);
       -o-transform: translateY(15%);
          transform: translateY(15%);
  margin: 0 auto;
}

.UserInfo__iconBackground___Y6ln0 {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: var(--color-tileFontColor);
  height: 46px;
  width: 46px;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  overflow: hidden;
}

.UserInfo__iconBackgroundMobile___XUR58 {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: var(--color-tileFontColor);
  height: 26px;
  width: 26px;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  overflow: hidden;
}

.UserInfo__percentageContainer___SCbhG {
  height: 100%;
  width: 100%;
  position: relative;
  display: inline-block;
}

.UserInfo__userText___1s-9s {
  font-weight: bold;
  color: black;
  font-size: 20px;
  padding-bottom: 20px;
}

.UserInfo__userTextSmall___OvI5S {
  font-size: 16px;
}

.UserInfo__readIcon___3fh1f {
  display: block;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
          border-radius: 30px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.UserInfo__readIconMobile___1XbOp {
  display: block;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
          border-radius: 20px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.UserInfo__innerReadIcon___1FTk- {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.UserInfo__readIconImg___wtbcs {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  left: 5px;
}

.UserInfo__readIconImgMobile___R5nKC {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
}

.UserInfo__unreadIcon___1Ob13 {
  display: block;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
          border-radius: 30px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.UserInfo__unreadIconMobile___3NXmC {
  display: block;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
          border-radius: 20px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.UserInfo__userDataTitle___3OS9k {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0 10px;
  font-size: 1.5rem;
  font-weight: 400;
}

.UserInfo__greyedOut___1hmfX {
  -webkit-filter: grayscale(1) brightness(0.95);
          filter: grayscale(1) brightness(0.95);
}