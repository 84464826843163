.AppCordovaGeneric__pageScroll___2kcAw {
  padding-top: var(--navBarHeightPx);
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
@supports (padding-bottom: constant(safe-area-inset-top)) {
  .AppCordovaGeneric__pageScroll___2kcAw {
    padding-top: -webkit-calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
    padding-top: -moz-calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
    padding-top: calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
  }
}
@supports (padding-bottom: env(safe-area-inset-top)) {
  .AppCordovaGeneric__pageScroll___2kcAw {
    padding-top: -webkit-calc(var(--navBarHeightPx) + var(--safe-area-top-env));
    padding-top: -moz-calc(var(--navBarHeightPx) + var(--safe-area-top-env));
    padding-top: calc(var(--navBarHeightPx) + var(--safe-area-top-env));
  }
}

.AppCordovaGeneric__pageNoScroll___h-95T {
  padding-top: var(--navBarHeightPx);
  height: 100%;
  overflow: hidden;
  -webkit-transform-origin: top left;
     -moz-transform-origin: top left;
      -ms-transform-origin: top left;
       -o-transform-origin: top left;
          transform-origin: top left;
}
@supports (padding-bottom: constant(safe-area-inset-top)) {
  .AppCordovaGeneric__pageNoScroll___h-95T {
    padding-top: -webkit-calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
    padding-top: -moz-calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
    padding-top: calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
  }
}
@supports (padding-bottom: env(safe-area-inset-top)) {
  .AppCordovaGeneric__pageNoScroll___h-95T {
    padding-top: -webkit-calc(var(--navBarHeightPx) + var(--safe-area-top-env));
    padding-top: -moz-calc(var(--navBarHeightPx) + var(--safe-area-top-env));
    padding-top: calc(var(--navBarHeightPx) + var(--safe-area-top-env));
  }
}

.AppCordovaGeneric__ieZFix___lqSoT {
  position: relative;
  z-index: 3000000;
  height: 100vh;
}

.AppCordovaGeneric__noPointerEvents___FA8B3 {
  pointer-events: none;
}

.AppCordovaGeneric__eulatext___2UBxf {
  font-size: 15px;
  width: 94%;
  margin: 0 auto;
  padding-top: 30px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.AppCordovaGeneric__toolbar___2B4aJ {
  padding-top: 60px;
  height: var(--navBarHeightPx);
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  background-color: var(--color-navbarBackground);
  border-bottom: 1px solid var(--color-ervGrey);
}

.AppCordovaGeneric__close___2aSUN {
  position: absolute;
  left: 20px;
  top: 50%;
  color: var(--color-ervBlue);
}

.AppCordovaGeneric__backArrow___2Ah97 {
  position: absolute;
  top: 50%;
  left: 3%;
  color: var(--color-ervBlue);
}

.AppCordovaGeneric__title___3R9NK {
  margin: var(--navBarMargin);
  margin-top: -55px;
  max-height: var(--navBarMaxHeight);
  text-align: var(--navBarTextAlign);
  font-size: var(--navBarFontSize);
  font-weight: var(--navBarFontWeight);
  padding-top: var(--navBarPaddingTop);
  line-height: var(--navBarLineHeight);
  overflow: var(--navBarOverflow);
  vertical-align: var(--navBarVerticalAlign);
  letter-spacing: -0.4px;
  max-width: var(--navBarMaxWidth);
  width: 500px;
}

.AppCordovaGeneric__bold___2fm8z {
  font-weight: 600 !important;
}

.AppCordovaGeneric__progressBarContainer___1bXQK {
  overflow: hidden;
  -webkit-border-radius: 15px;
     -moz-border-radius: 15px;
          border-radius: 15px;
  background-color: lightgray;
  width: 80%;
  height: 30px;
  position: relative;
  margin: 0 auto;
}

.AppCordovaGeneric__progressBar___6qi_1 {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  background-color: var(--color-buttonBackground);
}

.AppCordovaGeneric__safeAreaContainerTop___1gzoS {
  position: absolute;
  top: 0;
  height: 0;
  width: 100%;
  will-change: background-color;
  -webkit-transition: background-color 500ms ease-in-out;
  -o-transition: background-color 500ms ease-in-out;
  -moz-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .AppCordovaGeneric__safeAreaContainerTop___1gzoS {
    height: -webkit-calc(var(--safe-area-top-constant));
    height: -moz-calc(var(--safe-area-top-constant));
    height: calc(var(--safe-area-top-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .AppCordovaGeneric__safeAreaContainerTop___1gzoS {
    height: -webkit-calc(var(--safe-area-top-env));
    height: -moz-calc(var(--safe-area-top-env));
    height: calc(var(--safe-area-top-env));
  }
}

.AppCordovaGeneric__safeAreaContainerBottom___1HofX {
  position: absolute;
  bottom: 0;
  height: 0;
  width: 100%;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .AppCordovaGeneric__safeAreaContainerBottom___1HofX {
    height: -webkit-calc(var(--safe-area-bottom-constant));
    height: -moz-calc(var(--safe-area-bottom-constant));
    height: calc(var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .AppCordovaGeneric__safeAreaContainerBottom___1HofX {
    height: -webkit-calc(var(--safe-area-bottom-env));
    height: -moz-calc(var(--safe-area-bottom-env));
    height: calc(var(--safe-area-bottom-env));
  }
}

.AppCordovaGeneric__ergoUpdateContainer___1KesG {
  position: absolute;
  left: 10px;
  width: 70px;
  height: 70px;
  overflow: hidden;
  top: -webkit-calc(var(--navBarHeightPx) + 50px);
  top: -moz-calc(var(--navBarHeightPx) + 50px);
  top: calc(var(--navBarHeightPx) + 50px);
  z-index: 500;
  background-color: white;
  color: var(--color-ergoDarkRed);
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  border: 2px solid var(--color-ergo-warmGrey2);
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .AppCordovaGeneric__ergoUpdateContainer___1KesG {
    top: -webkit-calc(var(--navBarHeightPx) + 50px + constant(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
    top: -moz-calc(var(--navBarHeightPx) + 50px + constant(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
    top: calc(var(--navBarHeightPx) + 50px + constant(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .AppCordovaGeneric__ergoUpdateContainer___1KesG {
    top: -webkit-calc(var(--navBarHeightPx) + 50px + env(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
    top: -moz-calc(var(--navBarHeightPx) + 50px + env(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
    top: calc(var(--navBarHeightPx) + 50px + env(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
  }
}

.AppCordovaGeneric__updateContainer___1NwUt {
  position: absolute;
  left: 10px;
  width: 70px;
  height: 70px;
  overflow: hidden;
  top: -webkit-calc(var(--navBarHeightPx) + 50px);
  top: -moz-calc(var(--navBarHeightPx) + 50px);
  top: calc(var(--navBarHeightPx) + 50px);
  z-index: 500;
  background-color: white;
  color: var(--color-buttonBackground);
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  border: 2px solid var(--color-buttonBackground);
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .AppCordovaGeneric__updateContainer___1NwUt {
    top: -webkit-calc(var(--navBarHeightPx) + 50px + constant(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
    top: -moz-calc(var(--navBarHeightPx) + 50px + constant(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
    top: calc(var(--navBarHeightPx) + 50px + constant(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .AppCordovaGeneric__updateContainer___1NwUt {
    top: -webkit-calc(var(--navBarHeightPx) + 50px + env(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
    top: -moz-calc(var(--navBarHeightPx) + 50px + env(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
    top: calc(var(--navBarHeightPx) + 50px + env(safe-area-inset-top, 0) / var(--htmlSafeAreaPaddingDivisor));
  }
}

.AppCordovaGeneric__updateContainerSpinnerContainer___30102 {
  position: relative;
  width: 100%;
  height: 55%;
}

.AppCordovaGeneric__updateContainerSpinner___C2F16 {
  position: absolute;
  left: 15px;
  width: 36px !important;
  height: 36px !important;
  color: var(--color-ergoDarkRed) !important;
}

.AppCordovaGeneric__updateContainerPercentage___2qDrw {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: var(--color-ergoDarkRed);
  font-size: 9px;
}

.AppCordovaGeneric__updateContainerTitle___1FsdM {
  height: 40%;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 5px;
  font-size: 9px;
  line-height: 9px;
  text-align: center;
  color: var(--color-ergoDarkRed);
}

.AppCordovaGeneric__demoFlag___32jLl {
  position: absolute;
  color: #ff9b39;
  background-color: white;
  top: 4%;
  -webkit-transform: rotate(-45deg);
     -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
       -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: -27%;
  padding: 1%;
  z-index: 10001;
  width: 300px;
  text-align: center;
  border: 3px solid #ff9b39;
}