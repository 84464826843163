.TopicChooser__container___2Qual {
  width: 90%;
  margin: 10% auto;
  text-align: left;
  overflow: hidden;
  height: 100%;
}

.TopicChooser__wrapper___11aVg {
  overflow-x: hidden;
  overflow-y: scroll;
  height: -webkit-calc(90% - var(--bottomBarHeightPx));
  height: -moz-calc(90% - var(--bottomBarHeightPx));
  height: calc(90% - var(--bottomBarHeightPx));
}

.TopicChooser__headline___1plUq {
  font-size: 1.8em;
  font-weight: bold;
  font-family: Arial;
}

.TopicChooser__infotext___2ZzdX {
  font-family: Arial;
  margin: 1em 0;
  font-size: 1.1em;
  line-height: 1.4em;
}