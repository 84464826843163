.DrupalVideoNugget__videodiv___2edjH {
  position: relative;
  /** This is a strange bugfix. The original fullscreen icon on chrome at first makes the window fullscreen and then
      - on a second click - the video.

      In safari, however, the original fullscreen button opens the video in fullscreen with overlayed divs from somewhere
      else in the app if these divs are position:fixed under certain circumstances. If, however, this button is hidden by CSS
      like here, the button IS STILL visible but WORKS correctly.
  */
}
.DrupalVideoNugget__videodiv___2edjH video, .DrupalVideoNugget__videodiv___2edjH img {
  width: 100%;
}

.DrupalVideoNugget__blink___D0vKI {
  -webkit-animation: DrupalVideoNugget__blink___D0vKI 2s linear 0s infinite;
     -moz-animation: DrupalVideoNugget__blink___D0vKI 2s linear 0s infinite;
       -o-animation: DrupalVideoNugget__blink___D0vKI 2s linear 0s infinite;
          animation: DrupalVideoNugget__blink___D0vKI 2s linear 0s infinite;
}

@-moz-keyframes DrupalVideoNugget__blink___D0vKI {
  50% {
    opacity: 0;
  }
}

@-o-keyframes DrupalVideoNugget__blink___D0vKI {
  50% {
    opacity: 0;
  }
}

@keyframes DrupalVideoNugget__blink___D0vKI {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes DrupalVideoNugget__blink___D0vKI {
  50% {
    opacity: 0;
  }
}
.DrupalVideoNugget__videoButtonsSmall___Wv9MX {
  padding-right: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 25px;
  margin-top: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.DrupalVideoNugget__videoButtonsSmall___Wv9MX.DrupalVideoNugget__inactive___3ZhY5 {
  opacity: 0.5;
}
.DrupalVideoNugget__videoButtonsSmall___Wv9MX .DrupalVideoNugget__icon___2gCAa {
  /**
    As we cannot say for sure what is going on in the server deployed theme, we have to set all properties here
    that might affect the layout of this <img />
   */
  margin: 0 3px 5px 0;
  padding: 0;
  padding-top: 3px;
  border: 0;
  vertical-align: baseline;
  height: 17px;
  width: auto;
}
.DrupalVideoNugget__videoButtonsSmall___Wv9MX .DrupalVideoNugget__caption___1D3yI {
  color: white;
  float: left;
  line-height: 17px;
  margin: 0 10px;
  font-size: 12px;
}

.DrupalVideoNugget__videoButtons___2hD-c {
  height: 33px;
  margin: 15px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.DrupalVideoNugget__videoButtons___2hD-c.DrupalVideoNugget__inactive___3ZhY5 {
  opacity: 0.5;
}
.DrupalVideoNugget__videoButtons___2hD-c .DrupalVideoNugget__icon___2gCAa {
  /**
    As we cannot say for sure what is going on in the server deployed theme, we have to set all properties here
    that might affect the layout of this <img />
   */
  margin: 0 5px 10px 0;
  padding: 0;
  padding-top: 6px;
  border: 0;
  vertical-align: baseline;
  height: 25px;
  width: auto;
}
.DrupalVideoNugget__videoButtons___2hD-c .DrupalVideoNugget__caption___1D3yI {
  color: white;
  float: left;
  line-height: 25px;
  margin: 0 10px;
  font-size: 14px;
}

.DrupalVideoNugget__singleButton___1JSTU, .DrupalVideoNugget__fullscreenButton___VHP-Q, .DrupalVideoNugget__fullscreenButtonSmall___18VKI {
  padding: 0px;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  top: 2px;
  right: 0px;
  color: var(--color-buttonCaption);
}

.DrupalVideoNugget__fullscreenButton___VHP-Q {
  padding-top: 0px;
  top: 8px;
}

.DrupalVideoNugget__titleSmall___1cfK9 {
  width: -webkit-calc(100% - 24px);
  width: -moz-calc(100% - 24px);
  width: calc(100% - 24px);
}

.DrupalVideoNugget__leftButton___2-eam {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  color: var(--color-buttonCaption);
}

.DrupalVideoNugget__rightButton___3sSBH {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  color: var(--color-buttonCaption);
}

.DrupalVideoNugget__doubleButtonContainer___3pEt0 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
     -moz-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
  grid-gap: 3px;
}

.DrupalVideoNugget__fullscreenButton___VHP-Q, .DrupalVideoNugget__fullscreenButtonSmall___18VKI {
  position: absolute;
  right: 0;
  height: 27px;
  padding-right: 0;
}

.DrupalVideoNugget__fullscreenButtonSmall___18VKI {
  height: 25px;
}

.DrupalVideoNugget__chapterCaption___Mqgid {
  font-size: 17pt;
  padding: 20px 0;
  font-weight: 400;
}

.DrupalVideoNugget__chapters___2crT2 .DrupalVideoNugget__chapterPreview___rkcR0 {
  display: inline-block;
  overflow: hidden;
  margin: 0 10px 10px 0;
  -webkit-transform: translate3d(0, 0, 0);
}
.DrupalVideoNugget__chapters___2crT2 .DrupalVideoNugget__chapterPreview___rkcR0.DrupalVideoNugget__second___2SCd8 {
  margin-right: 0;
}
.DrupalVideoNugget__chapters___2crT2 .DrupalVideoNugget__chapterPreview___rkcR0.DrupalVideoNugget__fourth___HIHVR {
  margin-right: 0;
}
.DrupalVideoNugget__chapters___2crT2 .DrupalVideoNugget__chapterPreview___rkcR0 .DrupalVideoNugget__chapter___hZuIl {
  position: relative;
  width: 100%;
  height: 100%;
  background: no-repeat center center;
}
.DrupalVideoNugget__chapters___2crT2 .DrupalVideoNugget__chapterPreview___rkcR0 .DrupalVideoNugget__chapter___hZuIl .DrupalVideoNugget__timeWrapper___2wQt3 {
  width: 100%;
  height: 100%;
  text-align: center;
}
.DrupalVideoNugget__chapters___2crT2 .DrupalVideoNugget__chapterPreview___rkcR0 .DrupalVideoNugget__chapter___hZuIl .DrupalVideoNugget__timeWrapper___2wQt3 .DrupalVideoNugget__time___358mN {
  display: inline-block;
  text-align: center;
  color: white;
  margin: 0 auto;
  padding: 3px 3px;
  background-color: rgba(126, 126, 126, 0.2);
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  font-size: 28px;
  font-weight: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.DrupalVideoNugget__chapters___2crT2 .DrupalVideoNugget__chapterPreview___rkcR0 .DrupalVideoNugget__chapter___hZuIl .DrupalVideoNugget__text___q3F03 {
  position: absolute;
  bottom: 5px;
  left: 0;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  font-size: 18px;
  width: 90%;
  margin: 0 5%;
  padding-left: 5px;
  padding-right: 2px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  background-color: rgba(126, 126, 126, 0.5);
  text-align: left;
}

.DrupalVideoNugget__transcriptContainer___10PRg {
  max-width: 100%;
  font-size: 12px;
  padding: 20px 0;
  max-height: 200px;
  overflow: auto;
}

.DrupalVideoNugget__transcriptTitle___Yztuw {
  font-size: 16px;
  font-weight: bold;
  line-height: 60px;
}

.DrupalVideoNugget__transcriptArrow___2wGKO {
  width: 60px !important;
  height: 60px !important;
}

.DrupalVideoNugget__lineChapter___3Wtv8 {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  padding: 5px;
}

.DrupalVideoNugget__lineText___lpsbO {
  color: black;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  font-size: 18px;
  padding-right: 10px;
  text-align: left;
  display: inline-block;
}

.DrupalVideoNugget__lineTime___3p4Si {
  color: red;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  font-size: 18px;
  text-align: left;
  display: inline-block;
}