.BottomBar__bottomBar___F2Ns6 {
  width: 100%;
  font-size: 14px;
  height: 64px;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  right: 0;
  background-color: var(--color-bottomBackground);
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.BottomBar__container___1bp_t {
  height: 54px;
  position: relative;
  display: inline-block;
  margin-top: 10px;
  text-align: center;
}

.BottomBar__title___2abNo {
  font-size: 10px;
  line-height: 11px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translate3d(-50%, 0, 0);
       transform: translate3d(-50%, 0, 0);
  height: 23px;
  width: 95%;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  overflow: hidden;
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.BottomBar__iconActive___13eYE {
  height: 24px;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
     -moz-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  position: absolute;
}
.BottomBar__iconActive___13eYE svg path, .BottomBar__iconActive___13eYE svg rect, .BottomBar__iconActive___13eYE svg polygon, .BottomBar__iconActive___13eYE svg circle {
  fill: var(--color-ervBlue);
  height: 25px;
  width: auto;
  margin-top: 5px;
}

.BottomBar__iconUnactive___3U42U {
  height: 24px;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
     -moz-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  position: absolute;
}
.BottomBar__iconUnactive___3U42U svg path, .BottomBar__iconUnactive___3U42U svg rect, .BottomBar__iconUnactive___3U42U svg polygon, .BottomBar__iconUnactive___3U42U svg circle {
  fill: var(--color-ervGrey);
  height: 25px;
  width: auto;
  margin-top: 5px;
}