@font-face {
  font-family: Oxygen;
  src: url(a1a81ded4d6c8d46ca74f6376384a1eb.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Oxygen;
  src: url(86b2d759c9be9099ab86fc4c911a0137.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Oxygen;
  src: url(db8f6db6f6ab2757a756ca98c74db634.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Oxygen;
  src: url(74bb0110dc0186ccd59b75bbf3579b37.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Oxygen;
  src: url(a511462e682b83674e8fd666e629bc2a.woff2) format("woff2");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: Oxygen;
  src: url(a039075894057c5100c0a9a777a192fc.woff) format("woff");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: Oxygen;
  src: url(d7142d836aac71164ca9cdae10c115fb.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Oxygen;
  src: url(7fc4ab8a95641afff8352f449fac44c9.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}