.QuizTournamentEndScreen__mainWrapper___3T3bH {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #2F3235;
}

.QuizTournamentEndScreen__scoreTable___2KF_2 {
  margin: 10px auto 0;
  color: #DC911B;
  font-size: 35px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QuizTournamentEndScreen__winnerHeading___3VyYR {
  position: absolute;
  font-weight: bold;
  font-size: 40px;
  top: 100px;
  width: 100%;
  text-align: center;
  color: #DC911B;
}

.QuizTournamentEndScreen__contentWrapper___1kiS3 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.QuizTournamentEndScreen__confetti___3oLOi {
  width: 100%;
  height: 100%;
}

.QuizTournamentEndScreen__leaveBtn___2uuwH {
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-top: 30px !important;
}

.QuizTournamentEndScreen__teamPointsContainer___labVO {
  width: 80%;
  padding-bottom: 80%;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  position: relative;
  margin: 0 auto;
  border: 1px solid #DC911B;
}

.QuizTournamentEndScreen__teamPoints___2moZL {
  width: 100%;
  text-align: center;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  font-size: 100px;
  padding-top: 10px;
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
}