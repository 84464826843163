/*
.nestedFlipTilesContainer {
  height: 100%;
  width: 100%;
}
 */
/* Hide scrollbar for Chrome, Safari and Opera */
.NestedFlipTiles__hideScrollbar___2aiG3::-webkit-scrollbar {
  display: none;
}

.NestedFlipTiles__hideScrollbar___2aiG3 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.NestedFlipTiles__heading___Ghq60 {
  position: relative;
  color: #025693;
  font-size: 48px;
  vertical-align: bottom;
  height: 100%;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .NestedFlipTiles__heading___Ghq60 {
    font-size: 10vw;
  }
}

.NestedFlipTiles__visible___1EW_F {
  opacity: 1;
}

.NestedFlipTiles__notVisible___2P0y3, .NestedFlipTiles__tileFrameHidden___3YYqZ {
  opacity: 0;
}

.NestedFlipTiles__tileFrame___1V59O, .NestedFlipTiles__tileFrameRootFlipped___12Y9Z, .NestedFlipTiles__tileFrameRoot___2-Aa6 {
  -webkit-transform-origin: center;
     -moz-transform-origin: center;
      -ms-transform-origin: center;
       -o-transform-origin: center;
          transform-origin: center;
  overflow: auto;
  background-color: var(--backgroundColor, #ffffff);
}

.NestedFlipTiles__tileFrameRoot___2-Aa6 {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
     -moz-transform: translate3d(0, 0, 0) scale(1);
          transform: translate3d(0, 0, 0) scale(1);
}
.NestedFlipTiles__tileFrameRoot___2-Aa6.NestedFlipTiles__withTransition___3djfc {
  -webkit-transition: -webkit-transform 500ms;
  transition: -webkit-transform 500ms;
  -o-transition: -o-transform 500ms;
  -moz-transition: transform 500ms, -moz-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms, -moz-transform 500ms, -o-transform 500ms;
}
.NestedFlipTiles__tileFrameRoot___2-Aa6.NestedFlipTiles__withoutTransition___3_S4G {
  -webkit-transition: none;
  -o-transition: none;
  -moz-transition: none;
  transition: none;
}

.NestedFlipTiles__tileFrame___1V59O, .NestedFlipTiles__tileFrameRootFlipped___12Y9Z {
  width: 100%;
  position: fixed;
  overflow: hidden;
}
.NestedFlipTiles__tileFrame___1V59O.NestedFlipTiles__withTransition___3djfc, .NestedFlipTiles__withTransition___3djfc.NestedFlipTiles__tileFrameRootFlipped___12Y9Z {
  -webkit-transition: background-color 200ms, -webkit-transform 500ms;
  transition: background-color 200ms, -webkit-transform 500ms;
  -o-transition: background-color 200ms, -o-transform 500ms;
  -moz-transition: transform 500ms, background-color 200ms, -moz-transform 500ms;
  transition: transform 500ms, background-color 200ms;
  transition: transform 500ms, background-color 200ms, -webkit-transform 500ms, -moz-transform 500ms, -o-transform 500ms;
}
.NestedFlipTiles__tileFrame___1V59O.NestedFlipTiles__withoutTransition___3_S4G, .NestedFlipTiles__withoutTransition___3_S4G.NestedFlipTiles__tileFrameRootFlipped___12Y9Z {
  -webkit-transition: none;
  -o-transition: none;
  -moz-transition: none;
  transition: none;
}

.NestedFlipTiles__tileFrameHidden___3YYqZ {
  background-color: transparent;
}

.NestedFlipTiles__tileFrameRootFlipped___12Y9Z {
  overflow: hidden;
}

.NestedFlipTiles__withBottomBar___3ZTtO {
  height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .NestedFlipTiles__withBottomBar___3ZTtO {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .NestedFlipTiles__withBottomBar___3ZTtO {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.NestedFlipTiles__withoutBottomBar___2ztqR {
  height: -webkit-calc(100vh - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--navBarHeightPx));
  height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .NestedFlipTiles__withoutBottomBar___2ztqR {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .NestedFlipTiles__withoutBottomBar___2ztqR {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.NestedFlipTiles__basicStyleIsRoot___1d2kA {
  height: -webkit-calc(100vh - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--navBarHeightPx));
  height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .NestedFlipTiles__basicStyleIsRoot___1d2kA {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .NestedFlipTiles__basicStyleIsRoot___1d2kA {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.NestedFlipTiles__basicStyleIsNotRoot___2niZF {
  height: auto;
}
.NestedFlipTiles__basicStyleIsNotRoot___2niZF .NestedFlipTiles__withBottomBar___3ZTtO {
  min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .NestedFlipTiles__basicStyleIsNotRoot___2niZF .NestedFlipTiles__withBottomBar___3ZTtO {
    min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .NestedFlipTiles__basicStyleIsNotRoot___2niZF .NestedFlipTiles__withBottomBar___3ZTtO {
    min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}
.NestedFlipTiles__basicStyleIsNotRoot___2niZF .NestedFlipTiles__withoutBottomBar___2ztqR {
  min-height: -webkit-calc(100vh - var(--navBarHeightPx));
  min-height: -moz-calc(100vh - var(--navBarHeightPx));
  min-height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .NestedFlipTiles__basicStyleIsNotRoot___2niZF .NestedFlipTiles__withoutBottomBar___2ztqR {
    min-height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .NestedFlipTiles__basicStyleIsNotRoot___2niZF .NestedFlipTiles__withoutBottomBar___2ztqR {
    min-height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.NestedFlipTiles__outerPage___3RGG5 {
  height: 100%;
  width: 100%;
}

.NestedFlipTiles__container___2umTR {
  position: relative;
  float: left;
  overflow: hidden;
  -webkit-transition: width 0.5s, height 0.5s;
  -o-transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  transition: width 0.5s, height 0.5s;
}

.NestedFlipTiles__unpublished___r0Klg {
  opacity: 0.3;
}

.NestedFlipTiles__flippableContainer___2p5kN {
  position: relative;
  overflow: hidden;
  float: left;
  -webkit-perspective: 100000px;
     -moz-perspective: 100000px;
          perspective: 100000px;
  -webkit-transition: all 1s, width 1500ms, height 1500ms;
  -o-transition: all 1s, width 1500ms, height 1500ms;
  -moz-transition: all 1s, width 1500ms, height 1500ms;
  transition: all 1s, width 1500ms, height 1500ms;
}
.NestedFlipTiles__flippableContainer___2p5kN .NestedFlipTiles__front___de3A7 {
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
}

.NestedFlipTiles__tile___3Bu9p {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
     -moz-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: right center;
     -moz-transform-origin: right center;
      -ms-transform-origin: right center;
       -o-transform-origin: right center;
          transform-origin: right center;
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  z-index: 0;
}
.NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__front___de3A7, .NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__back___1AFEs {
  display: block;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100%;
  -moz-perspective: 1000px;
       perspective: 1000px;
  -moz-backface-visibility: hidden;
       backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -webkit-backface-visibility: hidden;
}
.NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__front___de3A7.NestedFlipTiles__frontFlipped___2Xi8z {
  -webkit-transform: rotateY(-180deg) !important;
     -moz-transform: rotateY(-180deg) !important;
          transform: rotateY(-180deg) !important;
}
.NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__back___1AFEs {
  -webkit-transform: translate3d(0, 0, 0) rotateY(180deg);
     -moz-transform: translate3d(0, 0, 0) rotateY(180deg);
          transform: translate3d(0, 0, 0) rotateY(180deg);
  background-color: var(--color-tileBackgroundColor);
  -webkit-transition: background-color 1500ms;
  -o-transition: background-color 1500ms;
  -moz-transition: background-color 1500ms;
  transition: background-color 1500ms;
}
.NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__back___1AFEs.NestedFlipTiles__backFlipped___2Ekhk {
  background-color: var(--color-tileBackgroundColor);
  overflow: hidden;
}
.NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__back___1AFEs.NestedFlipTiles__backFlipped___2Ekhk.NestedFlipTiles__closing___2-xlp {
  background-color: var(--color-tileBackgroundColor);
}
.NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__backIE___2AyXC {
  -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
     -moz-transform: translate3d(0, 0, 0) rotateY(0deg);
          transform: translate3d(0, 0, 0) rotateY(0deg);
  background-color: var(--color-tileBackgroundColor);
  z-index: -1;
  -webkit-transition: background-color 1500ms;
  -o-transition: background-color 1500ms;
  -moz-transition: background-color 1500ms;
  transition: background-color 1500ms;
}
.NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__backIE___2AyXC.NestedFlipTiles__backFlipped___2Ekhk {
  background-color: var(--color-tileBackgroundColor);
}
.NestedFlipTiles__tile___3Bu9p .NestedFlipTiles__backIE___2AyXC.NestedFlipTiles__backFlipped___2Ekhk.NestedFlipTiles__closing___2-xlp {
  background-color: var(--color-tileBackgroundColor);
}
.NestedFlipTiles__tile___3Bu9p.NestedFlipTiles__flipped___2SPZ2 {
  -webkit-transform: translate3d(0, 0, 0) translateX(-100%) rotateY(-180deg);
     -moz-transform: translate3d(0, 0, 0) translateX(-100%) rotateY(-180deg);
          transform: translate3d(0, 0, 0) translateX(-100%) rotateY(-180deg);
  z-index: 1;
}

.NestedFlipTiles__tilesButtonsExtra___1GjJQ {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 10001;
}

.NestedFlipTiles__tilesButtonsClose___3m-Z8 {
  position: relative;
  top: 6px;
  left: -webkit-calc(100% - 32px);
  left: -moz-calc(100% - 32px);
  left: calc(100% - 32px);
  z-index: 1;
  width: 0;
  height: 0;
  opacity: 1;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.NestedFlipTiles__tilesButtonsClose___3m-Z8.NestedFlipTiles__hiddenByOpacity___1HFoU {
  opacity: 0;
  -webkit-transition: opacity 100ms;
  -o-transition: opacity 100ms;
  -moz-transition: opacity 100ms;
  transition: opacity 100ms;
}
.NestedFlipTiles__tilesButtonsClose___3m-Z8.NestedFlipTiles__posAbs___1uYEd {
  position: absolute;
}

.NestedFlipTiles__closeButton___1GwOr {
  background-color: #555555;
  color: #FFFFFF;
  z-index: 2;
  padding: 6px 9px;
  -webkit-border-radius: 40px;
     -moz-border-radius: 40px;
          border-radius: 40px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 15px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NestedFlipTiles__usageCount___3aVjd {
  position: relative;
  top: 6px;
  left: -webkit-calc(100% - 150px);
  left: -moz-calc(100% - 150px);
  left: calc(100% - 150px);
  width: 0;
  height: 0;
  opacity: 1;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  z-index: 2;
}

.NestedFlipTiles__usageCountInner___1mUU1 {
  background-color: #555555;
  color: #FFFFFF;
  z-index: 2;
  padding: 6px 9px;
  -webkit-border-radius: 40px;
     -moz-border-radius: 40px;
          border-radius: 40px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 15px;
}

.NestedFlipTiles__openingBackTile___3vYij {
  background-color: beige;
}

.NestedFlipTiles__background-color1___OmKOi {
  background-color: var(--color-tileColors-1-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-1-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-1-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow1___1anvR {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-1-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-1-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-1-hsl);
  }
}
.NestedFlipTiles__background-glow1___sbg4m {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow1___1anvR;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color2___DD4Gm {
  background-color: var(--color-tileColors-2-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color2___DD4Gm,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color2___DD4Gm.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-2-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color2___DD4Gm,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color2___DD4Gm.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-2-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow2___34r2T {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-2-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-2-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-2-hsl);
  }
}
.NestedFlipTiles__background-glow2___Y6LJV {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow2___34r2T;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color3___1M655 {
  background-color: var(--color-tileColors-3-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color3___1M655,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color3___1M655.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-3-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color3___1M655,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color3___1M655.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-3-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow3___2xSZe {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-3-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-3-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-3-hsl);
  }
}
.NestedFlipTiles__background-glow3___1q8G5 {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow3___2xSZe;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color4___1AWwA {
  background-color: var(--color-tileColors-4-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color4___1AWwA,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color4___1AWwA.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-4-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color4___1AWwA,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color4___1AWwA.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-4-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow4___2kSpD {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-4-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-4-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-4-hsl);
  }
}
.NestedFlipTiles__background-glow4___zAGSd {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow4___2kSpD;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color5___1YM2W {
  background-color: var(--color-tileColors-5-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color5___1YM2W,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color5___1YM2W.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-5-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color5___1YM2W,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color5___1YM2W.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-5-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow5___p8lk2 {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-5-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-5-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-5-hsl);
  }
}
.NestedFlipTiles__background-glow5___3VwW1 {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow5___p8lk2;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color6___2G6ln {
  background-color: var(--color-tileColors-6-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color6___2G6ln,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color6___2G6ln.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-6-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color6___2G6ln,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color6___2G6ln.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-6-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow6___2YCL3 {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-6-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-6-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-6-hsl);
  }
}
.NestedFlipTiles__background-glow6___42po_ {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow6___2YCL3;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color7___4KwYG {
  background-color: var(--color-tileColors-7-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color7___4KwYG,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color7___4KwYG.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-7-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color7___4KwYG,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color7___4KwYG.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-7-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow7___UMmPm {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-7-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-7-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-7-hsl);
  }
}
.NestedFlipTiles__background-glow7___GuC7e {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow7___UMmPm;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color8___gnnrK {
  background-color: var(--color-tileColors-8-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color8___gnnrK,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color8___gnnrK.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-8-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color8___gnnrK,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color8___gnnrK.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-8-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow8___RaqK- {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-8-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-8-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-8-hsl);
  }
}
.NestedFlipTiles__background-glow8___mXpMl {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow8___RaqK-;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color9___2O6PD {
  background-color: var(--color-tileColors-9-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color9___2O6PD,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color9___2O6PD.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-9-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color9___2O6PD,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color9___2O6PD.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-9-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow9___29B0r {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-9-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-9-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-9-hsl);
  }
}
.NestedFlipTiles__background-glow9___1cKGd {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow9___29B0r;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color10___219Q- {
  background-color: var(--color-tileColors-10-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color10___219Q-,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color10___219Q-.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-10-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color10___219Q-,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color10___219Q-.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-10-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow10___1Bsj2 {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-10-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-10-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-10-hsl);
  }
}
.NestedFlipTiles__background-glow10___I7r_4 {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow10___1Bsj2;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__background-color11___3AyjP {
  background-color: var(--color-tileColors-11-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color11___3AyjP,
.NestedFlipTiles__front___de3A7 .NestedFlipTiles__background-color11___3AyjP.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-11-hsl);
}

.NestedFlipTiles__deactivated___1AzYA .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color1___OmKOi,
.NestedFlipTiles__read___2AWYY .NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color11___3AyjP,
.NestedFlipTiles__back___1AFEs .NestedFlipTiles__background-color11___3AyjP.NestedFlipTiles__read___2AWYY {
  background-color: var(--color-tileColors-11-hsl-dark);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow11___3AIy4 {
  to {
    -webkit-filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-11-hsl-dark);
    filter: brightness(120%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px var(--color-tileColors-11-hsl);
            box-shadow: 0 0 15px 5px var(--color-tileColors-11-hsl);
  }
}
.NestedFlipTiles__background-glow11___2OkKK {
  filter: brightness(100%) grayscale(10%);
  -webkit-filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow11___3AIy4;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
}

.NestedFlipTiles__ripple___1ZVFZ {
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  opacity: 1;
}

.NestedFlipTiles__rippleEffect___SjvaK {
  -webkit-animation: NestedFlipTiles__rippleDrop___2Ybr_ 530ms ease-out;
     -moz-animation: NestedFlipTiles__rippleDrop___2Ybr_ 530ms ease-out;
       -o-animation: NestedFlipTiles__rippleDrop___2Ybr_ 530ms ease-out;
          animation: NestedFlipTiles__rippleDrop___2Ybr_ 530ms ease-out;
}

@-webkit-keyframes NestedFlipTiles__rippleDrop___2Ybr_ {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@-moz-keyframes NestedFlipTiles__rippleDrop___2Ybr_ {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@-o-keyframes NestedFlipTiles__rippleDrop___2Ybr_ {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes NestedFlipTiles__rippleDrop___2Ybr_ {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
.NestedFlipTiles__activateIcon___3Js9E {
  z-index: 33;
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NestedFlipTiles__noTilesText___qOLIb {
  font-size: 20px;
  width: 100%;
  text-align: center;
  padding-top: 50px;
  height: 100vh;
  background-color: white;
}

.NestedFlipTiles__grayscale___3REhn {
  -webkit-filter: grayscale(1) brightness(0.95);
          filter: grayscale(1) brightness(0.95);
}

.NestedFlipTiles__test___1tuDd {
  -webkit-filter: grayscale(0) brightness(2);
          filter: grayscale(0) brightness(2);
}

.NestedFlipTiles__spocInfoBox___mdm1X, .NestedFlipTiles__spocInfoBoxBrowser___3QDuy {
  width: 100%;
  height: var(--spocInfoHeightPx);
  background-color: var(--color-spocInfoBackground);
  padding: 15px 20px;
  position: absolute;
  top: var(--navBarHeight);
  left: 0;
  z-index: 10;
  will-change: opacity;
}

.NestedFlipTiles__spocInfoBox___mdm1X {
  padding: 7px 20px 0;
  margin-bottom: -4px;
}

.NestedFlipTiles__lectureInfoContainer___30wnt {
  display: inline-block;
  margin-right: 20px;
}

.NestedFlipTiles__infoBoxIcon___3acuJ {
  margin-right: 10px;
}

.NestedFlipTiles__infoBox___1-DXE {
  display: inline-block;
  color: #9b9b9b;
  font-size: 15px;
  margin-right: 10px;
}

.NestedFlipTiles__lectureInfo___31i3c {
  display: inline-block;
  color: var(--color-spocInfoText);
  font-size: 15px;
  font-weight: bold;
}

.NestedFlipTiles__finishedText___VdyvI {
  font-size: 24px;
  margin: 150px auto;
  width: 600px;
  opacity: 0;
  -webkit-transition: opacity 300ms 800ms;
  -o-transition: opacity 300ms 800ms;
  -moz-transition: opacity 300ms 800ms;
  transition: opacity 300ms 800ms;
}

.NestedFlipTiles__finishedText___VdyvI.NestedFlipTiles__visible___1EW_F {
  opacity: 1;
}

.NestedFlipTiles__select___2eD9P {
  position: absolute !important;
  right: 35px;
  top: 10px;
}

.NestedFlipTiles__menu___1-5FR {
  top: 64px !important;
  height: -webkit-calc(100% - 64px);
  height: -moz-calc(100% - 64px);
  height: calc(100% - 64px);
}

@-webkit-keyframes NestedFlipTiles__pulse-glow___1NuqA {
  to {
    -webkit-filter: brightness(50%) grayscale(0%);
            filter: brightness(50%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px red;
            box-shadow: 0 0 15px 5px red;
  }
}

@-moz-keyframes NestedFlipTiles__pulse-glow___1NuqA {
  to {
    filter: brightness(50%) grayscale(0%);
    -moz-box-shadow: 0 0 15px 5px red;
         box-shadow: 0 0 15px 5px red;
  }
}

@-o-keyframes NestedFlipTiles__pulse-glow___1NuqA {
  to {
    filter: brightness(50%) grayscale(0%);
    box-shadow: 0 0 15px 5px red;
  }
}

@keyframes NestedFlipTiles__pulse-glow___1NuqA {
  to {
    -webkit-filter: brightness(50%) grayscale(0%);
            filter: brightness(50%) grayscale(0%);
    -webkit-box-shadow: 0 0 15px 5px red;
       -moz-box-shadow: 0 0 15px 5px red;
            box-shadow: 0 0 15px 5px red;
  }
}
.NestedFlipTiles__blinkyBlinky___3L6B7 {
  -webkit-box-shadow: 0 0 0px 5px red;
     -moz-box-shadow: 0 0 0px 5px red;
          box-shadow: 0 0 0px 5px red;
  -webkit-filter: brightness(100%) grayscale(10%);
          filter: brightness(100%) grayscale(10%);
  -webkit-animation-name: NestedFlipTiles__pulse-glow___1NuqA !important;
     -moz-animation-name: NestedFlipTiles__pulse-glow___1NuqA !important;
       -o-animation-name: NestedFlipTiles__pulse-glow___1NuqA !important;
          animation-name: NestedFlipTiles__pulse-glow___1NuqA !important;
  -webkit-animation-duration: 0.5s !important;
     -moz-animation-duration: 0.5s !important;
       -o-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important;
  -webkit-animation-direction: alternate !important;
     -moz-animation-direction: alternate !important;
       -o-animation-direction: alternate !important;
          animation-direction: alternate !important;
  -webkit-animation-iteration-count: infinite !important;
     -moz-animation-iteration-count: infinite !important;
       -o-animation-iteration-count: infinite !important;
          animation-iteration-count: infinite !important;
}

.NestedFlipTiles__sound___3Wlme {
  position: absolute;
  left: -10000px;
  top: -10000px;
}

.NestedFlipTiles__noAnimation___3nG-A {
  -webkit-transition: none !important;
  -o-transition: none !important;
  -moz-transition: none !important;
  transition: none !important;
}