.DrupalGalleryNugget__galleryContainer___1G0Sz {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.DrupalGalleryNugget__loadingOverlay___21C-v {
  position: absolute;
  z-index: 200;
  width: 100%;
  height: 100%;
  color: white;
  background-color: white;
}

.DrupalGalleryNugget__spinner___2klVd {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}