.KesiLogIn__logInInfo___2jJch {
  height: 100vH;
  text-align: center;
  margin: 40px auto 0;
  color: var(--color-buttonBackground);
}

.KesiLogIn__logInText___2BT0f {
  font-size: 30px;
  padding: 20px;
}

.KesiLogIn__textfield___2-Atr {
  display: block;
  width: 51%;
}

.KesiLogIn__tileBackground___wg_-f {
  width: 100%;
  heigth: 100%;
}

.KesiLogIn__tile___3Tnaw {
  display: inline-block;
  width: 16.6666666667%;
  padding: 10px;
}