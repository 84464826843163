:root {
  --color-titleBackgroundOverlay: rgba(255, 255, 255, 0.0);
  --color-tileFontColor: #ffffff;
  --color-tileFontWeight: 300;
  --color-tileBackgroundColor: #FFFFFF;

  --color-tileColors-1-hsl: hsl(206, 100%, 28%);
  --color-tileColors-1-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-2-hsl: hsl(206, 100%, 28%);
  --color-tileColors-2-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-3-hsl: hsl(206, 100%, 28%);
  --color-tileColors-3-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-4-hsl: hsl(206, 100%, 28%);
  --color-tileColors-4-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-5-hsl: hsl(206, 100%, 28%);
  --color-tileColors-5-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-6-hsl: hsl(206, 100%, 28%);
  --color-tileColors-6-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-7-hsl: hsl(206, 100%, 28%);
  --color-tileColors-7-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-8-hsl: hsl(206, 100%, 28%);
  --color-tileColors-8-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-9-hsl: hsl(206, 100%, 28%);
  --color-tileColors-9-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-10-hsl: hsl(206, 100%, 28%);
  --color-tileColors-10-hsl-dark: hsl(207, 10%, 36%);

  --color-tileColors-11-hsl: hsl(206, 100%, 28%);
  --color-tileColors-11-hsl-dark: hsl(207, 10%, 36%);

  --color-navbarBackground: #FFFFFF;
  --color-navbarBorderBottom: 1px solid #9b9b9b;
  --color-navbarText: #00518E;
  --color-menuBackground: #FFFFFF;
  --color-buttonBorder: #f0af00;
  --color-buttonBorderSecondary: #f0af00;
  --color-buttonCaption: #303d4f;
  --color-buttonCaptionSecondary: #FFFFFF;
  --color-buttonBackground:#f0af00;
  --color-buttonBackgroundLight: #c6defa;
  --color-buttonBorderLight: #8faed6;
  --color-buttonCaptionLight: #8faed6;
  --color-buttonBackgroundDark: #f4f4f4;
  --color-buttonBorderDark: #f4f4f4;
  --color-buttonCaptionDark: #00518E;
  --color-lightText: #00518E;
  --color-textfieldCaption: #00518E;
  --color-tab: #39597B;
  --color-tabBackground: #FFFFFF;
  --color-tableDivider: #D8D8D8;
  --color-dialogText: #6f6760;
  --color-dialogDivider: #a69b8d;
  --color-dialogBackground: #fbf9f7;
  --color-primaryColor1: #ff2337;
  --color-primaryColor2: #e32233;
  --color-navbarItemColor: #303d4f;
  --color-lectureBackButton: green;
  --color-lectureHeaderText: #ffffff;

  --fontFamily: Oxygen;
  --navBarMaxWidth: 45%;
  --navBarContainerLeftTop: 20px;
  --navBarHeight: 64;
  --navBarHeightPx: 64px;
  --navBarLogoMarginTop: 10;
  --navBarRightSideButtonsTop: 24px;
  --navBarLogoLeft: 10%;
  --navBarLogoHeight: 35px;
  --navBarLogoTop: 20px;
  --thresholdScreenWidth: 800px;
  --mainMenuWidth: 300px;
  --navBarMargin: 0 40px;
  --navBarMaxHeight: 60px;
  --navBarTextAlign: center;
  --navBarVerticalAlign: middle;
  --navBarFontSize: 16px;
  --navBarFontWeight: bold;
  --navbarTitleColor: #014170;
  --navBarPaddingTop: 14px;
  --navBarLineHeight: 23px;
  --burgerIconLeft: 10px;
  --navBarOverflow: hidden;
  --headerMinHeight: 160px;
  --navBarTitleWidth: 400px;
  --navBarBackIconSize: 50px;
  --navBarBackIconTop: -10px;

  --tiles-backgroundHeight: 99;
  --tiles-minTitleHeight: 100;
  --tiles-titleSize: 12;
  --tiles-iconSize: 80;
  --tiles-iconRight: 50%;
  --tiles-iconTransform: translateX(50%);
  --tiles-backgroundColor: rgba(255, 255, 255, 0.0);
  --tiles-blur: blur(0);
  --app-maxWidth: 1024px;

  --safe-area-top-constant: 0px;
  --safe-area-top-env: 0px;
  --safe-area-bottom-constant: 0px;
  --safe-area-bottom-env: 0px;
}
