.ErgoTopLevelView__shieldContainer___AWU59 {
  width: 100%;
  height: 45vh;
  position: relative;
  background-color: white;
  text-align: center;
}

.ErgoTopLevelView__imageContainer___3DphH {
  -webkit-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
  will-change: opacity;
}

.ErgoTopLevelView__imageContainerNoTrans___21f0H {
  -webkit-transition: opacity 0ms;
  -o-transition: opacity 0ms;
  -moz-transition: opacity 0ms;
  transition: opacity 0ms;
  will-change: opacity;
}

.ErgoTopLevelView__targetShieldBackground___3vXgu {
  height: 100%;
  min-width: 100%;
  left: 0;
  z-index: 0;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  margin-left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
     -moz-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}

.ErgoTopLevelView__targetShieldBackgroundTopOverlay___sktuV {
  height: 29%;
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 3;
  background: -webkit-gradient(linear, left top, left bottom, from(black), to(rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(top, black, rgba(0, 0, 0, 0));
  background: -moz- oldlinear-gradient(top, black, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(top, black, rgba(0, 0, 0, 0));
  background: linear-gradient(to bottom, black, rgba(0, 0, 0, 0));
  opacity: 0.4;
}

.ErgoTopLevelView__targetShieldBackgroundBottomOverlay___1gQYU {
  height: 30%;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 3;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), white);
  background: -moz- oldlinear-gradient(top, rgba(255, 255, 255, 0), white);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0), white);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
}

.ErgoTopLevelView__locationInfos___P3bx0 {
  display: block;
}

.ErgoTopLevelView__riskRingContainer___JjO2k {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.ErgoTopLevelView__riskRingSwipe___1To3m {
  position: relative;
  height: 80%;
  z-index: 5;
}

.ErgoTopLevelView__riskRingSwipe___1To3m > div:first-child, .ErgoTopLevelView__riskRingSwipe___1To3m > div:first-child > div {
  height: 100%;
}

.ErgoTopLevelView__riskRingBackground___3rlZM {
  opacity: 0.73;
  display: inline-block;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  height: 100%;
  position: absolute;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  -o-transition: -o-transform 1000ms;
  -moz-transition: transform 1000ms, -moz-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
  will-change: transform;
}

.ErgoTopLevelView__riskRing___3xIr2 {
  display: inline-block;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  height: 100%;
  position: relative;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  -o-transition: -o-transform 1000ms;
  -moz-transition: transform 1000ms, -moz-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErgoTopLevelView__countryInformation___2Kv0M, .ErgoTopLevelView__targetInformation___3FiAg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
}

.ErgoTopLevelView__targetInformation___3FiAg {
  top: 45%;
}

.ErgoTopLevelView__countryInformationSmall___2_DMx, .ErgoTopLevelView__targetInformationSmall___1mY4e {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.ErgoTopLevelView__textAnimation___1CZ7H {
  -webkit-animation: ErgoTopLevelView__fadeIn___JZ0MK 1s;
     -moz-animation: ErgoTopLevelView__fadeIn___JZ0MK 1s;
       -o-animation: ErgoTopLevelView__fadeIn___JZ0MK 1s;
          animation: ErgoTopLevelView__fadeIn___JZ0MK 1s;
  -webkit-animation-delay: 500ms;
     -moz-animation-delay: 500ms;
       -o-animation-delay: 500ms;
          animation-delay: 500ms;
  -webkit-animation-fill-mode: forwards;
     -moz-animation-fill-mode: forwards;
       -o-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
}

@-webkit-keyframes ErgoTopLevelView__fadeIn___JZ0MK {
  100% {
    opacity: 1;
  }
}

@-moz-keyframes ErgoTopLevelView__fadeIn___JZ0MK {
  100% {
    opacity: 1;
  }
}

@-o-keyframes ErgoTopLevelView__fadeIn___JZ0MK {
  100% {
    opacity: 1;
  }
}

@keyframes ErgoTopLevelView__fadeIn___JZ0MK {
  100% {
    opacity: 1;
  }
}
.ErgoTopLevelView__infoContainer___tJPGl {
  width: 100%;
  height: 100%;
  padding-top: 20px;
}

.ErgoTopLevelView__regionType___QMcpn, .ErgoTopLevelView__targetType___3BVk- {
  height: auto;
  position: relative;
  color: white;
  padding: 10px;
  font-size: 18px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  font-weight: bold;
}

.ErgoTopLevelView__targetType___3BVk- {
  min-height: 48px;
}

.ErgoTopLevelView__dotContainer___2cDy4 {
  width: 100%;
  text-align: center;
}

.ErgoTopLevelView__activeDot___2rHNg {
  width: 6px;
  height: 6px;
  background-color: white;
  display: inline-block;
  position: relative;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  margin: 5px;
}

.ErgoTopLevelView__dot___2MNfU {
  width: 6px;
  height: 6px;
  display: inline-block;
  position: relative;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  margin: 5px;
  opacity: 0.5;
  background-color: #ffffff;
}

.ErgoTopLevelView__locationRiskInfo___3kGP0 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 20%;
}

.ErgoTopLevelView__locationMarker___186JS {
  width: 60%;
  height: auto;
}

.ErgoTopLevelView__locationMarkerOff___2mFKJ {
  width: 80%;
  height: auto;
}

.ErgoTopLevelView__locationRisk___3xY4Q {
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  padding-bottom: 30%;
  width: 100%;
  margin-top: -10%;
}

.ErgoTopLevelView__secureProfile___A65jo {
  color: black;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.ErgoTopLevelView__trash___1cG6k {
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
  padding: 7px 5px 5px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErgoTopLevelView__addIcon___f9d-Y {
  width: 30%;
  height: auto;
  color: white;
}

.ErgoTopLevelView__addInfo___2EGZ3 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  -o-transition: -o-transform 1000ms;
  -moz-transition: transform 1000ms, -moz-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
  will-change: transform;
}

.ErgoTopLevelView__addUnits___3afYj, .ErgoTopLevelView__addUnitsSmall___jPTy2 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  margin-top: 30%;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  -o-transition: -o-transform 1000ms;
  -moz-transition: transform 1000ms, -moz-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
  will-change: transform;
}

.ErgoTopLevelView__addUnits___3afYj {
  margin-top: 22%;
}

.ErgoTopLevelView__addUnitsWithTime___1yia6 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  margin-top: 27%;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  -o-transition: -o-transform 1000ms;
  -moz-transition: transform 1000ms, -moz-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms, -moz-transform 1000ms, -o-transform 1000ms;
  will-change: transform;
}

.ErgoTopLevelView__circleUnitsNumber___14vhb {
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
}

.ErgoTopLevelView__circleUnitsText___3CKof {
  font-size: 12px;
  color: #ffffff;
}

.ErgoTopLevelView__circleTimeNumber___zliWo {
  color: #761012;
  font-weight: bold;
  font-size: 14px;
}

.ErgoTopLevelView__circleTimeText___1Ds6s {
  font-size: 12px;
  color: #761012;
}

.ErgoTopLevelView__circleUnitsNumberSmall___CxuHl {
  color: #ffffff;
  font-weight: bold;
  font-size: 11px;
}

.ErgoTopLevelView__circleUnitsTextSmall___1JRfi {
  font-size: 9px;
  color: #ffffff;
}

.ErgoTopLevelView__circleTimeNumberSmall___3uJLy {
  color: #761012;
  font-weight: bold;
  font-size: 11px;
}

.ErgoTopLevelView__circleTimeTextSmall___1vFlr {
  font-size: 9px;
  color: #761012;
}

.ErgoTopLevelView__addText___2pCFx {
  padding-top: 63%;
  color: #ffffff;
  font-size: 13px;
  width: 70%;
  margin: 0 auto;
}

.ErgoTopLevelView__arrowContainer___8FP9e {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  will-change: transform, opacity;
}

.ErgoTopLevelView__arrow___1rr7D {
  width: 30%;
  height: auto;
  color: black;
  font-size: 20px;
}

.ErgoTopLevelView__imageText___369qk {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 20px;
  z-index: 1;
  max-width: 90%;
  text-align: left;
  font-weight: bold;
}

.ErgoTopLevelView__circleAnimation___2YW0b {
  -webkit-animation: ErgoTopLevelView__progress___3viw6 1s ease-out forwards;
     -moz-animation: ErgoTopLevelView__progress___3viw6 1s ease-out forwards;
       -o-animation: ErgoTopLevelView__progress___3viw6 1s ease-out forwards;
          animation: ErgoTopLevelView__progress___3viw6 1s ease-out forwards;
  -webkit-animation-delay: 500ms;
     -moz-animation-delay: 500ms;
       -o-animation-delay: 500ms;
          animation-delay: 500ms;
  opacity: 0;
}

@-webkit-keyframes ErgoTopLevelView__progress___3viw6 {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 100;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes ErgoTopLevelView__progress___3viw6 {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 100;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes ErgoTopLevelView__progress___3viw6 {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 100;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ErgoTopLevelView__progress___3viw6 {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 100;
  }
  100% {
    opacity: 1;
  }
}
.ErgoTopLevelView__svg___3P-4g {
  -webkit-transform: rotate(-90deg);
     -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
       -o-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background: transparent;
  z-index: 20;
}

.ErgoTopLevelView__itemCss___31t5h {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  will-change: opacity;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms;
}

.ErgoTopLevelView__targetDescription___QvEX9 {
  color: white;
  font-size: 15px;
}

.ErgoTopLevelView__select___1QOBu {
  width: 100% !important;
  margin-top: 10px !important;
}