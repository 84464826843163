.AlertHelper__dialog___2IcP3 {
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  max-height: 90vh !important;
}

.AlertHelper__closeButton___KiPUJ {
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;
  text-align: right;
  float: right;
  position: absolute;
  top: 0;
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0 5px;
}

.AlertHelper__closeButton___KiPUJ:hover {
  background-color: rgba(0, 0, 0, 0);
}

.AlertHelper__content___Aotl6 {
  /* these are the title and message containers */
  font-size: 18px;
  padding: 10px;
  max-height: 65vh;
  -webkit-overflow-scrolling: touch;
  overflow: overlay;
  overflow: auto;
  /* div {
    max-height: 80vh;
    overflow: scroll
  } */
}
.AlertHelper__content___Aotl6 img, .AlertHelper__content___Aotl6 video {
  max-width: 100%;
}
.AlertHelper__content___Aotl6 img {
  margin: 10px 0;
}

.AlertHelper__centered___3ipYL {
  text-align: center;
}

.AlertHelper__title___3eWLt {
  /* these are the title and message containers */
  font-size: 18px;
  padding: 10px;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-family: var(--fontFamily) !important;
}

.AlertHelper__error___3A2DF {
  /* this is the error container */
  font-size: 18px;
  padding: 10px;
  text-align: center;
  color: red;
}

.mdl-textfield {
  padding: 40px 0 !important;
  font-size: 16px;
}

.mdl-textfield__label:after {
  bottom: 10px !important;
}

.AlertHelper__buttonActions___3mjBc {
  /* this is the buttons container */
  /*border-top: 1px map_get($colors, dialogDivider) solid;*/
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  font-size: 12px;
  line-height: 13px;
}
.AlertHelper__buttonActions___3mjBc button {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 5px 5px !important;
  width: 40%;
}

.AlertHelper__checkActions___2mRIQ {
  width: 100%;
}

.AlertHelper__extraCheckHeight___2k1CU {
  height: 80px !important;
}

.AlertHelper__fullWidthInput___1IPzP {
  padding-top: 0;
}
.AlertHelper__fullWidthInput___1IPzP button {
  width: 100%;
}

.AlertHelper__extraPadding___2AIw0 {
  padding-top: 30px;
}

.AlertHelper__fullWithButton___TUj48 button {
  width: 100%;
}

.AlertHelper__placeHolder___21yTB {
  width: 100%;
  height: 30px;
}

.AlertHelper__center___31iPR div {
  margin: 0 auto !important;
}

.AlertHelper__modal___1OKz6 {
  z-index: 100010;
  position: absolute;
}