.QuizOpponents__buttonParticipants___2DS1N {
  width: 90%;
  margin: 1px;
  border: 1px var(--color-buttonBorderSecondary) solid;
  color: var(--color-buttonCaptionSecondary);
  text-align: left;
  padding-left: 25%;
}

.QuizOpponents__buttonParticipants_disabled___1sYlK {
  border: none !important;
  background: #ccc !important;
}

.QuizOpponents__innerContentScroll___2AalV {
  overflow: auto;
}

.QuizOpponents__thumb___1vstO {
  width: 32px;
  height: 32px;
  /*border: 1px solid #ccc;*/
  -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
          border-radius: 50px;
  margin: 0 7px;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
}
.QuizOpponents__thumb___1vstO img {
  max-width: 32px;
}

.QuizOpponents__searchField___2irrw {
  width: 100%;
  -webkit-border-radius: 7px;
     -moz-border-radius: 7px;
          border-radius: 7px;
  border: 1px solid #ccc;
  padding: 7px;
  margin: 10px auto;
  display: block;
}

.QuizOpponents__filterButtons___1DQVU {
  padding-top: 10px;
  border-bottom: 1px white solid;
  text-align: left;
  background-color: var(--color-filterButton);
  color: var(--color-filterButtonText);
  position: relative;
  top: -2px;
  height: 56px;
}

.QuizOpponents__onlineFilter___iCBLv {
  height: 40px;
  position: relative;
  left: 130px;
  top: 5px;
  line-height: 24px;
  font-size: 1.4em;
}

.QuizOpponents__switch___1rWj1 {
  display: inline-block;
  width: 75px;
  top: 4px;
  left: 15px;
  position: relative;
}

.QuizOpponents__filterText___2Gk0F {
  font-size: 1.4em;
  top: 6px;
  position: relative;
}