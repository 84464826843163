.DrupalQuizNugget__answerList___2Nbxy {
  list-style: none;
  margin: 0;
  padding: 0;
  height: auto;
}

@media only screen and (max-width: 1023px) {
  .DrupalQuizNugget__answerList___2Nbxy {
    padding: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .DrupalQuizNugget__answerList___2Nbxy {
    padding: 0;
  }
}
.DrupalQuizNugget__overlay___15Gbo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.DrupalQuizNugget__window___2UYHJ {
  font-size: 17px;
  width: 70%;
  overflow: auto;
  padding: 20px;
  margin: auto;
  background-color: #ffffff;
  border: 1px solid grey;
  z-index: 7;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
}

.DrupalQuizNugget__windowNoBorder___3tvhj {
  font-size: 17px;
  width: 100%;
  overflow: auto;
  padding: 20px;
  margin: auto;
  background-color: #ffffff;
  z-index: 7;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
}

.DrupalQuizNugget__feedbackText___TZuPR {
  padding-bottom: 30px;
}

.DrupalQuizNugget__feedback___hzSYp {
  font-size: 1.35rem !important;
  margin: 10px;
}

@media only screen and (max-width: 1023px) {
  .DrupalQuizNugget__button___32XgP {
    float: right;
  }
}
.DrupalQuizNugget__centeredButton___2U2yn {
  margin-top: 5%;
  margin-left: 50%;
  margin-bottom: 5%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  min-width: 150px !important;
}

.DrupalQuizNugget__quizFinished___1pnMA {
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 1.35rem;
  position: relative;
  height: 100%;
  color: #ff6600;
}

.DrupalQuizNugget__quizInfo___5ufXP {
  width: 100%;
  text-align: right;
  font-size: 15px;
  color: #4a4a4a;
  padding-top: 15px;
}

.DrupalQuizNugget__buttonContainer___31yuL {
  position: relative;
  width: 100%;
  height: auto;
}

.DrupalQuizNugget__quiz___2Hm7h {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}