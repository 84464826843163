.styles__button___29pfe {
  /*border: 1px map-get($colors, buttonBorder) solid;
  color: map-get($colors, buttonCaption);*/
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  /*background-color: map-get($colors, buttonBackground);*/
  font-size: 13px;
  line-height: 16px;
  font-family: var(--fontFamily);
  font-weight: 900;
  text-transform: uppercase;
  height: 50px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.styles__noBorder___3FQzF {
  border: none !important;
}

.styles__disabled___3ufm1 {
  border: 1px rgba(0, 0, 0, 0.26) solid;
}

.mdl-button:hover {
  /*background-color: map-get($colors, buttonBackground);*/
}

.mdl-button:active {
  background-color: rgba(158, 158, 158, 0.4);
}