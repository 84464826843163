.DrupalPodcastNugget__audioContainer___6TvIM {
  position: relative;
  width: 100%;
}

.DrupalPodcastNugget__podcastBackground___1mIwK {
  width: 100%;
  height: auto;
}

.DrupalPodcastNugget__audioPlayerContainer___18ka5 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 90%;
}

.DrupalPodcastNugget__audioFileName___3W4bM {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.DrupalPodcastNugget__audioFileInfo___3r3YC {
  font-size: 12px;
  display: block;
  padding-top: 10px;
}

.DrupalPodcastNugget__infoContainer___1Sdm9 {
  display: inline-block;
  width: -webkit-calc(100% - 90px);
  width: -moz-calc(100% - 90px);
  width: calc(100% - 90px);
}

.DrupalPodcastNugget__bigAudioPlayer___XEQ8_ {
  width: 100%;
  height: 50px;
  display: block;
  margin: 20px 0;
}

.DrupalPodcastNugget__playButton___UjHyn {
  color: var(--color-buttonBackground);
  height: 50px !important;
  width: 50px !important;
  display: inline-block !important;
  vertical-align: top !important;
  margin-right: 30px !important;
}

.DrupalPodcastNugget__playButtonCentered___3x3UT {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 40% !important;
  width: 40% !important;
}

.DrupalPodcastNugget__audioItem___bU0_g {
  width: 100%;
  display: block;
  padding-bottom: 30px;
}

.DrupalPodcastNugget__buttonBelow___1W77f {
  width: 50px !important;
  height: 50px !important;
  padding: 10px !important;
  position: relative;
  display: inline-block;
}

.DrupalPodcastNugget__subscribeButton___lrjYH {
  position: relative;
  margin: 10px;
}