.QuizRound__button___3pbRj {
  display: block;
  width: 290px;
  max-width: 90%;
  margin: auto;
}

.QuizRound__answerStatusContainer___higHI {
  margin: 2px;
  display: inline-block;
}

.QuizRound__questionNum___1wDb3 {
  text-align: center;
}

.QuizRound__answerStatus___2RRnq {
  background: #FFFFFF;
  border: 1px solid #cccccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.QuizRound__scrollDown___EogFV {
  position: absolute;
  bottom: 10px;
  right: 10px;
  /*height: 36px;
  width: 36px;
  background: url(./assets/scroll-down.png);
  background-size: cover;*/
}

.QuizRound__quizHeader___2QPCT {
  max-height: 75px;
}

.QuizRound__quizRoundContainer___k-dNv {
  height: 100%;
  background-color: var(--color-quizBackground);
}