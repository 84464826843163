.DrupalVideoPlaylistNugget__videoPlayer___oUBPP {
  margin-bottom: 20px;
  display: inline-block;
  width: 50%;
}

.DrupalVideoPlaylistNugget__videoFileName___2O3kr {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.DrupalVideoPlaylistNugget__videoFileInfo___2sLHY {
  font-size: 12px;
  display: block;
  padding-top: 10px;
}

.DrupalVideoPlaylistNugget__infoContainer___1hxEY {
  display: inline-block;
  width: -webkit-calc(100% - 90px);
  width: -moz-calc(100% - 90px);
  width: calc(100% - 90px);
}

.DrupalVideoPlaylistNugget__bigVideoPlayer___1EMyv {
  width: 100%;
  height: auto;
  display: block;
  margin: 20px 0;
}

.DrupalVideoPlaylistNugget__playButton___1Bm9e {
  color: var(--color-buttonBackground);
  height: 50px !important;
  width: 50px !important;
  display: inline-block !important;
  vertical-align: top !important;
  margin-right: 30px !important;
}

.DrupalVideoPlaylistNugget__playButtonCentered___3JBcv {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 40% !important;
  width: 40% !important;
}

.DrupalVideoPlaylistNugget__videoItem___1fwaZ {
  width: 100%;
  display: block;
  padding-bottom: 30px;
}

.DrupalVideoPlaylistNugget__playingTitle___C6fsY {
  font-size: 22px;
  font-weight: bold;
  color: var(--color-buttonBackground);
}

.DrupalVideoPlaylistNugget__transcriptContainer___2ie9B {
  max-width: 100%;
  font-size: 12px;
  margin: 0 20px 20px;
  max-height: 200px;
  overflow: scroll;
}

.DrupalVideoPlaylistNugget__transcriptTitle___3AriT {
  font-size: 16px;
  font-weight: bold;
  line-height: 60px;
}

.DrupalVideoPlaylistNugget__transcriptArrow___3JB8i {
  width: 60px !important;
  height: 60px !important;
}

.DrupalVideoPlaylistNugget__transcriptText___Ls8VV p, .DrupalVideoPlaylistNugget__transcriptText___Ls8VV ol, .DrupalVideoPlaylistNugget__transcriptText___Ls8VV ul, .DrupalVideoPlaylistNugget__transcriptText___Ls8VV li {
  line-height: 14px !important;
  font-size: 12px !important;
}