.DrupalContainerNugget__galleryContainer___HV7br {
  position: relative;
  vertical-align: top;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.DrupalContainerNugget__loadingOverlay___26A9Y {
  position: absolute;
  z-index: 200;
  width: 100%;
  height: 100%;
  color: white;
  background-color: white;
}

.DrupalContainerNugget__spinner___4eNAe {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.DrupalContainerNugget__buttonContainer___1uNnD {
  padding-top: 10px;
  width: 100%;
  position: relative;
}

.DrupalContainerNugget__closeButton___3Qs-c {
  width: 80% !important;
  height: 40px;
  margin: 10px 10% !important;
  display: block;
  background-color: var(--color-buttonBackground) !important;
  color: white !important;
  -webkit-border-radius: 20px !important;
     -moz-border-radius: 20px !important;
          border-radius: 20px !important;
  -webkit-box-shadow: darkgray 2px 2px 5px 0px;
     -moz-box-shadow: darkgray 2px 2px 5px 0px;
          box-shadow: darkgray 2px 2px 5px 0px;
}