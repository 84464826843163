.QuizHighScores__quizHighScores___AJHVk {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.QuizHighScores__quizHighScores___AJHVk ul {
  list-style: none;
  padding: 0;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}
.QuizHighScores__quizHighScores___AJHVk ul li {
  list-style: none;
  border-top: 1px solid #fff;
  padding: 7px 5px;
}
.QuizHighScores__quizHighScores___AJHVk ul li div {
  display: inline-block;
  width: 50%;
  padding: 0 10px;
}
.QuizHighScores__quizHighScores___AJHVk ul li div:first-child {
  width: 10%;
  margin-left: 10%;
}
.QuizHighScores__quizHighScores___AJHVk ul li div:last-child {
  width: 20%;
  text-align: center;
}
.QuizHighScores__quizHighScores___AJHVk ul li .QuizHighScores__thumb___11cGC {
  width: 24px;
  height: 24px;
  /*border: 1px solid #ccc;*/
  -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
          border-radius: 50px;
  margin: 0 7px;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
}
.QuizHighScores__quizHighScores___AJHVk ul li .QuizHighScores__thumb___11cGC img {
  max-width: 24px;
}
.QuizHighScores__quizHighScores___AJHVk ul li.QuizHighScores__last-child___2Froy {
  border-bottom: 1px solid #fff;
}

.QuizHighScores__header___3ab5H {
  font-weight: 600;
  width: 90%;
  background: #fff;
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.QuizHighScores__header___3ab5H li {
  padding: 7px 5px;
  list-style: none;
  border-top: 1px solid #fff;
}
.QuizHighScores__header___3ab5H li div {
  display: inline-block;
  width: 50%;
  padding: 0 10px;
}
.QuizHighScores__header___3ab5H li div:first-child {
  width: 10%;
  margin-left: 10%;
}
.QuizHighScores__header___3ab5H li div:last-child {
  width: 20%;
  text-align: center;
}

.QuizHighScores__tabsContainer___1ZIDF {
  text-align: center;
  padding-top: 10px;
}
.QuizHighScores__tabsContainer___1ZIDF div {
  display: inline-block;
  border: 1px solid var(--color-buttonBorderSecondary);
  padding: 5px 10px;
  margin: 2px;
}
.QuizHighScores__tabsContainer___1ZIDF .QuizHighScores__active___2kOuI {
  color: var(--color-buttonCaptionSecondary);
  background: var(--color-buttonBorderSecondary);
}

.QuizHighScores__userName___2iEYN {
  font-weight: bold;
}

.QuizHighScores__scrollArrow___lFNrJ {
  position: absolute;
  bottom: 10px;
  right: 7%;
  font-size: 0.85em;
  font-style: italic;
}