:root {
  --thresholdScreenWidth: 800px;
  --mainMenuWidth: 300px;
  --navBarMargin: 0 40px;
  --navBarMaxHeight: 60px;
  --navBarTextAlign: center;
  --navBarVerticalAlign: middle;
  --navBarFontSize: 16px;
  --navBarFontWeight: bold;
  --navbarTitleColor: #014170;
  --navBarPaddingTop: 14px;
  --navBarLineHeight: 19px;
  --burgerIconLeft: 10px;
  --navBarOverflow: hidden;
  --navBarMaxWidth: 80%;
  --navBarContainerLeftTop: 10px;
  --navBarLogoMarginTop: 10px;
  --navBarLogoLeft: 10%;
  --navBarHeight: 64;
  --navBarLogoHeight: 35px;
  --navBarHeightPx: 64px;
  --categoryChooserHeight: 100px;
  --categoryHeaderHeight: 140px;
  --color-chatColor: #FF7011;
  --color-chatExpertColor: #1170B8;
  --navBarBackIconSize: 32px;
  --navBarBackIconTop: 4px;

  --fontFamily: Roboto, sans-serif;

  --tiles-backgroundHeight: 60;
  --tiles-minTitleHeight: 61;
  --tiles-iconSize: 80;
  --tiles-iconRight: 0;
  --tiles-iconTransform: "translateX(0)";
  --tiles-backgroundColor: "rgba(0,0,0,0)";
  --tiles-blur: "blur(6px)";
  --tiles-titleSize: 14;
  --colors-tileStandardTextColor: 'white';
  --color-tileBorder: 'none';
  --tiles-borderRadius: 0px;
}
