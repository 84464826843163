.NuggetBack__nuggetBack___3F_DF {
  position: absolute;
}

.NuggetBack__nuggetBackIE___aiFgC {
  -webkit-transform: translate3d(0, 0, 0) rotateY(180deg);
     -moz-transform: translate3d(0, 0, 0) rotateY(180deg);
          transform: translate3d(0, 0, 0) rotateY(180deg);
  position: absolute;
  width: 100%;
  background-color: var(--color-tileBackgroundColor);
}

.NuggetBack__favoriteButton___1FFgZ, .NuggetBack__readingStateButton___3UGNi {
  color: white;
  font-weight: 100;
  font-size: 11px;
  padding: 5px 10px;
  position: absolute;
}

.NuggetBack__lectureFrame___1Oeeg {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: -webkit-calc(100vh - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--navBarHeightPx));
  height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .NuggetBack__lectureFrame___1Oeeg {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .NuggetBack__lectureFrame___1Oeeg {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}