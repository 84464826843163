.ChatInput__chatInput___fdKJb {
  width: 100%;
  text-align: center;
  position: relative;
  display: block;
  margin-top: 20px;
}

.ChatInput__textfield___2-JsV {
  width: 80%;
  margin-right: 10px;
  -webkit-transition: height 300ms;
  -o-transition: height 300ms;
  -moz-transition: height 300ms;
  transition: height 300ms;
}

.ChatInput__textfieldLabel___1Mx59 {
  padding: 10%;
}

.ChatInput__textfield___2-JsV textarea {
  min-height: 100px !important;
  background-color: white;
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #8b8b8b;
  padding: 5px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  resize: vertical;
}

.ChatInput__button___3G_zg {
  right: 5%;
  top: 50%;
  position: absolute;
  font-size: 30px;
  -webkit-transform: translate(50%, -50%);
     -moz-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
       -o-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  color: var(--color-chatColor);
}

.ChatInput__emojiButton___gy5j1 {
  right: 13%;
  bottom: 13%;
  position: absolute;
  font-size: 15px;
  -webkit-transform: translateX(50%);
     -moz-transform: translateX(50%);
      -ms-transform: translateX(50%);
       -o-transform: translateX(50%);
          transform: translateX(50%);
  color: var(--color-chatColor);
}

.ChatInput__penButton___1txPH {
  right: 18%;
  bottom: 13%;
  position: absolute;
  font-size: 15px;
  -webkit-transform: translateX(50%);
     -moz-transform: translateX(50%);
      -ms-transform: translateX(50%);
       -o-transform: translateX(50%);
          transform: translateX(50%);
  color: var(--color-chatColor);
}

.ChatInput__picker___YBtGZ {
  -webkit-transition: max-height 500ms ease-in-out;
  -o-transition: max-height 500ms ease-in-out;
  -moz-transition: max-height 500ms ease-in-out;
  transition: max-height 500ms ease-in-out;
  overflow: hidden;
  width: 80%;
  margin: 0 10%;
}

.ChatInput__quote___2YW9- {
  width: 80%;
  margin: 0 10%;
  padding: 10px;
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  background-color: white;
  border: 1px solid #8b8b8b;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.ChatInput__quoteTitle___3FnB0 {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #a3a3a3;
}

.ChatInput__removeQuoteButton___38vxV {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #a3a3a3;
}

.ChatInput__name___3BXOI {
  font-weight: 800;
  width: 80%;
  margin-left: 10%;
  text-align: left;
}

.ChatInput__text___20XQh {
  margin-top: 3px;
  width: 80%;
  margin-left: 10%;
  text-align: left;
}