.Topic__container___3Y4Te {
  -webkit-border-radius: 12px;
     -moz-border-radius: 12px;
          border-radius: 12px;
  background: lightgrey;
  color: white;
  margin: 1.4em 0;
  display: -ms-grid;
  display: grid;
  height: 100%;
  -ms-grid-columns: 6fr 1fr;
      grid-template-columns: 6fr 1fr;
}

.Topic__activeCtn___AfKEI {
  background: #ff9b39;
}

.Topic__title___346WZ {
  font-size: 1.1em;
  font-weight: bold;
  font-family: Arial;
  padding: 18px 0 18px 14px;
}

.Topic__progress___11BUy {
  font-size: 1em;
  font-family: Arial;
  padding: 0 0 18px 14px;
}

.Topic__imageCtn___2wMVx {
  height: 100%;
}

.Topic__image___1ICm7 {
  padding-right: 0.9em;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  -webkit-transform: scale(1.2);
     -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
       -o-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 0.4;
}

.Topic__activeImg___319WM {
  opacity: 1;
}

.Topic__helper___5NlR6 {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}