.UserProfile__userProfileContainer___1l505 {
  width: 100%;
  padding-top: 20px;
}

.UserProfile__editIcon___3QkKu {
  float: right;
  color: darkgray;
  font-size: 20px;
}

.UserProfile__bold___15y0d {
  font-weight: bold;
  width: 160px;
}

.UserProfile__paddingBottom___WltiF {
  padding-bottom: 10px;
}

.UserProfile__textfield___1ASrX {
  display: inline-block !important;
  padding-bottom: 20px !important;
}

.UserProfile__error___3IK9N {
  color: red;
}

.UserProfile__formContainer___by-RK {
  width: 80%;
  margin: 0 auto;
}

.UserProfile__selectMenu___1qKz8 {
  z-index: 35001 !important;
}

.UserProfile__select___2GJiw {
  min-width: 200px;
}