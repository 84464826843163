.UserProfile__profileContainer___Dx4r8 {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.UserProfile__profileContainer___Dx4r8 .UserProfile__imageContainer___3K-Vw {
  cursor: pointer;
  text-align: right;
  display: inline-block;
  padding: 10px;
}
.UserProfile__profileContainer___Dx4r8 .UserProfile__imageContainer___3K-Vw .UserProfile__profileThumb___1h1Dz {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
          border-radius: 50px;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
}
.UserProfile__profileContainer___Dx4r8 .UserProfile__imageContainer___3K-Vw .UserProfile__profileThumb___1h1Dz img {
  max-width: 100px;
}
.UserProfile__profileContainer___Dx4r8 .UserProfile__infoContainer___14Fyk, .UserProfile__profileContainer___Dx4r8 .UserProfile__infoContainerTournament___VZ-Z3 {
  display: inline-block;
  font-size: 20px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}
.UserProfile__profileContainer___Dx4r8 .UserProfile__infoContainer___14Fyk p, .UserProfile__profileContainer___Dx4r8 .UserProfile__infoContainerTournament___VZ-Z3 p {
  display: inline-block;
  font-size: 20px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  margin: 0;
}
.UserProfile__profileContainer___Dx4r8 .UserProfile__infoContainer___14Fyk {
  width: 50%;
  padding: 10px;
}
.UserProfile__profileContainer___Dx4r8 .UserProfile__infoContainerTournament___VZ-Z3 {
  margin-top: 10px;
  width: 95%;
}

.UserProfile__points___2zT66, .UserProfile__pointsTournament___W0Emo {
  width: 90%;
  margin-left: 5%;
}

.UserProfile__teamName___3tBEQ {
  width: 100%;
  text-align: center;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
}

.UserProfile__userTeamName___2D5mU {
  width: 100%;
  text-align: center;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}

.UserProfile__teamName___3tBEQ, .UserProfile__userTeamName___2D5mU {
  padding: 7px 0px;
  margin-top: 5%;
  font-size: 25px;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
}

.UserProfile__teamPointsContainer___1Fsx1 {
  width: 60%;
  padding-bottom: 60%;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  position: relative;
  margin: 0 auto;
}

.UserProfile__teamPointsContainerTablet___1wdru {
  width: 50%;
  padding-bottom: 50%;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  position: relative;
  margin: 0 auto;
}

.UserProfile__teamName___3tBEQ, .UserProfile__userTeamName___2D5mU {
  position: relative;
}

.UserProfile__teamPoints___3Wt2- {
  width: 100%;
  text-align: center;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
}

.UserProfile__userTeamPoints___2p9BZ {
  width: 100%;
  text-align: center;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}

.UserProfile__teamPoints___3Wt2-, .UserProfile__userTeamPoints___2p9BZ {
  font-size: 48px;
  padding-top: 10px;
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
}

.UserProfile__styledPoints___3lAr_ {
  font-weight: bold;
  color: var(--color-userProfilePoints);
}