.QrScannerOverlay__outer___2oum7 {
  display: table;
  height: 100vh;
  margin: auto;
}

.QrScannerOverlay__inner___n5HIN {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.QrScannerOverlay__solidBackground___3_Trh {
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  min-width: 70vw;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
}

.QrScannerOverlay__headline___sgfks {
  font-size: 24px;
  padding: 20px;
}

.QrScannerOverlay__infotext___1zoQ0 {
  font-size: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

.QrScannerOverlay__scannerSquare___1CCoN {
  width: 50vh;
  height: 50vh;
  border: 7px solid #232323;
  margin: 0 auto 20px auto;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  outline: 4px solid #ffffff88;
}

.QrScannerOverlay__innerSquare___j0G2g {
  width: 25vh;
  height: 25vh;
  border: 2px solid greenyellow;
  margin: 50% auto 42px auto;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
}

.QrScannerOverlay__cancelButtonOuter___2gnW4 {
  padding: 10px 20px;
}