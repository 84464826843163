.PageSlide__container___3yZb2 {
  position: relative;
  width: 100%;
}

.PageSlide__main___2WNDs {
  position: relative;
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  -o-transition: -o-transform 500ms ease-in-out;
  -moz-transition: transform 500ms ease-in-out, -moz-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out, -moz-transform 500ms ease-in-out, -o-transform 500ms ease-in-out;
  will-change: transform;
  width: 100vw;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.PageSlide__mainInContainer___1LgVy {
  position: relative;
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  -o-transition: -o-transform 500ms ease-in-out;
  -moz-transition: transform 500ms ease-in-out, -moz-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out, -moz-transform 500ms ease-in-out, -o-transform 500ms ease-in-out;
  will-change: transform;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.PageSlide__extra___1W0Zi {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 1;
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  -o-transition: -o-transform 500ms ease-in-out;
  -moz-transition: transform 500ms ease-in-out, -moz-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out, -moz-transform 500ms ease-in-out, -o-transform 500ms ease-in-out;
  will-change: transform;
  background-color: #FFFFFF;
  -webkit-overflow-scrolling: touch;
}

.PageSlide__extraInContainer___2KJek {
  position: fixed;
  top: 0;
  width: 90%;
  height: 100%;
  left: 50%;
  padding: 30px 5%;
  z-index: 10;
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  -o-transition: -o-transform 500ms ease-in-out;
  -moz-transition: transform 500ms ease-in-out, -moz-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out, -moz-transform 500ms ease-in-out, -o-transform 500ms ease-in-out;
  will-change: transform;
  background-color: #FFFFFF;
  -webkit-overflow-scrolling: touch;
}

.PageSlide__browserExtraInContainer___Mpcsa {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 50%;
  padding: 30px 5%;
  z-index: 10;
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  -o-transition: -o-transform 500ms ease-in-out;
  -moz-transition: transform 500ms ease-in-out, -moz-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out, -moz-transform 500ms ease-in-out, -o-transform 500ms ease-in-out;
  will-change: transform;
  background-color: #FFFFFF;
  -webkit-overflow-scrolling: touch;
  max-width: var(--app-maxWidth);
}

.PageSlide__browserExtraInContainerSafari___2bKJs {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: -webkit-calc(50% - 10px);
  left: -moz-calc(50% - 10px);
  left: calc(50% - 10px);
  padding: 30px 5%;
  z-index: 10;
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  -o-transition: -o-transform 500ms ease-in-out;
  -moz-transition: transform 500ms ease-in-out, -moz-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out, -moz-transform 500ms ease-in-out, -o-transform 500ms ease-in-out;
  will-change: transform;
  background-color: #FFFFFF;
  -webkit-overflow-scrolling: touch;
  max-width: var(--app-maxWidth);
}

.PageSlide__safariPadding___2AVi0 {
  padding: 60px 5%;
}

.PageSlide__speedTransition___-J_g6 {
  -webkit-transition: -webkit-transform 130ms ease-in-out;
  transition: -webkit-transform 130ms ease-in-out;
  -o-transition: -o-transform 130ms ease-in-out;
  -moz-transition: transform 130ms ease-in-out, -moz-transform 130ms ease-in-out;
  transition: transform 130ms ease-in-out;
  transition: transform 130ms ease-in-out, -webkit-transform 130ms ease-in-out, -moz-transform 130ms ease-in-out, -o-transform 130ms ease-in-out;
  will-change: transform;
}

.PageSlide__close___1DRhm {
  position: absolute;
  left: 20px;
  top: 25px;
  color: var(--color-ervBlue);
}

.PageSlide__title___ZgpLk {
  margin: var(--navBarMargin);
  max-height: var(--navBarMaxHeight);
  text-align: var(--navBarTextAlign);
  font-size: var(--navBarFontSize);
  font-weight: var(--navBarFontWeight);
  padding-top: var(--navBarPaddingTop);
  line-height: var(--navBarLineHeight);
  overflow: var(--navBarOverflow);
  vertical-align: var(--navBarVerticalAlign);
  letter-spacing: -0.4px;
  max-width: var(--navBarMaxWidth);
  width: 500px;
}

.PageSlide__toolbar___3HFWi {
  height: var(--navBarHeightPx);
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  background-color: var(--color-navbarBackground);
  border-bottom: 1px solid var(--color-ervGrey);
}

.PageSlide__itemArrow___1Xczv {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 3%;
  color: var(--color-ervBlue);
}

.PageSlide__scroller___QSNkJ {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.PageSlide__marginTopNoSafeArea___1r3BM {
  margin-top: -webkit-calc(var(--navBarHeightPx));
  margin-top: -moz-calc(var(--navBarHeightPx));
  margin-top: calc(var(--navBarHeightPx));
}

.PageSlide__marginTop___2scJY {
  margin-top: -webkit-calc(var(--navBarHeightPx));
  margin-top: -moz-calc(var(--navBarHeightPx));
  margin-top: calc(var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .PageSlide__marginTop___2scJY {
    margin-top: -webkit-calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
    margin-top: -moz-calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
    margin-top: calc(var(--navBarHeightPx) + var(--safe-area-top-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .PageSlide__marginTop___2scJY {
    margin-top: -webkit-calc(var(--navBarHeightPx) + var(--safe-area-top-env));
    margin-top: -moz-calc(var(--navBarHeightPx) + var(--safe-area-top-env));
    margin-top: calc(var(--navBarHeightPx) + var(--safe-area-top-env));
  }
}

.PageSlide__withoutBothVh___Q3vZS {
  height: 100vh;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .PageSlide__withoutBothVh___Q3vZS {
    height: -webkit-calc(100vh - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .PageSlide__withoutBothVh___Q3vZS {
    height: -webkit-calc(100vh - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.PageSlide__withBottomBarVh___39rql {
  height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .PageSlide__withBottomBarVh___39rql {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .PageSlide__withBottomBarVh___39rql {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.PageSlide__withoutBoth___3Bb9K {
  height: 100%;
}

.PageSlide__withBottomBar___2PuaX {
  height: -webkit-calc(100% - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: -moz-calc(100% - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: calc(100% - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}

.PageSlide__withoutBottomBar___-EwGD {
  height: -webkit-calc(100% - var(--navBarHeightPx));
  height: -moz-calc(100% - var(--navBarHeightPx));
  height: calc(100% - var(--navBarHeightPx));
}

.PageSlide__withoutNavBar___2C3fx {
  height: -webkit-calc(100% - var(--bottomBarHeightPx));
  height: -moz-calc(100% - var(--bottomBarHeightPx));
  height: calc(100% - var(--bottomBarHeightPx));
}

.PageSlide__withoutNavBarVh___U76MT {
  height: -webkit-calc(100vh - var(--bottomBarHeightPx));
  height: -moz-calc(100vh - var(--bottomBarHeightPx));
  height: calc(100vh - var(--bottomBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .PageSlide__withoutNavBarVh___U76MT {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .PageSlide__withoutNavBarVh___U76MT {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.PageSlide__withoutBottomBarVh___2MhGj {
  height: -webkit-calc(100vh - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--navBarHeightPx));
  height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .PageSlide__withoutBottomBarVh___2MhGj {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .PageSlide__withoutBottomBarVh___2MhGj {
    height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.PageSlide__withoutBottomBarMax___170vX {
  max-height: -webkit-calc(100vh - var(--navBarHeightPx));
  max-height: -moz-calc(100vh - var(--navBarHeightPx));
  max-height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .PageSlide__withoutBottomBarMax___170vX {
    max-height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    max-height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    max-height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .PageSlide__withoutBottomBarMax___170vX {
    max-height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    max-height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    max-height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}