.DrupalMazarsCertNugget__textfield___2PnDJ {
  width: 150px !important;
  display: block !important;
  margin-top: 20px !important;
}

.DrupalMazarsCertNugget__button___6vFge {
  display: block !important;
  margin-top: 20px !important;
}

.DrupalMazarsCertNugget__error___1dMAr {
  color: red;
  padding: 20px 0;
}