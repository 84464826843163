.AnswersBox__answer_box_span___2L_7l table {
  width: 100%;
}
.AnswersBox__answer_box_span___2L_7l table svg {
  width: 100%;
}

.AnswersBox__freetextAnswer___26wNV {
  margin-bottom: 30px !important;
}
.AnswersBox__freetextAnswer___26wNV input {
  text-align: center;
  font-size: 20px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
}

.AnswersBox__seAnswer___CRHay {
  margin-bottom: 30px !important;
}
.AnswersBox__seAnswer___CRHay textarea {
  text-align: left;
  font-size: 20px;
  line-height: 22px;
  background-color: #FFFFFF;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  height: 100px !important;
  padding: 10px;
}

.AnswersBox__cursor___4CeVh {
  position: absolute;
  height: 27px;
  width: 2px;
  background-color: black;
  z-index: 20;
  left: 50%;
  top: 50px;
  -webkit-animation: AnswersBox__blink___1sohv 1s step-start 0s infinite;
     -moz-animation: AnswersBox__blink___1sohv 1s step-start 0s infinite;
       -o-animation: AnswersBox__blink___1sohv 1s step-start 0s infinite;
          animation: AnswersBox__blink___1sohv 1s step-start 0s infinite;
}

@-webkit-keyframes AnswersBox__blink___1sohv {
  50% {
    opacity: 0;
  }
}

@-moz-keyframes AnswersBox__blink___1sohv {
  50% {
    opacity: 0;
  }
}

@-o-keyframes AnswersBox__blink___1sohv {
  50% {
    opacity: 0;
  }
}

@keyframes AnswersBox__blink___1sohv {
  50% {
    opacity: 0;
  }
}