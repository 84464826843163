.ErvBottomBar__bottomBar___1FPbA {
  width: 100%;
  font-size: 14px;
  height: 64px;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  right: 0;
  background: var(--color-bottomBarBackground);
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  will-change: opacity;
  -webkit-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .ErvBottomBar__bottomBar___1FPbA {
    bottom: -webkit-calc(var(--safe-area-bottom-constant));
    bottom: -moz-calc(var(--safe-area-bottom-constant));
    bottom: calc(var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .ErvBottomBar__bottomBar___1FPbA {
    bottom: -webkit-calc(var(--safe-area-bottom-env));
    bottom: -moz-calc(var(--safe-area-bottom-env));
    bottom: calc(var(--safe-area-bottom-env));
  }
}
.ErvBottomBar__bottomBar___1FPbA svg {
  width: 100%;
  height: 100%;
}

.ErvBottomBar__container___3xI-Q {
  height: 54px;
  position: relative;
  display: inline-block;
  margin-top: 15px;
  text-align: center;
}

.ErvBottomBar__multilineTabs___12J-Y {
  margin-top: 5px;
}

.ErvBottomBar__title___JAGWo {
  font-size: 2.3vw;
  line-height: 2.6vw;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translate3d(-50%, 0, 0);
       transform: translate3d(-50%, 0, 0);
  height: 23px;
  width: 95%;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  overflow: hidden;
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
@media (min-width: 600px) {
  .ErvBottomBar__title___JAGWo {
    font-size: 1.6vw;
    line-height: 1.7vw;
  }
}

.ErvBottomBar__noTitle___3j8NC {
  height: 35px !important;
}

.ErvBottomBar__iconActive___7Rxqu {
  height: 24px;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
     -moz-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  position: absolute;
}
.ErvBottomBar__iconActive___7Rxqu svg path, .ErvBottomBar__iconActive___7Rxqu svg rect, .ErvBottomBar__iconActive___7Rxqu svg polygon, .ErvBottomBar__iconActive___7Rxqu svg circle {
  fill: var(--color-bottomBarActive);
  height: 25px;
  width: auto;
  margin-top: 5px;
}

.ErvBottomBar__newIconActive___2N1xX {
  height: 24px;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
     -moz-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  position: absolute;
}
.ErvBottomBar__newIconActive___2N1xX svg path, .ErvBottomBar__newIconActive___2N1xX svg rect, .ErvBottomBar__newIconActive___2N1xX svg polygon, .ErvBottomBar__newIconActive___2N1xX svg circle {
  fill: var(--color-newBottomBarActive);
  height: 25px;
  width: auto;
  margin-top: 5px;
}

.ErvBottomBar__iconUnactive___1Yx3_ {
  height: 24px;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
     -moz-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  position: absolute;
}
.ErvBottomBar__iconUnactive___1Yx3_ svg path, .ErvBottomBar__iconUnactive___1Yx3_ svg rect, .ErvBottomBar__iconUnactive___1Yx3_ svg polygon, .ErvBottomBar__iconUnactive___1Yx3_ svg circle {
  fill: var(--color-bottomBarInactive);
  height: 25px;
  width: auto;
  margin-top: 5px;
}