.QuizSubjects__buttonWithBorder___3JxMx {
  font-weight: bold !important;
  border: 1px solid black !important;
}

.QuizSubjects__boldButton___3Bm1r {
  font-weight: bold !important;
}

.QuizSubjects__categoriesFrame___2X8ar {
  width: 90%;
  position: relative;
  margin: 5vh auto;
}

.QuizSubjects__buttonParticipants___2wVQo {
  margin-right: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  text-align: center;
  font-size: 1.1em !important;
  line-height: 1.1em !important;
  height: 80px !important;
  text-transform: none !important;
  width: 100%;
}
.QuizSubjects__buttonParticipants___2wVQo.QuizSubjects__withIcon___2RxNX {
  padding-left: 95px;
}

.QuizSubjects__categoryIcon___1uOtn {
  max-height: 50px;
  max-width: 50px;
  position: absolute;
  left: 15px;
  top: 50%;
  text-align: right;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.QuizSubjects__categoryName___3stkQ {
  text-align: left;
  font-weight: 500;
  padding-bottom: 30px;
  width: 60%;
  position: absolute;
  top: 10px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}

.QuizSubjects__learningProgress___3Xkei {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 7px;
  height: 100%;
}

.QuizSubjects__learningProgressPie___1k_UF {
  width: 22px;
  height: 22px;
}

.QuizSubjects__descriptionButton___2BvVC {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: help;
}

.QuizSubjects__bottomInfo___15xEC {
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  height: 30px;
  width: 300px;
  position: absolute;
  bottom: 6px;
}

.QuizSubjects__questionCount___WWEIJ {
  margin-left: 32px;
  position: absolute;
  height: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}