.LiveTicker__alarmInactive___2GvXY {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background-color: gray;
  border: 2px solid black;
  display: inline-block;
}

.LiveTicker__alarmActive___2Aorr {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background-color: #75d13f;
  border: 2px solid black;
  display: inline-block;
}

.LiveTicker__alarmInfo___3edwg {
  display: inline-block;
  line-height: 20px;
  padding-left: 10px;
  font-size: 16px;
  margin-top: 2px;
  vertical-align: top;
}

.LiveTicker__alarmContainer___2RtPk {
  margin-top: 5px;
  margin-left: 5px;
}

.LiveTicker__chat___l3Y5h {
  width: 100%;
  background-color: #ededed;
  padding: 5px;
  height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - 120px);
  height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - 120px);
  height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - 120px);
}
@media (min-width: 600px) {
  .LiveTicker__chat___l3Y5h {
    padding: 20px;
  }
}

.LiveTicker__chatMobile___1KGUH {
  width: 100%;
  background-color: #ededed;
  padding: 5px;
  height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - 330px);
  height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - 330px);
  height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - 330px);
}

.LiveTicker__downloadContainer___u8ryJ {
  padding-top: 10px;
  left: 15px;
  bottom: 15px;
  color: var(--color-chatColor);
  font-size: 12px;
  display: inline-block;
  position: absolute;
}

.LiveTicker__chatToggleContainer___2zEjn {
  padding-top: 10px;
  margin-left: 75%;
  width: 23%;
  color: var(--color-chatColor);
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.LiveTicker__chatToggle___1dqTj {
  display: block;
  padding-bottom: 10px;
}

.LiveTicker__switchColors___25sim {
  color: var(--color-chatColor) !important;
}

.LiveTicker__switchColorsDeactivated___EWreT {
  color: #535353 !important;
}

.LiveTicker__barColor___2U_5I {
  background-color: var(--color-chatColor) !important;
}

.LiveTicker__barColorDeactivated___3apNZ {
  background-color: #535353 !important;
}

.LiveTicker__downloadButton___2h3SV {
  margin-right: 5px;
}