.DrupalSwipeNugget__navigationContainer___10myH {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-top: 50px;
}

.DrupalSwipeNugget__buttonLeft___18GJq {
  font-size: 30px;
  left: 0;
  bottom: 0;
  display: inline-block;
  position: absolute;
}

.DrupalSwipeNugget__buttonLeftTop___2yICF {
  font-size: 30px;
  left: 0;
  top: 0;
  display: inline-block;
  position: absolute;
}

.DrupalSwipeNugget__buttonRightTop___3QKDh {
  font-size: 30px;
  right: 0;
  top: 0;
  display: inline-block;
  position: absolute;
}

.DrupalSwipeNugget__arrowIcon___ZIh06 {
  padding: 2px;
}

.DrupalSwipeNugget__buttonRight___17lPQ {
  font-size: 30px;
  right: 0;
  bottom: 0;
  display: inline-block;
  position: absolute;
}

.DrupalSwipeNugget__bulletContainer___3yVud {
  font-size: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  display: inline-block;
  position: absolute;
}

.DrupalSwipeNugget__nuggetBullet___3sJAj {
  display: inline-block;
  height: 7px;
  width: 7px;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  margin: 3px;
}

.DrupalSwipeNugget__questionHeader___1vZSM {
  width: 100%;
  font-size: 15px;
  color: #4a4a4a !important;
}

.DrupalSwipeNugget__swipeImage____HYVY {
  max-width: 100%;
  margin: 0 auto;
  left: 50%;
  position: relative;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.DrupalSwipeNugget__subtitle___6DwUR {
  max-width: 100%;
  margin: 0 auto;
  left: 50%;
  position: relative;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 16px;
  padding: 10px 0;
  color: var(--color-textfieldCaption);
}

.DrupalSwipeNugget__icon___jBtfx {
  position: relative;
  display: inline-block;
  color: var(--color-navbarBackground) !important;
  font-size: 30px !important;
}

.DrupalSwipeNugget__iconContainer___2Tf63 {
  position: absolute;
  left: 30px;
  top: 30px;
}

.DrupalSwipeNugget__fullScreenOverlayImg___3vA0o {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.DrupalSwipeNugget__portraitOverlayImgContainer___2jQC0 {
  margin: auto;
  height: 80%;
  width: 100%;
}

.DrupalSwipeNugget__portraitOverlayImgContainer___2jQC0 > div {
  height: 100% !important;
  width: 100% !important;
}

.DrupalSwipeNugget__resetButton___3sSjZ {
  width: 80% !important;
  height: 40px;
  margin: 10px 10% !important;
  display: block;
  background-color: var(--color-buttonBackground) !important;
  color: var(--color-buttonCaption) !important;
  -webkit-border-radius: 20px !important;
     -moz-border-radius: 20px !important;
          border-radius: 20px !important;
  -webkit-box-shadow: darkgray 2px 2px 5px 0px;
     -moz-box-shadow: darkgray 2px 2px 5px 0px;
          box-shadow: darkgray 2px 2px 5px 0px;
}

.DrupalSwipeNugget__galleryContainer___32sgz {
  width: 80%;
  position: relative;
  margin-left: 10%;
}

.DrupalSwipeNugget__innerGalleryContainer___3iVO1 {
  position: relative;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  width: 100%;
}

.DrupalSwipeNugget__galleryImage___LA9zi {
  height: 100%;
  position: relative;
  display: -ms-inline-grid;
  display: inline-grid;
  opacity: 0.5;
  overflow: hidden;
}
.DrupalSwipeNugget__galleryImage___LA9zi img {
  max-width: unset !important;
  max-height: unset !important;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.DrupalSwipeNugget__activeImage___3xw_j {
  border: 3px solid var(--color-buttonBackground);
  opacity: 1;
}