.RunningGames__buttonOpponents___3cSA_ {
  width: 90%;
  margin: 1px;
  text-align: left;
  padding-left: 25%;
}

.RunningGames__opponentsText___3txxj {
  margin: 7px 1% 7px 5%;
  font-size: 1.4em;
  line-height: 40px;
  vertical-align: middle;
  font-weight: 400;
}

.RunningGames__userText___2KHl0 {
  margin: 7px 1% 7px 5%;
  font-size: 1.4em;
  line-height: 40px;
  vertical-align: middle;
  font-weight: 700;
}

.RunningGames__thumb___17S7M {
  width: 40px;
  height: 40px;
  /*border: 1px solid #ccc;*/
  -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
          border-radius: 50px;
  margin-right: 20px;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
}
.RunningGames__thumb___17S7M img {
  max-width: 40px;
}

.RunningGames__scrollArrow___1sPga {
  bottom: 20px;
}