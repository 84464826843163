.QRCodeReferrer__container___2dwMV {
  display: block;
  margin: 20px auto;
}

.QRCodeReferrer__headline___1-aA4 {
  font-weight: bold;
  text-align: center;
}

.QRCodeReferrer__infotext___33xJA {
  text-align: center;
  color: #424242;
}

.QRCodeReferrer__wrapper___3BFrA {
  margin: 0px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.QRCodeReferrer__progressText___2rLZG {
  text-align: center;
  margin-top: 20px;
}

.QRCodeReferrer__startButton___1HE3I {
  display: block;
  margin: 20px auto;
  width: 100%;
}

.QRCodeReferrer__refreshButton___3BFx8 {
  display: block;
  margin: 20px auto;
}

.QRCodeReferrer__errorMessage___K6Dwe {
  text-align: center;
}