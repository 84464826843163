.ERGOComponents__listContainer___XHZxJ {
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid var(--color-ervLightGrey);
  border-top: 1px solid var(--color-ervLightGrey);
  color: var(--color-ervText);
}
.ERGOComponents__listContainer___XHZxJ a {
  text-decoration: none;
  color: var(--color-ervBlue) !important;
}
.ERGOComponents__listContainer___XHZxJ a:visited {
  color: var(--color-ervText);
}

.ERGOComponents__listItem___1NpRn {
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid var(--color-ervLightGrey);
  position: relative;
  font-size: 15px;
  list-style: none;
}
.ERGOComponents__listItem___1NpRn ul {
  list-style: none;
  width: 100%;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}
.ERGOComponents__listItem___1NpRn li {
  width: 100%;
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

.ERGOComponents__listItem___1NpRn:last-child {
  border-bottom: none;
}

.ERGOComponents__list___1EAWl {
  width: 94%;
  margin: 0 3%;
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

.ERGOComponents__container___33eYV {
  overflow: auto;
  height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .ERGOComponents__container___33eYV {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .ERGOComponents__container___33eYV {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.ERGOComponents__contactHeader___3gUca {
  width: 100%;
  min-height: 80px;
  position: relative;
}

.ERGOComponents__contactInfoCircle___3yr4G {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 1em;
  height: 1em;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
}

.ERGOComponents__contactName___3apx0 {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-left: 10%;
  width: 50%;
  display: inline-block;
}

.ERGOComponents__contactMessage___uvXUa {
  color: #919191;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 5;
  padding: 10px;
}

.ERGOComponents__qrCode___1esSs {
  color: #919191;
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 5;
  padding: 10px;
}

.ERGOComponents__onlineStatus___3o-k5 {
  background-color: green;
  position: absolute;
  right: 7px;
  top: 7px;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
}

.ERGOComponents__contactState___15_Im {
  color: #919191;
  font-size: 14px;
  position: relative;
  padding-left: 10%;
  width: 80%;
  display: block;
}

.ERGOComponents__contactListName___WwHgt {
  max-width: 90%;
}

.ERGOComponents__contactListContainer___3Qa09 {
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid var(--color-ervLightGrey);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

.ERGOComponents__contactListScroller___3ae2P {
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid var(--color-ervLightGrey);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
  display: block;
  position: relative;
  height: auto;
}

.ERGOComponents__contactList___2fG8e {
  height: auto;
  width: 94%;
  margin: 0 3%;
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
}

.ERGOComponents__contactItem___kLDo_, .ERGOComponents__languageItem___2Ywck {
  width: 100%;
  font-size: 18px;
  line-height: 22px;
  color: black;
  list-style: none;
  border-bottom: 1px solid var(--color-ervLightGrey);
  padding: 10px;
  position: relative;
  font-weight: 600;
  z-index: 1;
}

.ERGOComponents__contactContainer___1CXZ9 {
  height: 100%;
  width: 90%;
  position: relative;
}

.ERGOComponents__contactItem___kLDo_:last-child, .ERGOComponents__languageItem___2Ywck:last-child {
  border-bottom: none;
}

.ERGOComponents__header___8iJUf {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.ERGOComponents__name___n2rDf {
  color: black;
  font-size: 2em;
}

.ERGOComponents__created___1gOOC {
  color: #b9b9b9;
  font-size: 1em;
}

.ERGOComponents__progressContainer___105oC, .ERGOComponents__informationContainer___2day3 {
  width: 100%;
  position: relative;
}

.ERGOComponents__progressStatus___2iUF4 {
  font-size: 1em;
  margin-bottom: 10px;
  font-weight: bold;
}

.ERGOComponents__progressBar___15z_N {
  width: 100%;
  height: 30px;
  background-color: #b9b9b9;
  -webkit-border-radius: 15px;
     -moz-border-radius: 15px;
          border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.ERGOComponents__progress___3G9w4 {
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
}

.ERGOComponents__card___2i7r5 {
  margin: 10px 0;
  padding: 20px;
  position: relative;
}

.ERGOComponents__messageCard___2MtCZ {
  margin: 10px 0;
  padding: 20px;
  position: relative;
  width: 100%;
  height: 100%;
}

.ERGOComponents__pictureContainer___uAUtJ {
  position: relative;
  width: 15em;
  height: 15em;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  max-width: 50vw;
  max-height: 50vw;
}

.ERGOComponents__picture___3RbA5 {
  width: 100%;
  height: auto;
  top: 50% !important;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
}

.ERGOComponents__editIconContainer___1wTYB {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  z-index: 5;
}

.ERGOComponents__editIcon___2aglj {
  width: 100% !important;
  height: 100% !important;
  color: #b9b9b9 !important;
}

.ERGOComponents__title___3nBka {
  font-weight: bold;
  color: black;
  font-size: 1.4em;
  padding: 20px 0;
}

.ERGOComponents__infoText___1NkC8 {
  color: #b9b9b9;
  font-size: 1em;
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.ERGOComponents__text___2E9m0 {
  color: black;
  size: 1em;
  position: relative;
  display: block;
}

.ERGOComponents__divider___2T8Eg {
  background-color: #b9b9b9;
  width: 100%;
  margin-bottom: 20px;
}

.ERGOComponents__picButton___ZJbNx {
  padding: 15px !important;
  display: inline-block !important;
  vertical-align: top !important;
}

.ERGOComponents__saveButton___eVPgp {
  padding: 15px !important;
  margin: 10px 0 !important;
  display: inline-block;
}

.ERGOComponents__deleteButton___1mbET {
  padding: 0 !important;
  margin: 0 !important;
  text-decoration: underline !important;
  color: #b9b9b9 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  width: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  text-transform: none !important;
}

.ERGOComponents__picPreviewContainer___3klTZ {
  height: 44px;
  width: 44px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  margin-right: 20px;
}

.ERGOComponents__picPreview___1J1Fu {
  margin-bottom: 10px;
}

.ERGOComponents__previewPic___2ZTzw {
  width: 100%;
  height: 100%;
}

.ERGOComponents__oldMessages___2vHTe {
  position: relative;
  width: 100%;
  height: 75%;
  overflow: scroll;
}

.ERGOComponents__sendContainer___kiMdV {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 30%;
  padding: 10px 5% 20px;
  background-color: white;
}

.ERGOComponents__input___37WBo {
  width: 80%;
  float: left;
  display: inline-block;
  position: relative;
}

.ERGOComponents__iconContainer___2Cdmm {
  width: 20%;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.ERGOComponents__sendIcon___1Ly-1 {
  width: 50% !important;
  padding: 5px !important;
  height: auto !important;
  position: absolute;
  right: 0;
  top: 0;
  color: var(--color-bottomBarInactive);
}

.ERGOComponents__imageIcon___dvA6D {
  width: 50% !important;
  padding: 5px !important;
  height: auto !important;
  position: absolute;
  left: 0;
  top: 0;
  color: var(--color-bottomBarInactive);
}

.ERGOComponents__chatContainer___2HQc5 {
  width: 90%;
  height: 100%;
  padding: 10px 0;
  margin: 0 5%;
  overflow: hidden;
  position: relative;
}

.ERGOComponents__messageDate___ikG13 {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 10px;
  color: inherit;
}

.ERGOComponents__singleMessageFromMe___2G8qE {
  height: auto;
  color: white;
  background-color: var(--color-ergoDarkRed);
  -webkit-border-radius: 10px 20px 0 10px;
     -moz-border-radius: 10px 20px 0 10px;
          border-radius: 10px 20px 0 10px;
  margin: 10px 10px 10px auto;
  padding: 30px 20px 30px 20px;
  width: 600px;
  max-width: 80%;
  float: right;
  display: block;
  position: relative;
}
.ERGOComponents__singleMessageFromMe___2G8qE img {
  position: relative;
  width: 100%;
  height: auto;
}

.ERGOComponents__singleMessageFromOther___1YRUf {
  height: auto;
  color: black;
  background-color: white;
  -webkit-border-radius: 20px 10px 10px 0;
     -moz-border-radius: 20px 10px 10px 0;
          border-radius: 20px 10px 10px 0;
  margin: 10px 10px auto 10px;
  padding: 30px 20px 30px 20px;
  width: 600px;
  max-width: 80%;
  float: left;
  display: block;
  border: 1px solid var(--color-ergoDarkRed);
  position: relative;
}
.ERGOComponents__singleMessageFromOther___1YRUf img {
  position: relative;
  width: 100%;
  height: auto;
}

.ERGOComponents__chatTitle____0r2p {
  color: var(--color-ergoDarkRed);
  font-size: 16px;
  font-weight: bold;
}

.ERGOComponents__imageContainer___3JhUq {
  width: 100%;
  height: 80px;
  position: relative;
}
.ERGOComponents__imageContainer___3JhUq img {
  position: relative;
  height: 100%;
  width: auto;
  margin: 0 auto;
}

.ERGOComponents__closeIcon___3VjB7 {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  color: var(--color-bottomBarInactive);
}

.ERGOComponents__formTitle___1UDhj {
  font-weight: bold;
  padding: 10px 0;
}

.ERGOComponents__errorMessage___vp48Q {
  font-weight: bold;
  padding: 10px 0;
  color: red;
}