.LiveTickerMessage__outer___d8lAl {
  position: relative;
  background-color: white;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  overflow: hidden;
  will-change: max-height !important;
  height: auto;
  max-height: 0;
  -webkit-transition: max-height 5s ease-in-out, padding 100ms ease-in-out, margin 100ms ease-in-out !important;
  -o-transition: max-height 5s ease-in-out, padding 100ms ease-in-out, margin 100ms ease-in-out !important;
  -moz-transition: max-height 5s ease-in-out, padding 100ms ease-in-out, margin 100ms ease-in-out !important;
  transition: max-height 5s ease-in-out, padding 100ms ease-in-out, margin 100ms ease-in-out !important;
}

.LiveTickerMessage__author___2liyd {
  color: darkgray !important;
  font-size: 10px !important;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  word-break: break-word;
}

.LiveTickerMessage__authorMobile___1nXRR {
  color: darkgray !important;
  font-size: 8px !important;
  line-height: 9px !important;
  -webkit-margin-after: 5px !important;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  word-break: break-word;
}

.LiveTickerMessage__message___2DEFn {
  font-size: 16px !important;
}

.LiveTickerMessage__messageMobile___1_2RS {
  font-size: 16px !important;
}

.LiveTickerMessage__date___3E5kZ {
  color: darkgray !important;
  font-size: 10px !important;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  word-break: break-word;
}

.LiveTickerMessage__dateMobile___bBi_j {
  color: darkgray !important;
  font-size: 8px !important;
  line-height: 9px !important;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  word-break: break-word;
}

.LiveTickerMessage__answerBlock___3ILQa {
  margin-top: 5px;
}