.QuizHome__button___2O0Yz {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  display: block;
  background-color: var(--color-buttonBorder);
  color: #FFFFFF;
  margin: auto;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  font-size: 1em;
}

.QuizHome__spanText___1b2HP {
  vertical-align: -25%;
  overflow: hidden;
  width: 60%;
  display: inline-block;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.QuizHome__spanTextWide___3ta27 {
  vertical-align: -25%;
  overflow: hidden;
  width: 70%;
  display: inline-block;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.QuizHome__spanRank___1Hi4G {
  width: 15%;
  display: inline-block;
  vertical-align: -30%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.QuizHome__overlayContainer___3C5Ln {
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.7;
}

.QuizHome__popupContainer___jAs6V {
  -webkit-background-size: contain !important;
     -moz-background-size: contain !important;
          background-size: contain !important;
  width: 90%;
  /*height: 225px;*/
  position: absolute;
  top: 25%;
}

.QuizHome__buttonPopUp___2ZpPj {
  width: 90%;
  margin: 10px;
  border: 1px var(--color-buttonBorderSecondary) solid;
  color: var(--color-buttonCaptionSecondary);
}

.QuizHome__opponentPoints___2R2jL {
  margin: 7px 5%;
  font-size: 1.4em;
  font-weight: 900;
  color: var(--color-buttonCaption);
  display: inline-block;
}

.QuizHome__userPoints___3eG8e {
  margin: 7px 5%;
  font-size: 1.4em;
  font-weight: 900;
  display: inline-block;
}

.QuizHome__icon___22NjX {
  font-size: 30px;
  vertical-align: middle;
}

.QuizHome__messageIcon___5frec {
  margin-right: 10%;
  font-size: 30px;
  vertical-align: middle;
}

.QuizHome__homeHeader___2Rseb {
  position: fixed;
  height: 178px;
  width: 100%;
  overflow: hidden;
  z-index: 5;
}

.QuizHome__homeHeaderKesi___1Se1M {
  top: 0 !important;
  position: absolute !important;
}

.QuizHome__homeHeaderTournament___2sPcu {
  height: 224px;
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 5;
}

.QuizHome__homeHeaderTournamentTablet___Q2OF3 {
  height: 276px;
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 5;
}

.QuizHome__homeHeaderWidescreen___23ZjM {
  position: fixed;
  height: 178px;
  left: 300px;
  right: 0;
  overflow: hidden;
  z-index: 5;
}

.QuizHome__homeHeaderTournamentWidescreen___3xfWl {
  position: fixed;
  height: 224px;
  left: 300px;
  right: 0;
  overflow: hidden;
  z-index: 5;
}

.QuizHome__list___1qAl2 {
  padding-top: 170px;
  z-index: 3;
  max-width: 100%;
  margin-top: 10px;
  height: 100%;
}

.QuizHome__listTournament___3Bqs5 {
  padding-top: 216px;
  z-index: 3;
  max-width: 100%;
  margin-top: 10px;
  height: 100%;
}

.QuizHome__listTournamentTablet___2ZhrV {
  padding-top: 276px;
  z-index: 3;
  max-width: 100%;
  margin-top: 10px;
  height: 100%;
}

.QuizHome__listContainer___vpeA3 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.QuizHome__listItem___6cIGk {
  cursor: pointer;
  border-bottom: 1px solid var(--color-buttonCaption);
  font-size: 14px !important;
  min-height: 30px !important;
  padding: 6px !important;
  white-space: nowrap;
}

.QuizHome__innerContent___3bcQ4 {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  clear: both;
}

.QuizHome__innerContentExtraHeight___u4l1X {
  max-height: -webkit-calc(100vh - 290px);
  max-height: -moz-calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .QuizHome__innerContentExtraHeight___u4l1X {
    max-height: -webkit-calc(100vh - 290px - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    max-height: -moz-calc(100vh - 290px - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    max-height: calc(100vh - 290px - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .QuizHome__innerContentExtraHeight___u4l1X {
    max-height: -webkit-calc(100vh - 290px - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    max-height: -moz-calc(100vh - 290px - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    max-height: calc(100vh - 290px - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.QuizHome__innerContentNormalHeight___3TN-r {
  max-height: -webkit-calc(100vh - 234px);
  max-height: -moz-calc(100vh - 234px);
  max-height: calc(100vh - 234px);
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .QuizHome__innerContentNormalHeight___3TN-r {
    max-height: -webkit-calc(100vh - 234px - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    max-height: -moz-calc(100vh - 234px - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    max-height: calc(100vh - 234px - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .QuizHome__innerContentNormalHeight___3TN-r {
    max-height: -webkit-calc(100vh - 234px - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    max-height: -moz-calc(100vh - 234px - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    max-height: calc(100vh - 234px - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.QuizHome__tabsContainer___1tqRo, .QuizHome__tabsContainerTournament___11IIp {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  line-height: 1.7;
}

.QuizHome__tabsContainerTournament___11IIp {
  margin-top: 0;
}

.QuizHome__tab___2vv1t {
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 60px;
  font-size: 0.85em !important;
}

.QuizHome__tabNoCaps___SflFx {
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 60px;
  font-size: 0.85em !important;
  text-transform: none !important;
}

.mdl-tabs__tab-bar {
  border-bottom: 0;
}

.mdl-list {
  padding-bottom: 0 !important;
}

.mdl-tabs__tab.is-active {
  font-weight: bold;
}

.mdl-tabs__tab.is-active:after {
  height: 2px !important;
  background: var(--color-mainTextColor) !important;
}

.mdl-tabs__tab {
  padding: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 0.7em;
}

.QuizHome__opponentsText___2IEDi {
  margin: 7px 1% 7px 5%;
  font-size: 1.4em;
  line-height: 40px;
  vertical-align: middle;
  font-weight: 400;
  max-width: 80%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  text-align: left;
  width: 100%;
}
.QuizHome__opponentsText___2IEDi > span {
  width: 100%;
}

.QuizHome__opponentsTextFullWidth___1LWwW {
  margin: 7px 1% 7px 5%;
  font-size: 1.4em;
  line-height: 40px;
  vertical-align: middle;
  font-weight: 400;
  max-width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  text-align: left;
  width: 100%;
}
.QuizHome__opponentsTextFullWidth___1LWwW > span {
  width: 100%;
}

.mdl-list__item-primary-content-span {
  width: 100%;
}

.QuizHome__userText___wl3xy {
  margin: 7px 1% 7px 5%;
  font-size: 1.4em;
  line-height: 40px;
  vertical-align: middle;
  font-weight: 700;
  max-width: 80%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  text-align: left;
  width: 100%;
}
.QuizHome__userText___wl3xy > span {
  width: 100%;
}

.QuizHome__thumb___2Lbsi {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  /*border: 1px solid #ccc;*/
  -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
          border-radius: 50px;
  margin-right: 10px;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
}
.QuizHome__thumb___2Lbsi img {
  max-width: 40px;
}

.QuizHome__highScoreThumb___1OqUS {
  width: 40px;
  height: 40px;
  /*border: 1px solid #ccc;*/
  -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
          border-radius: 50px;
  margin-right: 5%;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
}
.QuizHome__highScoreThumb___1OqUS img {
  max-width: 40px;
}

.QuizHome__userOnline___2uMSw, .QuizHome__userOffline___qUo0a, .QuizHome__deviceOffline___1vGIB, .QuizHome__userInvisible___33IOy {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  border: 1px #efefef solid;
  float: left;
  position: relative;
  top: 2px;
  margin-right: 10px;
  -webkit-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
}

.QuizHome__userOnline___2uMSw {
  background-color: #7ed321;
}

.QuizHome__userOffline___qUo0a {
  background-color: #b9b9b9;
}

.QuizHome__deviceOffline___1vGIB {
  background-color: transparent;
  border-color: transparent;
}

.QuizHome__userInvisible___33IOy {
  background-color: transparent;
  font-size: 15px;
  padding-left: 7px;
}

.QuizHome__subjectButton___2kKBk {
  position: absolute;
  left: 50%;
  font-size: 14px;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}