.DrupalHtmlNugget__icon___1o20X {
  position: relative;
  display: inline-block;
  font-size: 30px !important;
}

.DrupalHtmlNugget__iconContainer___3N7hB {
  position: absolute;
  left: 30px;
  top: 30px;
}

.DrupalHtmlNugget__fullScreenOverlayImg___4ajIe {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.DrupalHtmlNugget__portraitOverlayImgContainer___3Or65 {
  margin: auto;
  height: 80%;
  width: 100%;
}

.DrupalHtmlNugget__portraitOverlayImgContainer___3Or65 > div {
  height: 100% !important;
  width: 100% !important;
}

.DrupalHtmlNugget__resetButton___1K0lo {
  width: 80% !important;
  height: 40px;
  margin: 10px 10% !important;
  display: block;
  background-color: var(--color-buttonBackground) !important;
  color: var(--color-buttonCaption) !important;
  -webkit-border-radius: 20px !important;
     -moz-border-radius: 20px !important;
          border-radius: 20px !important;
  -webkit-box-shadow: darkgray 2px 2px 5px 0px;
     -moz-box-shadow: darkgray 2px 2px 5px 0px;
          box-shadow: darkgray 2px 2px 5px 0px;
}

.DrupalHtmlNugget__zoomIcon___3UKcT {
  position: absolute;
  right: 20%;
  top: 0;
}

.DrupalHtmlNugget__zoomContainer___2q8wd {
  position: relative;
}