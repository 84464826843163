.DrupalFeedbackNugget__feedbackButton___2-dYW {
  background-color: var(--color-buttonBackground) !important;
  color: var(--color-buttonCaption) !important;
}

.DrupalFeedbackNugget__textfield___3NiGv {
  width: 100% !important;
}

.DrupalFeedbackNugget__feedbackLabel___Bw5gf {
  left: 10px !important;
}

.DrupalFeedbackNugget__button___cKal4 {
  float: right;
}

.DrupalFeedbackNugget__centeredButton___1kOy5 {
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.DrupalFeedbackNugget__feedbackContainer___3DjVz {
  padding-bottom: 70px;
  padding-top: 20px;
}

.DrupalFeedbackNugget__feedbackContainerInContainer___1O93J {
  width: 90% !important;
  margin: 0 5% !important;
}