.QuizLeagueHighScores__header___UwthH {
  background-color: rgba(255, 255, 255, 0.3);
}
.QuizLeagueHighScores__header___UwthH .QuizLeagueHighScores__headline___RX6z3 {
  font-size: 18px;
  padding-top: 10px;
  font-weight: 800;
}
.QuizLeagueHighScores__header___UwthH .QuizLeagueHighScores__region___qSFPV {
  margin-left: 14%;
  position: relative;
  top: -7px;
}
.QuizLeagueHighScores__header___UwthH .QuizLeagueHighScores__points___HJODM {
  color: white;
  text-align: right;
  font-size: 1em;
}
.QuizLeagueHighScores__header___UwthH .QuizLeagueHighScores__listItem___2Jzip {
  padding: 0 6px !important;
}

.QuizLeagueHighScores__innerContent___15zgB {
  max-height: -webkit-calc(100% - 110px);
  max-height: -moz-calc(100% - 110px);
  max-height: calc(100% - 110px);
}
.QuizLeagueHighScores__innerContent___15zgB .QuizLeagueHighScores__text___qj2TB {
  margin: 7px 4% 7px 5%;
}

.QuizLeagueHighScores__spanText___1M-HE {
  width: 88%;
}