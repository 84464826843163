.QuizTournamentTeamChooser__mainWrapper___1anxF {
  font-family: sans-serif;
  padding: 20px 20px 20px 20px;
  word-wrap: break-word;
  height: -webkit-calc(100vh - 64px);
  height: -moz-calc(100vh - 64px);
  height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
}

.QuizTournamentTeamChooser__lowerWrapper___3xYqg {
  text-align: center;
}

.QuizTournamentTeamChooser__heading___3rXYp {
  font-size: 20px;
  color: #0F3764;
  clear: both;
  text-align: center;
  position: relative;
  top: 10%;
}

.QuizTournamentTeamChooser__mainHeading___pQkFT {
  color: #0092b7;
  font-size: medium;
  font-weight: bold;
  position: relative;
  clear: both;
}

.QuizTournamentTeamChooser__subHeading___1KjqB {
  margin-top: 10%;
  font-weight: bold;
}

.QuizTournamentTeamChooser__teamWrapper___3IvkY {
  font-size: 16px;
  color: #0F3764;
  width: 50%;
  display: inline-block;
  padding: 2%;
  text-align: center;
}

.QuizTournamentTeamChooser__singleTeamWrapper___2ZAzA {
  width: 50%;
  display: inline-block;
  padding: 2%;
  margin-left: 25%;
  text-align: center;
}

.QuizTournamentTeamChooser__mainTeamWrapper___3uOW3 {
  position: relative;
  top: 15%;
  width: 100%;
  max-height: 80%;
  overflow-x: hidden;
  overflow-y: auto;
}

.QuizTournamentTeamChooser__teamAmountMembers___2nbRY {
  margin-top: 5%;
}

.QuizTournamentTeamChooser__teamMembers___Rq2A3 {
  font-size: 16px;
  color: #0F3764;
  font-weight: bold;
  margin: 20px 0;
}

.QuizTournamentTeamChooser__leaveBtn___7XvIR {
  float: right;
}

.QuizTournamentTeamChooser__infoBtn___10Q4a {
  white-space: nowrap;
  position: absolute;
  width: 60%;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 50px;
}

.QuizTournamentTeamChooser__infoBtn___10Q4a span {
  display: inline-block;
  width: 6px;
  height: 6px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  margin-right: 3px;
  background: #FFFFFF;
  -webkit-animation: QuizTournamentTeamChooser__wave___1Mkap 1.3s linear infinite;
     -moz-animation: QuizTournamentTeamChooser__wave___1Mkap 1.3s linear infinite;
       -o-animation: QuizTournamentTeamChooser__wave___1Mkap 1.3s linear infinite;
          animation: QuizTournamentTeamChooser__wave___1Mkap 1.3s linear infinite;
}
.QuizTournamentTeamChooser__infoBtn___10Q4a span:nth-child(2) {
  -webkit-animation-delay: -1.1s;
     -moz-animation-delay: -1.1s;
       -o-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.QuizTournamentTeamChooser__infoBtn___10Q4a span:nth-child(3) {
  -webkit-animation-delay: -0.9s;
     -moz-animation-delay: -0.9s;
       -o-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

@-webkit-keyframes QuizTournamentTeamChooser__wave___1Mkap {
  0%, 60%, 100% {
    -webkit-transform: initial;
            transform: initial;
  }
  30% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@-moz-keyframes QuizTournamentTeamChooser__wave___1Mkap {
  0%, 60%, 100% {
    -moz-transform: initial;
         transform: initial;
  }
  30% {
    -moz-transform: translateY(-10px);
         transform: translateY(-10px);
  }
}

@-o-keyframes QuizTournamentTeamChooser__wave___1Mkap {
  0%, 60%, 100% {
    -o-transform: initial;
       transform: initial;
  }
  30% {
    -o-transform: translateY(-10px);
       transform: translateY(-10px);
  }
}

@keyframes QuizTournamentTeamChooser__wave___1Mkap {
  0%, 60%, 100% {
    -webkit-transform: initial;
       -moz-transform: initial;
         -o-transform: initial;
            transform: initial;
  }
  30% {
    -webkit-transform: translateY(-10px);
       -moz-transform: translateY(-10px);
         -o-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}