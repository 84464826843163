.NuggetFront__front___HChLC {
  width: 100%;
  height: 100%;
  color: var(--color-tileFontColor);
  font-weight: var(--color-tileFontWeight);
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  cursor: pointer;
  -webkit-border-radius: var(--tiles-borderRadius);
     -moz-border-radius: var(--tiles-borderRadius);
          border-radius: var(--tiles-borderRadius);
  -webkit-font-smoothing: subpixel-antialiased;
}

.NuggetFront__unpublished___2Sdtj {
  opacity: 0.3;
}

.NuggetFront__keyword___24k7z {
  line-height: 125%;
}

.NuggetFront__title___1CasV {
  position: absolute;
  bottom: 0;
  left: 0;
}

.NuggetFront__subtitle___3X8ep {
  position: absolute;
  left: 0;
}

.NuggetFront__titleBackground___2RjPz {
  position: absolute;
  bottom: 0;
  left: 0;
}

.NuggetFront__titleBackgroundBlur___3dEql {
  position: absolute;
  bottom: 0;
  left: 0;
}

.NuggetFront__titleBackgroundOverlay___3QyQB {
  position: absolute;
  bottom: 0;
  left: 0;
}

.NuggetFront__icon___356Sm {
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__lockedIcon___3SxDW {
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 50%;
  height: 50%;
}

.NuggetFront__iconContainer___1LZkt {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__messageContainer___3YlCX {
  background: -webkit-radial-gradient(red, transparent);
  background: -moz- oldradial-gradient(red, transparent);
  background: -o-radial-gradient(red, transparent);
  background: radial-gradient(red, transparent);
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  height: 30px;
  width: 30px;
  -webkit-animation: NuggetFront__blink___1bYQE 1s infinite;
     -moz-animation: NuggetFront__blink___1bYQE 1s infinite;
       -o-animation: NuggetFront__blink___1bYQE 1s infinite;
          animation: NuggetFront__blink___1bYQE 1s infinite;
}

@-webkit-keyframes NuggetFront__blink___1bYQE {
  0%, 100% {
    background: -webkit-radial-gradient(red, transparent 50%);
    background: radial-gradient(red, transparent 50%);
  }
  10%, 90% {
    background: -webkit-radial-gradient(red, transparent 60%);
    background: radial-gradient(red, transparent 60%);
  }
  20%, 80% {
    background: -webkit-radial-gradient(red, transparent 70%);
    background: radial-gradient(red, transparent 70%);
  }
  30%, 70% {
    background: -webkit-radial-gradient(red, transparent 80%);
    background: radial-gradient(red, transparent 80%);
  }
  40%, 60% {
    background: -webkit-radial-gradient(red, transparent 90%);
    background: radial-gradient(red, transparent 90%);
  }
  50% {
    background: -webkit-radial-gradient(red, transparent 100%);
    background: radial-gradient(red, transparent 100%);
  }
}
.NuggetFront__messages___XWKSu {
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  position: absolute;
  top: 50%;
}

.NuggetFront__background___1fV6n {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__backgroundIE___1wtoP {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 0.5;
}

.NuggetFront__backgroundContainer___1YtAZ {
  -webkit-transition: width 400ms, height 400ms, top 400ms, left 400ms;
  -o-transition: width 400ms, height 400ms, top 400ms, left 400ms;
  -moz-transition: width 400ms, height 400ms, top 400ms, left 400ms;
  transition: width 400ms, height 400ms, top 400ms, left 400ms;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.NuggetFront__backgroundCopyright___3bDlk {
  position: absolute;
  right: 2%;
  bottom: 0;
  width: auto;
  height: auto;
  font-size: 9px;
  color: white;
}

.NuggetFront__backgroundContainer___1YtAZ:hover {
  width: 120% !important;
  height: 120% !important;
  left: -10%;
  top: -10%;
}

.NuggetFront__containerRight___143g_ {
  position: absolute;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__readIcon___KaR_E {
  display: inline-block;
  color: #FFFFFF;
  vertical-align: bottom;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__favIcon___iVTI2 {
  display: inline-block;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__tileArrow___2K9Jr {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--color-navbarBackground);
  color: #FFFFFF;
  font-size: 20px;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__arrowIcon___-roOH {
  font-size: 40px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__containerLeft___fHqHK {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 16px;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.NuggetFront__hidden___nyBU- {
  -webkit-transition: width 0.5s, height 0.5s;
  /* Safari */
  -o-transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  transition: width 0.5s, height 0.5s;
  height: 0;
  width: 0;
}

.NuggetFront__lockIcon___KxN7A {
  height: 18px;
  width: auto;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}

.NuggetFront__lockText___1mOJZ {
  color: black;
  display: inline-block;
  padding-left: 10px;
}

.NuggetFront__injectionContainer___yPW6A {
  position: absolute;
  z-index: 10;
  bottom: 10px;
  left: 10px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}