.Picker__wrapper___fl3qq {
  position: relative;
}

.Picker__emojiPickerWrapper___3fiNV {
  margin-top: 1rem;
  border: 1px solid #8b8b8b;
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  background: white;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  z-index: 100;
}

.Picker__emojiPicker___1OuLb {
  cursor: pointer;
  border: 1px solid #eee;
  padding: 0.4rem 0.8rem;
  margin: 0;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
}
.Picker__emojiPicker___1OuLb:hover {
  background: rgba(0, 0, 0, 0.1);
}

.Picker__emojiWrapper___1xWCX {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 10px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
     -moz-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  float: left;
  width: 93%;
  max-width: -webkit-calc(100% - 45px);
  max-width: -moz-calc(100% - 45px);
  max-width: calc(100% - 45px);
}

.Picker__emoji___3IuPg {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px;
}
.Picker__emoji___3IuPg img {
  height: 16px !important;
  width: 16px !important;
}

.Picker__tabs___x8ZDi {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  float: left;
  width: 7%;
  min-width: 40px;
  height: 100%;
  color: rgba(0, 0, 0, 0.2);
  border-right: 1px solid #d3d3d3;
}
.Picker__tabs___x8ZDi .Picker__active___aGnFs {
  color: black;
}

.Picker__title___26ehy {
  padding: 10px;
  margin: 0 auto;
  cursor: pointer;
  will-change: color;
  -webkit-transition: color 300ms;
  -o-transition: color 300ms;
  -moz-transition: color 300ms;
  transition: color 300ms;
}
.Picker__title___26ehy img {
  height: 24px !important;
  width: 24px !important;
}

.Picker__closeButton___-LWyw {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #FF7011;
}