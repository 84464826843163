.Voting__button___3AFMa {
  position: absolute;
  right: 0;
  bottom: 0;
}

.Voting__centeredButton___3r_sQ {
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.Voting__buttonContainer___3Om97 {
  position: relative;
  width: 100%;
  height: 50px;
}

.Voting__answerContainer___z7woy {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.Voting__pieChart___3ld_6 {
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  margin-top: 20px;
}

.Voting__legendEntry___3AQQK {
  margin-top: 10px;
}

.Voting__title___30ETt {
  color: #000000;
  text-align: center;
  font-size: 18px;
}

.Voting__teacherButton___1XCzY {
  width: 200px;
  height: 30px;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  border: none;
  color: #FFFFFF;
  background-color: red;
}

.Voting__info___2XEgg {
  width: 100%;
  color: #3e4146;
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 17px;
  z-index: 9;
  -webkit-transition: all 0.25s linear;
}