.MuiAlertHelper__dialog___1tM0A {
  font-size: 12px;
  line-height: 12px;
  z-index: 35002 !important;
}

.MuiAlertHelper__dialogWithSelects___GtnPe {
  text-align: center;
  font-size: 12px;
  line-height: 12px;
}

.MuiAlertHelper__paper___3z39h {
  display: block !important;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.MuiAlertHelper__widePaper___2bw6I {
  display: block !important;
  width: 80% !important;
  max-width: 860px !important;
}

.MuiAlertHelper__fullScreen___2gUSr {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-height: 100% !important;
}

.MuiAlertHelper__closeButton___1QA9z {
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;
  margin: 0 !important;
  position: absolute !important;
  top: 0;
  right: 5px;
  background-color: transparent;
  border: 0;
  padding: 10px !important;
  min-width: 30px !important;
  z-index: 100;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .MuiAlertHelper__closeButton___1QA9z {
    padding-top: -webkit-calc(10px + var(--safe-area-top-constant)) !important;
    padding-top: -moz-calc(10px + var(--safe-area-top-constant)) !important;
    padding-top: calc(10px + var(--safe-area-top-constant)) !important;
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .MuiAlertHelper__closeButton___1QA9z {
    padding-top: -webkit-calc(10px + var(--safe-area-top-env)) !important;
    padding-top: -moz-calc(10px + var(--safe-area-top-env)) !important;
    padding-top: calc(10px + var(--safe-area-top-env)) !important;
  }
}

.MuiAlertHelper__closeButtonExtraPadding___jUJau {
  top: var(--navBarHeightPx);
}

.MuiAlertHelper__closeButton___1QA9z:hover {
  background-color: rgba(0, 0, 0, 0);
}

.MuiAlertHelper__content___3o2Sk {
  /* these are the title and message containers */
  font-size: 15px;
  padding: 10px !important;
  overflow: hidden;
  /* div {
    max-height: 80vh;
    overflow: scroll
  } */
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .MuiAlertHelper__content___3o2Sk {
    padding-top: -webkit-calc(10px + var(--safe-area-top-constant)) !important;
    padding-top: -moz-calc(10px + var(--safe-area-top-constant)) !important;
    padding-top: calc(10px + var(--safe-area-top-constant)) !important;
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .MuiAlertHelper__content___3o2Sk {
    padding-top: -webkit-calc(10px + var(--safe-area-top-env)) !important;
    padding-top: -moz-calc(10px + var(--safe-area-top-env)) !important;
    padding-top: calc(10px + var(--safe-area-top-env)) !important;
  }
}
.MuiAlertHelper__content___3o2Sk.MuiAlertHelper__extraPadding___NnZZF {
  padding: 20px !important;
}
.MuiAlertHelper__content___3o2Sk img, .MuiAlertHelper__content___3o2Sk video {
  max-width: 100%;
}
.MuiAlertHelper__content___3o2Sk img {
  margin: 10px 0;
}
.MuiAlertHelper__content___3o2Sk label {
  margin-left: 0;
}

.MuiAlertHelper__contentSmallPadding___2RpPi {
  padding: 0 10px !important;
  margin-bottom: -10px;
  margin-top: -10px;
}

.MuiAlertHelper__centered___3i4hR {
  text-align: center;
}

.MuiAlertHelper__full___3a-V_ {
  width: 100%;
  height: 100%;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .MuiAlertHelper__full___3a-V_ {
    height: -webkit-calc(100% - var(--safe-area-top-constant));
    height: -moz-calc(100% - var(--safe-area-top-constant));
    height: calc(100% - var(--safe-area-top-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .MuiAlertHelper__full___3a-V_ {
    height: -webkit-calc(100% - var(--safe-area-top-env));
    height: -moz-calc(100% - var(--safe-area-top-env));
    height: calc(100% - var(--safe-area-top-env));
  }
}

.MuiAlertHelper__fullWithButtonsNoTitle___uP6fv {
  width: 100%;
  height: -webkit-calc(100% - 80px);
  height: -moz-calc(100% - 80px);
  height: calc(100% - 80px);
}

.MuiAlertHelper__fullWithButtons___3Q3jk {
  width: 100%;
  height: -webkit-calc(100% - 160px);
  height: -moz-calc(100% - 160px);
  height: calc(100% - 160px);
}

.MuiAlertHelper__title___dNu8g {
  /* these are the title and message containers */
  font-size: 18px;
  padding: 10px !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

.MuiAlertHelper__titlePadding___2vNhu {
  /* these are the title and message containers */
  font-size: 18px;
  padding: 10px !important;
  padding-top: 34px !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  height: 80px;
}

.MuiAlertHelper__noTitlePadding___2mUxJ {
  font-size: 18px;
  padding: 10px !important;
  padding-top: 24px !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

.MuiAlertHelper__error___4xR-7 {
  /* this is the error container */
  font-size: 15px;
  padding: 10px !important;
  text-align: center;
  color: red;
}

.MuiAlertHelper__buttonActions___2RrEg {
  /* this is the buttons container */
  /*border-top: 1px map_get($colors, dialogDivider) solid;*/
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  font-size: 15px;
  line-height: 17px;
  font-weight: bold;
  width: 100%;
  margin: 0 !important;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
     -moz-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
     -moz-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  height: 80px;
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.MuiAlertHelper__buttonActions___2RrEg button {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 5px 5px !important;
}

.MuiAlertHelper__buttonActionsERV___3bRop {
  /* this is the buttons container */
  /*border-top: 1px map_get($colors, dialogDivider) solid;*/
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  font-size: 15px;
  line-height: 17px;
  font-weight: bold;
  width: 100%;
  margin: 0 !important;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
     -moz-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
     -moz-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  height: 80px;
}
.MuiAlertHelper__buttonActionsERV___3bRop button {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 5px 5px !important;
}

.MuiAlertHelper__columnButtons___YDLfF {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px !important;
  height: auto !important;
}

.MuiAlertHelper__checkActions___6-dRT {
  width: 50%;
}

.MuiAlertHelper__fullWidthInput___3ZbIA {
  padding-top: 0;
}
.MuiAlertHelper__fullWidthInput___3ZbIA button {
  width: 100%;
}

.MuiAlertHelper__extraPadding___NnZZF {
  padding-top: 30px;
}

.MuiAlertHelper__welcomeButton___1buEn {
  display: block !important;
}

.MuiAlertHelper__bigButton___2Q857 button {
  font-size: 20px !important;
}

.MuiAlertHelper__center___2pguv div {
  margin: 0 auto !important;
}

.MuiAlertHelper__action___2nGoE {
  margin-top: 15px !important;
}

.MuiAlertHelper__textfield___2MOup {
  width: 60%;
}

.MuiAlertHelper__textfieldInput___ebzYh {
  margin-left: 0 !important;
}

.MuiAlertHelper__root___3YKRB {
  display: block !important;
  margin-top: 10px !important;
  z-index: 35002 !important;
  width: 60% !important;
  margin-left: 20% !important;
}

.MuiAlertHelper__checked___sjJQD {
  color: var(--color-ervBlue) !important;
}

.MuiAlertHelper__select___10W_p {
  width: 100%;
}

.MuiAlertHelper__titleImage___j2BDT {
  position: relative;
  width: 20%;
  height: auto;
  margin-left: 40%;
}

.MuiAlertHelper__selectContainer___2facP {
  width: 80%;
}

.MuiAlertHelper__noScroll___21kgl {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.MuiAlertHelper__buttonTitle___3kqUG {
  padding: 10px;
  color: black;
  font-weight: bold;
}