.DrupalLivestreamNugget__videodiv___38vE1 {
  position: relative;
  /** This is a strange bugfix. The original fullscreen icon on chrome at first makes the window fullscreen and then
      - on a second click - the video.

      In safari, however, the original fullscreen button opens the video in fullscreen with overlayed divs from somewhere
      else in the app if these divs are position:fixed under certain circumstances. If, however, this button is hidden by CSS
      like here, the button IS STILL visible but WORKS correctly.
  */
}
.DrupalLivestreamNugget__videodiv___38vE1 video, .DrupalLivestreamNugget__videodiv___38vE1 img {
  width: 100%;
}

.DrupalLivestreamNugget__blink___1-iFj {
  -webkit-animation: DrupalLivestreamNugget__blink___1-iFj 2s linear 0s infinite;
     -moz-animation: DrupalLivestreamNugget__blink___1-iFj 2s linear 0s infinite;
       -o-animation: DrupalLivestreamNugget__blink___1-iFj 2s linear 0s infinite;
          animation: DrupalLivestreamNugget__blink___1-iFj 2s linear 0s infinite;
}

@-moz-keyframes DrupalLivestreamNugget__blink___1-iFj {
  50% {
    opacity: 0;
  }
}

@-o-keyframes DrupalLivestreamNugget__blink___1-iFj {
  50% {
    opacity: 0;
  }
}

@keyframes DrupalLivestreamNugget__blink___1-iFj {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes DrupalLivestreamNugget__blink___1-iFj {
  50% {
    opacity: 0;
  }
}
.DrupalLivestreamNugget__videoButtonsSmall___3yCaE {
  padding-right: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 25px;
  margin-top: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.DrupalLivestreamNugget__videoButtonsSmall___3yCaE.DrupalLivestreamNugget__inactive___1n8GJ {
  opacity: 0.5;
}
.DrupalLivestreamNugget__videoButtonsSmall___3yCaE .DrupalLivestreamNugget__icon___jmXmD {
  /**
    As we cannot say for sure what is going on in the server deployed theme, we have to set all properties here
    that might affect the layout of this <img />
   */
  margin: 0 3px 5px 0;
  padding: 0;
  padding-top: 3px;
  border: 0;
  vertical-align: baseline;
  height: 17px;
  width: auto;
}
.DrupalLivestreamNugget__videoButtonsSmall___3yCaE .DrupalLivestreamNugget__caption___mc5u3 {
  color: white;
  float: left;
  line-height: 17px;
  margin: 0 10px;
  font-size: 12px;
}

.DrupalLivestreamNugget__videoButtons___FOE4Q {
  height: 33px;
  margin: 15px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.DrupalLivestreamNugget__videoButtons___FOE4Q.DrupalLivestreamNugget__inactive___1n8GJ {
  opacity: 0.5;
}
.DrupalLivestreamNugget__videoButtons___FOE4Q .DrupalLivestreamNugget__icon___jmXmD {
  /**
    As we cannot say for sure what is going on in the server deployed theme, we have to set all properties here
    that might affect the layout of this <img />
   */
  margin: 0 5px 10px 0;
  padding: 0;
  padding-top: 6px;
  border: 0;
  vertical-align: baseline;
  height: 25px;
  width: auto;
}
.DrupalLivestreamNugget__videoButtons___FOE4Q .DrupalLivestreamNugget__caption___mc5u3 {
  color: white;
  float: left;
  line-height: 25px;
  margin: 0 10px;
  font-size: 14px;
}

.DrupalLivestreamNugget__singleButton___Yt6Ph, .DrupalLivestreamNugget__fullscreenButton___21FIl, .DrupalLivestreamNugget__fullscreenButtonSmall___2su4X {
  padding: 0px;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  top: 2px;
  right: 0px;
  color: var(--color-buttonCaption);
}

.DrupalLivestreamNugget__fullscreenButton___21FIl {
  padding-top: 0px;
  top: 8px;
}

.DrupalLivestreamNugget__titleSmall___1dJGY {
  width: -webkit-calc(100% - 24px);
  width: -moz-calc(100% - 24px);
  width: calc(100% - 24px);
}

.DrupalLivestreamNugget__leftButton___3zN5- {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  color: var(--color-buttonCaption);
}

.DrupalLivestreamNugget__rightButton___3GL8b {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  color: var(--color-buttonCaption);
}

.DrupalLivestreamNugget__doubleButtonContainer___2sdEq {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
     -moz-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
  grid-gap: 3px;
}

.DrupalLivestreamNugget__fullscreenButton___21FIl, .DrupalLivestreamNugget__fullscreenButtonSmall___2su4X {
  position: absolute;
  right: 0;
  height: 27px;
  padding-right: 0;
}

.DrupalLivestreamNugget__fullscreenButtonSmall___2su4X {
  height: 25px;
}

.DrupalLivestreamNugget__chapterCaption___14ehN {
  font-size: 17pt;
  padding: 20px 0;
  font-weight: 400;
}

.DrupalLivestreamNugget__chapters___25Ff_ .DrupalLivestreamNugget__chapterPreview___etAtp {
  display: inline-block;
  overflow: hidden;
  margin: 0 10px 10px 0;
  -webkit-transform: translate3d(0, 0, 0);
}
.DrupalLivestreamNugget__chapters___25Ff_ .DrupalLivestreamNugget__chapterPreview___etAtp.DrupalLivestreamNugget__second___32HMi {
  margin-right: 0;
}
.DrupalLivestreamNugget__chapters___25Ff_ .DrupalLivestreamNugget__chapterPreview___etAtp.DrupalLivestreamNugget__fourth___7X94S {
  margin-right: 0;
}
.DrupalLivestreamNugget__chapters___25Ff_ .DrupalLivestreamNugget__chapterPreview___etAtp .DrupalLivestreamNugget__chapter___22xgE {
  position: relative;
  width: 100%;
  height: 100%;
  background: no-repeat center center;
}
.DrupalLivestreamNugget__chapters___25Ff_ .DrupalLivestreamNugget__chapterPreview___etAtp .DrupalLivestreamNugget__chapter___22xgE .DrupalLivestreamNugget__timeWrapper___2iZ0h {
  width: 100%;
  height: 100%;
  text-align: center;
}
.DrupalLivestreamNugget__chapters___25Ff_ .DrupalLivestreamNugget__chapterPreview___etAtp .DrupalLivestreamNugget__chapter___22xgE .DrupalLivestreamNugget__timeWrapper___2iZ0h .DrupalLivestreamNugget__time___2Tdv7 {
  display: inline-block;
  text-align: center;
  color: white;
  margin: 0 auto;
  padding: 3px 3px;
  background-color: rgba(126, 126, 126, 0.2);
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  font-size: 28px;
  font-weight: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.DrupalLivestreamNugget__chapters___25Ff_ .DrupalLivestreamNugget__chapterPreview___etAtp .DrupalLivestreamNugget__chapter___22xgE .DrupalLivestreamNugget__text___1cMa6 {
  position: absolute;
  bottom: 5px;
  left: 0;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  font-size: 18px;
  width: 90%;
  margin: 0 5%;
  padding-left: 5px;
  padding-right: 2px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  background-color: rgba(126, 126, 126, 0.5);
  text-align: left;
}

.DrupalLivestreamNugget__transcriptContainer___1D32T {
  max-width: 100%;
  font-size: 12px;
  padding: 20px 0;
  max-height: 200px;
  overflow: scroll;
}

.DrupalLivestreamNugget__transcriptTitle___14eoj {
  font-size: 16px;
  font-weight: bold;
  line-height: 60px;
}

.DrupalLivestreamNugget__transcriptArrow___3VxTP {
  width: 60px !important;
  height: 60px !important;
}