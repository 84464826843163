.DrupalUrlNugget__withBottomBar___3WOv3 {
  min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .DrupalUrlNugget__withBottomBar___3WOv3 {
    min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .DrupalUrlNugget__withBottomBar___3WOv3 {
    min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.DrupalUrlNugget__withoutBottomBar____jPyI {
  min-height: -webkit-calc(100vh - var(--navBarHeightPx));
  min-height: -moz-calc(100vh - var(--navBarHeightPx));
  min-height: calc(100vh - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .DrupalUrlNugget__withoutBottomBar____jPyI {
    min-height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .DrupalUrlNugget__withoutBottomBar____jPyI {
    min-height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}