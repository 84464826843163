.SettingsCordova__list___2lF1E {
  padding: 6px;
  font-size: 15px;
  line-height: 10px;
  margin: auto;
  max-width: 360px;
  width: 100%;
}

.SettingsCordova__contentButton___3-ZYX {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.SettingsCordova__contactContainer___24SLN {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.SettingsCordova__text___2VCXA {
  width: 80%;
  margin: 20px 10%;
  position: relative;
  color: #424242;
  text-align: left;
  height: 100vh;
}

.SettingsCordova__contact___1FDT7 {
  width: 100%;
  position: relative;
  margin-top: 20px;
  color: #424242;
}

.SettingsCordova__switch___nrsMJ {
  display: inline-block;
  width: 50px;
  top: 17px;
  right: 0;
  position: absolute;
}

.mdl-switch .mdl-switch__track {
  height: 24px;
  width: 50px;
  top: 0;
  background: #d3d3d3;
}
.mdl-switch .mdl-switch__thumb {
  background: #fff;
  border: 1px solid #d3d3d3;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
  top: 2px;
  left: 2px;
}

.mdl-switch.is-checked .mdl-switch__track {
  height: 24px;
  width: 50px;
  top: 0;
  background: var(--color-buttonBorder);
}
.mdl-switch.is-checked .mdl-switch__thumb {
  background: #fff;
  border: 1px solid var(--color-buttonBorder);
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
  top: 2px;
  left: 28px;
}

.SettingsCordova__switch___nrsMJ.SettingsCordova__is-checked___2BtcW {
  display: inline-block;
  width: 50px;
  margin-left: 20%;
}

.SettingsCordova__button___3Q0K0 {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  display: block;
  background-color: var(--color-buttonBorder);
  color: #FFFFFF;
  margin: auto;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

.SettingsCordova__settingsContainer___3e7kz {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  padding: 10px 0;
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .SettingsCordova__settingsContainer___3e7kz {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .SettingsCordova__settingsContainer___3e7kz {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.SettingsCordova__embeddedContainer___Bu6Zw {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 20px;
}

.SettingsCordova__ieContainer___3vnDW {
  height: 100vh;
}

.SettingsCordova__pickerTitle___3F21W {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding-bottom: 10px;
}

.SettingsCordova__pickerInfo___xW1qu {
  font-size: 12px;
  text-align: center;
  padding-bottom: 10px;
}