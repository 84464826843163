.DrupalFeedbackContainerNugget__feedbackcontainerInnerContainer___1RWIO {
  background-color: #ffffff;
  width: 90%;
  padding: 10px;
  margin: 0 auto;
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
}

.DrupalFeedbackContainerNugget__button___1GVuv {
  float: right;
  margin-top: 20px;
}

.DrupalFeedbackContainerNugget__centeredButton___1c2WW {
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-top: 20px;
}

.DrupalFeedbackContainerNugget__buttonContainer___8h7zR {
  overflow: hidden;
}

.DrupalFeedbackContainerNugget__feedbackTextfield___3nnlf {
  width: 100% !important;
  border: 1px solid var(--color-buttonBackground) !important;
  -webkit-border-radius: 4px !important;
     -moz-border-radius: 4px !important;
          border-radius: 4px !important;
  padding: 10px !important;
}