.Nugget__container___2be5t {
  height: auto;
  -webkit-transition: max-height 1s ease-in-out;
  -o-transition: max-height 1s ease-in-out;
  -moz-transition: max-height 1s ease-in-out;
  transition: max-height 1s ease-in-out;
  overflow: visible;
  max-height: 20000000px;
}

.Nugget__innerHidden___R036h {
  max-height: 0;
  -webkit-transition: max-height 1s ease-in-out;
  -o-transition: max-height 1s ease-in-out;
  -moz-transition: max-height 1s ease-in-out;
  transition: max-height 1s ease-in-out;
}

.Nugget__outerContainer___3f-r4 {
  -webkit-transition: border 1s ease-in-out 1s, margin 1s ease-in-out 1s, padding 1s ease-in-out 1s;
  -o-transition: border 1s ease-in-out 1s, margin 1s ease-in-out 1s, padding 1s ease-in-out 1s;
  -moz-transition: border 1s ease-in-out 1s, margin 1s ease-in-out 1s, padding 1s ease-in-out 1s;
  transition: border 1s ease-in-out 1s, margin 1s ease-in-out 1s, padding 1s ease-in-out 1s;
}

.Nugget__inFeedbackContainer___Upwzj {
  width: 90% !important;
  margin: 0 5% !important;
}

.Nugget__outerHidden___1gbTo {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  -webkit-transition: border 1s ease-in-out 0s, margin 1s ease-in-out 0s, padding 1s ease-in-out 0s;
  -o-transition: border 1s ease-in-out 0s, margin 1s ease-in-out 0s, padding 1s ease-in-out 0s;
  -moz-transition: border 1s ease-in-out 0s, margin 1s ease-in-out 0s, padding 1s ease-in-out 0s;
  transition: border 1s ease-in-out 0s, margin 1s ease-in-out 0s, padding 1s ease-in-out 0s;
}

.Nugget__info___3eVf3 {
  width: 100%;
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 17px;
  z-index: 9;
  padding-bottom: 20px;
}

.Nugget__likeContainer___31EHs {
  width: 100%;
  padding-top: 20px;
}

.Nugget__selfReflectContainer___WTqyz {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
}

.Nugget__svgLikeButton___1iEcZ {
  display: inline-block;
  width: 45px;
  height: auto;
}

.Nugget__likeButton___3FLjB {
  color: #7a7a7a;
  font-size: 30px;
  display: inline-block;
}

.Nugget__likeButtonActivated___3c74F {
  color: var(--color-navbarBackground);
  font-size: 30px;
  display: inline-block;
}

.Nugget__likeText___WmSWA {
  display: inline-block;
  font-size: 17px;
  padding-left: 20px;
  vertical-align: middle;
}

.Nugget__link___1unNh {
  font-weight: bold !important;
  color: var(--color-buttonBackground) !important;
  cursor: pointer;
}

.Nugget__card___1rVDT {
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
}

.Nugget__hidden___3GHdW {
  display: none;
}

.Nugget__tooltip___2km2h {
  font-size: 17px !important;
}

.Nugget__centered___2cv1r {
  text-align: center;
}

.Nugget__chatContainer___3tP5w {
  overflow: auto;
  height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .Nugget__chatContainer___3tP5w {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .Nugget__chatContainer___3tP5w {
    height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}