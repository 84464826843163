.DrupalFlipNugget__flip___1CtG5 {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
       perspective: 800px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.DrupalFlipNugget__flip___1CtG5 .DrupalFlipNugget__card___2ZxeA.DrupalFlipNugget__flipped___IEHg8 {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
       transform-style: preserve-3d;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
       transform: rotateX(-180deg);
}

.DrupalFlipNugget__flip___1CtG5 .DrupalFlipNugget__card___2ZxeA {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
       transform-style: preserve-3d;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  -moz-transition: 0.7s;
  transition: 0.7s;
}

.DrupalFlipNugget__flip___1CtG5 .DrupalFlipNugget__card___2ZxeA .DrupalFlipNugget__face___2EeAX {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
       backface-visibility: hidden;
  z-index: 2;
}

.DrupalFlipNugget__flip___1CtG5 .DrupalFlipNugget__card___2ZxeA .DrupalFlipNugget__back___2uTzb {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
       transform: rotateX(0deg);
  position: absolute;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.7s;
  transition: -webkit-transform 0.7s;
  -o-transition: -o-transform 0.7s;
  -moz-transition: transform 0.7s, -moz-transform 0.7s;
  transition: transform 0.7s;
  transition: transform 0.7s, -webkit-transform 0.7s, -moz-transform 0.7s, -o-transform 0.7s;
}

.DrupalFlipNugget__flip___1CtG5 .DrupalFlipNugget__card___2ZxeA .DrupalFlipNugget__front___1x2nm {
  -webkit-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
       transform: rotateX(-180deg);
  -webkit-transition: -webkit-transform 0.7s;
  transition: -webkit-transform 0.7s;
  -o-transition: -o-transform 0.7s;
  -moz-transition: transform 0.7s, -moz-transform 0.7s;
  transition: transform 0.7s;
  transition: transform 0.7s, -webkit-transform 0.7s, -moz-transform 0.7s, -o-transform 0.7s;
}

.DrupalFlipNugget__flip___1CtG5 .DrupalFlipNugget__card___2ZxeA.DrupalFlipNugget__flipped2___39aMn .DrupalFlipNugget__back___2uTzb {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
       transform: rotateX(180deg);
  position: absolute;
  -webkit-transition: -webkit-transform 0.7s;
  transition: -webkit-transform 0.7s;
  -o-transition: -o-transform 0.7s;
  -moz-transition: transform 0.7s, -moz-transform 0.7s;
  transition: transform 0.7s;
  transition: transform 0.7s, -webkit-transform 0.7s, -moz-transform 0.7s, -o-transform 0.7s;
}

.DrupalFlipNugget__flip___1CtG5 .DrupalFlipNugget__card___2ZxeA.DrupalFlipNugget__flipped2___39aMn .DrupalFlipNugget__front___1x2nm {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
       transform: rotateX(0deg);
  -webkit-transition: -webkit-transform 0.7s;
  transition: -webkit-transform 0.7s;
  -o-transition: -o-transform 0.7s;
  -moz-transition: transform 0.7s, -moz-transform 0.7s;
  transition: transform 0.7s;
  transition: transform 0.7s, -webkit-transform 0.7s, -moz-transform 0.7s, -o-transform 0.7s;
}