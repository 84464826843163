.QuizModeChooser__buttonContainer___2Fd2j {
  width: 100%;
  height: 75%;
  position: relative;
}

.QuizModeChooser__statsContainer___2ivT2 {
  width: 100%;
  height: 25%;
  position: relative;
  background-color: var(--color-statsButtonBackground);
}

.QuizModeChooser__statsIconContainer___2f1oE {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QuizModeChooser__statsIcon___1aRed {
  position: relative;
  width: 70%;
  height: 70%;
  margin-top: 15%;
}

.QuizModeChooser__modeName___ZWmUa {
  position: relative;
  display: block;
  width: 100%;
  height: 10%;
  font-size: 16px;
  color: var(--color-quizText);
  font-weight: bold;
}

.QuizModeChooser__subjectName___34w2W {
  position: relative;
  display: block;
  width: 100%;
  height: 10%;
  font-size: 20px;
  color: var(--color-quizText);
  font-weight: bold;
}

.QuizModeChooser__doubleButtonContainer___1_CJo {
  position: relative;
  display: block;
  height: 45%;
  width: 100%;
  padding: 10px;
}

.QuizModeChooser__singleButtonContainer___3Sdg7 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 50%;
  padding: 10%;
  width: 100%;
}

.QuizModeChooser__singlePlayerButton___1_72_ {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  overflow: hidden;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
}

.QuizModeChooser__battleButton___3WowO {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  overflow: hidden;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
}

.QuizModeChooser__buttonImage___1bhyw {
  width: 80%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QuizModeChooser__backText___3KrZb {
  padding-left: 3px;
  font-size: 18px;
}

.QuizModeChooser__backButton___3VSpX {
  cursor: pointer;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  padding: 5px;
  position: relative;
  top: 10px;
  width: 100px;
  left: 20px;
  height: 30px;
}

.QuizModeChooser__externalSite___3TMGt {
  height: 100%;
  width: 100%;
  background-color: var(--color-quizBackground);
}

.QuizModeChooser__iframeContainer___3gDyV {
  height: -webkit-calc(100% - 50px);
  height: -moz-calc(100% - 50px);
  height: calc(100% - 50px);
  margin-top: 20px;
}