DrupalQDNugget.DrupalQDNugget__scss___2NFzW.DrupalQDNugget__select___122Cc {
  min-width: 400px !important;
}

.DrupalQDNugget__learningProgress___1txJd {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 7px;
  height: 100%;
}

.DrupalQDNugget__learningProgressPie___2NwHO {
  width: 22px;
  height: 22px;
}

.DrupalQDNugget__categoryIcon___2QU4C {
  max-height: 50px;
  max-width: 50px;
  position: absolute;
  left: 15px;
  top: 50%;
  text-align: right;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.DrupalQDNugget__descriptionButton___37ehc {
  position: absolute;
  top: 5px;
  right: 10px;
}

.DrupalQDNugget__categoryContainer___rn2Qg {
  width: 100%;
  margin: 1px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  text-align: center;
  font-size: 1.1em !important;
  line-height: 1.1em !important;
  height: 80px !important;
  margin-top: 10px !important;
  text-transform: none !important;
}
.DrupalQDNugget__categoryContainer___rn2Qg.DrupalQDNugget__withIcon___3zeBQ {
  padding-left: 95px;
}

.DrupalQDNugget__categoryName___3mvrr {
  text-align: left;
  font-weight: 500;
  padding-bottom: 30px;
  max-width: 85%;
  position: absolute;
  top: 10px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}

.DrupalQDNugget__categoryHeader___gYon7 {
  width: 100%;
  margin: 10px 0;
}

.DrupalQDNugget__bottomInfo___2JDkI {
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  height: 30px;
  width: 300px;
  position: absolute;
  bottom: 6px;
}

.DrupalQDNugget__questionCount___2CJzF {
  margin-left: 32px;
  position: absolute;
  height: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
}

.DrupalQDNugget__questionBox___2pqu4 {
  margin: 0 auto;
}

.DrupalQDNugget__menuItem___2iMP1 {
  height: auto !important;
}

.DrupalQDNugget__chooser___RBpe1 {
  padding-bottom: 20px !important;
  width: 450px !important;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.DrupalQDNugget__greyBackground___1jDxB {
  background-color: #f7f7f7;
}