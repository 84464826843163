.DrupalReminderNugget__audioPlayer___1NR6X {
  margin-bottom: 20px;
  display: inline-block;
  width: 50%;
}

.DrupalReminderNugget__audioFileName___3odo_ {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.DrupalReminderNugget__audioFileInfo___gsDF0 {
  font-size: 12px;
  display: block;
  padding-top: 10px;
}

.DrupalReminderNugget__infoContainer___5Wp_g {
  display: inline-block;
  width: -webkit-calc(100% - 90px);
  width: -moz-calc(100% - 90px);
  width: calc(100% - 90px);
}

.DrupalReminderNugget__bigAudioPlayer___3IEn- {
  width: 100%;
  height: 50px;
  display: block;
  margin: 20px 0;
}

.DrupalReminderNugget__playButton___KN-Qk {
  color: var(--color-buttonBackground);
  height: 50px !important;
  width: 50px !important;
  display: inline-block !important;
  vertical-align: top !important;
  margin-right: 30px !important;
}

.DrupalReminderNugget__audioItem___1UNh5 {
  width: 100%;
  display: block;
  padding-bottom: 30px;
}

.DrupalReminderNugget__playingTitle___2dZtC {
  font-size: 22px;
  font-weight: bold;
  color: var(--color-buttonBackground);
}