.DrupalPDFNugget__pdfButton___1hHtJ {
  position: absolute;
  max-width: 50%;
  padding: 10px;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  color: var(--color-buttonCaption);
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  font-weight: bold;
}

.DrupalPDFNugget__pdfButtonWithIcon___2lmmF {
  position: absolute;
  max-width: 50%;
  padding: 10px;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  color: var(--color-buttonCaption);
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  font-weight: bold;
  padding-right: 35px;
}

.DrupalPDFNugget__downloadButton___2ShDM {
  position: absolute;
  width: 30px;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  color: var(--color-buttonCaption);
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 5px;
  font-weight: bold;
}

.DrupalPDFNugget__loadingIcon___2E84S {
  -webkit-animation: DrupalPDFNugget__blink___t-w5y 1s linear 0s infinite;
     -moz-animation: DrupalPDFNugget__blink___t-w5y 1s linear 0s infinite;
       -o-animation: DrupalPDFNugget__blink___t-w5y 1s linear 0s infinite;
          animation: DrupalPDFNugget__blink___t-w5y 1s linear 0s infinite;
}

@-moz-keyframes DrupalPDFNugget__blink___t-w5y {
  50% {
    opacity: 0;
  }
}

@-o-keyframes DrupalPDFNugget__blink___t-w5y {
  50% {
    opacity: 0;
  }
}

@keyframes DrupalPDFNugget__blink___t-w5y {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes DrupalPDFNugget__blink___t-w5y {
  50% {
    opacity: 0;
  }
}
.DrupalPDFNugget__pdfTitle___3ub1S {
  position: relative;
  max-width: 55%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  word-break: break-word;
}

.DrupalPDFNugget__listItem___1al-E {
  width: 100%;
  padding: 15px 5px;
  border-bottom: 1px solid var(--color-ergo-warmGrey4);
  position: relative;
  font-size: 15px;
  list-style: none;
}
.DrupalPDFNugget__listItem___1al-E ul {
  list-style: none;
  width: 100%;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}
.DrupalPDFNugget__listItem___1al-E li {
  width: 100%;
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

.DrupalPDFNugget__listItem___1al-E:first-child {
  border-top: 1px solid var(--color-ervLightGrey);
}

.DrupalPDFNugget__list___3Hvy0 {
  width: 94%;
  margin: 0 3%;
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

.DrupalPDFNugget__itemArrow___2R2xZ {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 3%;
  color: var(--color-quizMainColor);
}

.DrupalPDFNugget__singleFileContainer___5-M5q {
  width: 100%;
  padding: 10px;
  height: auto;
}

.DrupalPDFNugget__singleFileTitle___3SW3_ {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  padding-bottom: 5px;
  position: relative;
  display: block;
}

.DrupalPDFNugget__singleFileName___3V3yO {
  width: 80%;
  position: relative;
  display: inline-block;
  float: left;
}

.DrupalPDFNugget__singleFileDownloadButton___2HcYN {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  float: right;
  background-color: var(--color-buttonBackground);
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
}

.DrupalPDFNugget__singleFileHeader___2oExf {
  width: 100%;
  position: relative;
  display: block;
  height: 40px;
  vertical-align: top;
}

.DrupalPDFNugget__singleFileInfo___1fuYo {
  width: 100%;
  position: relative;
  display: block;
}

.DrupalPDFNugget__singleFileDivider___21FDe {
  color: lightgrey;
  height: 2px;
}

.DrupalPDFNugget__nuggetInfo___kOG-H {
  padding-bottom: 30px;
}