.DrupalWizardNugget__navigationContainer___sHAEY {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-top: 30px;
}

.DrupalWizardNugget__buttonLeft___11ElM {
  font-size: 30px;
  left: 0;
  bottom: 0;
  display: inline-block;
  position: absolute;
}

.DrupalWizardNugget__arrowIcon___jUiKZ {
  padding: 2px;
}

.DrupalWizardNugget__buttonRight___acYy6 {
  font-size: 30px;
  right: 0;
  bottom: 0;
  display: inline-block;
  position: absolute;
}

.DrupalWizardNugget__bulletContainer___qtl3W {
  font-size: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  display: inline-block;
  position: absolute;
  width: 100%;
  text-align: center;
}

.DrupalWizardNugget__nuggetBullet____iM6Q {
  display: inline-block;
  height: 7px;
  width: 7px;
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  margin: 3px;
}

.DrupalWizardNugget__questionHeader___1d8Jg {
  width: 100%;
  font-size: 15px;
  color: #4a4a4a !important;
}

.DrupalWizardNugget__buttonContainer___1BJ2A {
  padding-top: 10px;
  width: 100%;
  position: relative;
}

.DrupalWizardNugget__nextButton___1yq0F {
  width: 80% !important;
  height: 40px;
  margin: 10px 10% !important;
  display: block;
  background-color: var(--color-buttonBackground) !important;
  color: var(--color-buttonCaption) !important;
  -webkit-border-radius: 20px !important;
     -moz-border-radius: 20px !important;
          border-radius: 20px !important;
  -webkit-box-shadow: darkgray 2px 2px 5px 0px;
     -moz-box-shadow: darkgray 2px 2px 5px 0px;
          box-shadow: darkgray 2px 2px 5px 0px;
}

.DrupalWizardNugget__backButton___2AWK_ {
  width: 80% !important;
  height: 40px;
  margin: 10px 10% !important;
  display: block;
  background-color: var(--color-backButtonBackground) !important;
  color: var(--color-buttonCaption) !important;
  -webkit-border-radius: 20px !important;
     -moz-border-radius: 20px !important;
          border-radius: 20px !important;
  -webkit-box-shadow: darkgray 2px 2px 5px 0px;
     -moz-box-shadow: darkgray 2px 2px 5px 0px;
          box-shadow: darkgray 2px 2px 5px 0px;
}

.DrupalWizardNugget__nextButtonDisabled___3XsuH, .DrupalWizardNugget__backButtonDisabled___2Nzui {
  width: 80% !important;
  margin: 10px 10% !important;
  height: 40px;
  display: block;
  background-color: var(--color-inactiveButton) !important;
  color: var(--color-buttonCaption) !important;
  -webkit-border-radius: 20px !important;
     -moz-border-radius: 20px !important;
          border-radius: 20px !important;
  -webkit-box-shadow: darkgray 2px 2px 5px 0px;
     -moz-box-shadow: darkgray 2px 2px 5px 0px;
          box-shadow: darkgray 2px 2px 5px 0px;
}