.ChatMessage__outer___VeSM2 {
  position: relative;
}

.ChatMessage__message___-B6_T {
  color: black;
  position: relative;
}
.ChatMessage__message___-B6_T.ChatMessage__me___109Xp {
  background-color: var(--color-chatColor);
  -webkit-transition: background-color 300ms;
  -o-transition: background-color 300ms;
  -moz-transition: background-color 300ms;
  transition: background-color 300ms;
  -webkit-border-radius: 10px 20px 0 10px;
     -moz-border-radius: 10px 20px 0 10px;
          border-radius: 10px 20px 0 10px;
  margin: 10px 10px 10px auto;
  padding: 10px 20px 30px 20px;
  width: 600px;
  max-width: 60%;
  border: 1px var(--color-chatColor) solid;
}
.ChatMessage__message___-B6_T.ChatMessage__me___109Xp .ChatMessage__name___3mq0k {
  color: white;
}
.ChatMessage__message___-B6_T.ChatMessage__me___109Xp .ChatMessage__text___Xn4K9 {
  margin-top: 3px;
  color: white;
}
.ChatMessage__message___-B6_T.ChatMessage__me___109Xp .ChatMessage__date___3JEOd {
  float: right;
  color: white;
}
.ChatMessage__message___-B6_T.ChatMessage__me___109Xp .ChatMessage__expertIcon___12BoM {
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
}
.ChatMessage__message___-B6_T.ChatMessage__others___28NRo {
  background-color: white;
  -webkit-border-radius: 20px 10px 10px 0;
     -moz-border-radius: 20px 10px 10px 0;
          border-radius: 20px 10px 10px 0;
  margin: 10px 10px auto 10px;
  padding: 20px 10px 30px 20px;
  width: 600px;
  max-width: 60%;
  border: 1px var(--color-chatColor) solid;
}
.ChatMessage__message___-B6_T.ChatMessage__others___28NRo .ChatMessage__name___3mq0k {
  font-weight: 800;
}
.ChatMessage__message___-B6_T.ChatMessage__others___28NRo .ChatMessage__text___Xn4K9 {
  margin-top: 3px;
}
.ChatMessage__message___-B6_T.ChatMessage__others___28NRo .ChatMessage__date___3JEOd {
  float: right;
  color: #737373;
}
.ChatMessage__message___-B6_T.ChatMessage__others___28NRo .ChatMessage__expertIcon___12BoM {
  color: white;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 20px;
}
.ChatMessage__message___-B6_T.ChatMessage__expert___3GyJp {
  border: 2px var(--color-chatExpertColor) solid;
  background-color: var(--color-chatExpertColor);
}
.ChatMessage__message___-B6_T.ChatMessage__expert___3GyJp .ChatMessage__me___109Xp {
  -webkit-border-radius: 10px 20px 0 10px;
     -moz-border-radius: 10px 20px 0 10px;
          border-radius: 10px 20px 0 10px;
  margin: 10px 10px 10px auto;
  padding: 10px 20px 30px 20px;
  width: 600px;
  max-width: 60%;
}
.ChatMessage__message___-B6_T.ChatMessage__expert___3GyJp .ChatMessage__others___28NRo {
  margin: 10px 10px 10px 50px;
  -webkit-border-radius: 20px 10px 10px 0;
     -moz-border-radius: 20px 10px 10px 0;
          border-radius: 20px 10px 10px 0;
  padding: 20px 10px 30px 20px;
  width: 600px;
  max-width: 60%;
}
.ChatMessage__message___-B6_T.ChatMessage__expert___3GyJp .ChatMessage__name___3mq0k {
  color: white;
}
.ChatMessage__message___-B6_T.ChatMessage__expert___3GyJp .ChatMessage__text___Xn4K9 {
  margin-top: 3px;
  color: white;
}
.ChatMessage__message___-B6_T.ChatMessage__expert___3GyJp .ChatMessage__date___3JEOd {
  float: right;
  color: white;
}
.ChatMessage__message___-B6_T.ChatMessage__expert___3GyJp .ChatMessage__expertIcon___12BoM {
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
}
.ChatMessage__message___-B6_T .ChatMessage__expertName___13ww7 {
  font-weight: 800;
  color: white;
}
.ChatMessage__message___-B6_T.ChatMessage__presenceMessage___3DCA3 {
  background-color: rgba(0, 0, 0, 0);
  width: 80%;
  margin: 0 auto;
  color: #5a5a5a;
  text-align: center;
  font-size: 12px;
  padding: 10px;
}
.ChatMessage__message___-B6_T .ChatMessage__quoteButton___3Ez57 {
  position: absolute;
  right: 10px;
  top: 20px;
}
.ChatMessage__message___-B6_T .ChatMessage__quoteButtonExpert___2TERG {
  position: absolute;
  right: 10px;
  top: 40px;
}
.ChatMessage__message___-B6_T .ChatMessage__quote___2kbjI {
  width: 90%;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  padding: 5px;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  font-style: italic;
}