.DrupalTerminNugget__terminButton___3ZFGA {
  position: relative;
  max-width: 50%;
  padding: 10px;
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  background-color: var(--color-buttonBackground);
  color: var(--color-buttonCaption);
  margin-top: 20px;
  margin-bottom: 20px;
}

.DrupalTerminNugget__listItem___20ITR {
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid var(--color-ervLightGrey);
  position: relative;
  font-size: 15px;
  list-style: none;
}
.DrupalTerminNugget__listItem___20ITR ul {
  list-style: none;
  width: 100%;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}
.DrupalTerminNugget__listItem___20ITR li {
  width: 100%;
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

.DrupalTerminNugget__listItem___20ITR:first-child {
  border-top: 1px solid var(--color-ervLightGrey);
}

.DrupalTerminNugget__list___1AW5b {
  width: 94%;
  margin: 0 3%;
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

.DrupalTerminNugget__itemArrow___1OTKa {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 3%;
  color: var(--color-quizMainColor);
}