.ErvFront__front____x4Nc {
  width: 100%;
  height: 100%;
  color: var(--color-tileFontColor);
  font-weight: var(--color-tileFontWeight);
  will-change: opacity, color;
}

.ErvFront__expandedFront___18WHe {
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

.ErvFront__lectureContainer___1aevK {
  width: 100vw;
  height: 100%;
  min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
  min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx));
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .ErvFront__lectureContainer___1aevK {
    min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
    min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-constant) - var(--safe-area-bottom-constant));
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .ErvFront__lectureContainer___1aevK {
    min-height: -webkit-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: -moz-calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
    min-height: calc(100vh - var(--bottomBarHeightPx) - var(--navBarHeightPx) - var(--safe-area-top-env) - var(--safe-area-bottom-env));
  }
}

.ErvFront__lectureContainerNoBottomBar___2X0jT {
  width: 100vw;
  height: 100%;
  min-height: -webkit-calc(100vh - var(--navBarHeightPx) + 2px);
  min-height: -moz-calc(100vh - var(--navBarHeightPx) + 2px);
  min-height: calc(100vh - var(--navBarHeightPx) + 2px);
}
@supports (margin-bottom: constant(safe-area-inset-top)) {
  .ErvFront__lectureContainerNoBottomBar___2X0jT {
    min-height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) + 2px);
    min-height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) + 2px);
    min-height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-constant) + 2px);
  }
}
@supports (margin-bottom: env(safe-area-inset-top)) {
  .ErvFront__lectureContainerNoBottomBar___2X0jT {
    min-height: -webkit-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) + 2px);
    min-height: -moz-calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) + 2px);
    min-height: calc(100vh - var(--navBarHeightPx) - var(--safe-area-top-env) + 2px);
  }
}

.ErvFront__keyword___23kQN {
  line-height: 125%;
}

.ErvFront__title___1SuhT {
  position: absolute;
  font-weight: 500;
  will-change: color;
  max-width: 100%;
  word-break: break-word;
}

.ErvFront__subtitle___3ibgA {
  position: absolute;
  bottom: 0;
  left: 0;
  will-change: color;
}

.ErvFront__titleBackground___4-2qt {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__backgroundCopyright___2GIsA {
  position: absolute;
  right: 2%;
  bottom: 0;
  width: auto;
  height: auto;
  font-size: 7px;
  color: white;
}

.ErvFront__titleBackgroundBlur___OFBbw {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__titleBackgroundOverlay___3QKC7 {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-titleBackgroundOverlay);
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__icon___14eP_ {
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ErvFront__icon___14eP_ svg {
  height: 100%;
  width: 100%;
}
.ErvFront__icon___14eP_ svg path, .ErvFront__icon___14eP_ svg rect, .ErvFront__icon___14eP_ svg polygon, .ErvFront__icon___14eP_ svg circle {
  will-change: fill;
  fill: var(--color-ervBlue);
}

.ErvFront__iconNewNoFill___1Uf-N {
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ErvFront__iconNewNoFill___1Uf-N svg {
  height: 100%;
  width: 100%;
}

.ErvFront__iconNew___2apyX {
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ErvFront__iconNew___2apyX svg {
  height: 100%;
  width: 100%;
}
.ErvFront__iconNew___2apyX svg path, .ErvFront__iconNew___2apyX svg rect, .ErvFront__iconNew___2apyX svg polygon, .ErvFront__iconNew___2apyX svg circle {
  will-change: fill;
  fill: var(--color-iconColor);
}

.ErvFront__iconWhite___3fMMS {
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ErvFront__iconWhite___3fMMS svg {
  height: 100%;
  width: 100%;
}
.ErvFront__iconWhite___3fMMS svg path, .ErvFront__iconWhite___3fMMS svg rect, .ErvFront__iconWhite___3fMMS svg polygon, .ErvFront__iconWhite___3fMMS svg circle {
  will-change: fill;
  fill: #FFFFFF;
}

.ErvFront__iconDisabled___3nHZR {
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ErvFront__iconDisabled___3nHZR svg {
  height: 100%;
  width: 100%;
}
.ErvFront__iconDisabled___3nHZR svg path, .ErvFront__iconDisabled___3nHZR svg rect, .ErvFront__iconDisabled___3nHZR svg polygon, .ErvFront__iconDisabled___3nHZR svg circle {
  will-change: fill;
  fill: var(--color-ervGrey);
}

.ErvFront__iconContainer___1Nh8R, .ErvFront__dayContainer___2DeOx {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__background___3qqp1 {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__containerRight___vV_Wq {
  position: absolute;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__tileArrow___2N8wd {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #FFFFFF;
  font-size: 20px;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  text-align: center;
  vertical-align: middle;
}

.ErvFront__arrowIcon___2amuP {
  font-size: 40px;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__containerLeft___FI6rk {
  position: absolute;
  bottom: 5px;
  left: 7px;
  font-size: 12px;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__readIcon___3Veue {
  display: inline-block;
  color: inherit;
  vertical-align: bottom;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__favIcon___1scCf {
  display: inline-block;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__hidden___20Qwt {
  -webkit-transition: width 0.5s, height 0.5s;
  /* Safari */
  -o-transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  transition: width 0.5s, height 0.5s;
  height: 0;
  width: 0;
  will-change: width, height;
}

.ErvFront__frontContainer___1HxXX {
  width: 100%;
}

.ErvFront__closeButton___2A5Ax {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 20;
}

.ErvFront__unpublished___3O0_g {
  opacity: 0.3;
}

.ErvFront__bookedMarker___3Te2J {
  width: 150%;
  height: 30px;
  background-color: #009de0;
  position: absolute;
  top: 23%;
  left: -50%;
  z-index: 20;
  -webkit-transform: rotate(-45deg);
     -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
       -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border: 2px solid white;
  vertical-align: middle;
}

.ErvFront__bookedText___2mwlr {
  color: white;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  margin-top: -2px;
}

.ErvFront__zoom___3HVUy {
  height: 30px !important;
  width: 30px !important;
  color: white;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 20;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ErvFront__newBanner___3BKKY {
  width: 150px;
  height: 20px;
  color: white;
  background-color: var(--color-ervBlue);
  position: absolute;
  font-weight: bold;
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
       -o-transform: rotate(45deg);
          transform: rotate(45deg);
  right: -25%;
  top: 7%;
  text-align: center;
  padding: 2px;
}