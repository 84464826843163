.DrupalRubric__eventDatesOuter___7xjKl {
  -webkit-transition: opacity 300ms, height 200ms 150ms;
  -o-transition: opacity 300ms, height 200ms 150ms;
  -moz-transition: opacity 300ms, height 200ms 150ms;
  transition: opacity 300ms, height 200ms 150ms;
  opacity: 0;
}
.DrupalRubric__eventDatesOuter___7xjKl.DrupalRubric__visible___3WSGC {
  opacity: 1;
}

.DrupalRubric__eventDates___9Ed6W {
  margin: 10px;
}

.DrupalRubric__combinedDateBox___bJwzo {
  margin-left: 4px;
}

.DrupalRubric__combinedDateBox___bJwzo {
  margin-bottom: 10px;
}

.DrupalRubric__combinedDate___37WKZ {
  padding-right: 20px;
}

.DrupalRubric__container___1Ebqu {
  position: relative;
  float: left;
}

.DrupalRubric__tile___2BMvB {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-border-radius: var(--tiles-borderRadius);
     -moz-border-radius: var(--tiles-borderRadius);
          border-radius: var(--tiles-borderRadius);
  z-index: 0;
  overflow: hidden;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms;
  opacity: 1;
}
.DrupalRubric__tile___2BMvB .DrupalRubric__front___2OO8M, .DrupalRubric__tile___2BMvB .DrupalRubric__back___1I661 {
  display: block;
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100%;
}
.DrupalRubric__tile___2BMvB .DrupalRubric__back___1I661 {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  background-color: var(--color-tileBackgroundColor);
  -webkit-transition: background-color 1500ms;
  -o-transition: background-color 1500ms;
  -moz-transition: background-color 1500ms;
  transition: background-color 1500ms;
}
.DrupalRubric__tile___2BMvB .DrupalRubric__backIE___3TXMN {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  background-color: var(--color-tileBackgroundColor);
  z-index: -1;
  -webkit-transition: background-color 1500ms;
  -o-transition: background-color 1500ms;
  -moz-transition: background-color 1500ms;
  transition: background-color 1500ms;
}

.DrupalRubric__infoText___z24__ {
  text-align: center;
  margin-bottom: 20px;
}

.DrupalRubric__infoTextLeft___342i9 {
  text-align: left;
  margin-bottom: 20px;
}

.DrupalRubric__tableContainer___21nvU {
  width: 100%;
}

.DrupalRubric__bold___mmKWO {
  font-weight: bold;
  width: 160px;
}

.DrupalRubric__paddingBottom___1i-w- {
  padding-bottom: 10px;
}

.DrupalRubric__eventSeriesTiles___189cj {
  display: block;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100%;
}

.DrupalRubric__cardWithMargin___1k81B {
  margin-bottom: 20px;
}

.DrupalRubric__eventSeriesTitle___3FGMG {
  font-size: 16px;
  font-weight: bold;
}

.DrupalRubric__eventSeriesHeader___1m_Ao {
  margin-bottom: 20px;
  width: 100%;
}

.DrupalRubric__closeIcon___3sWk- {
  float: right;
  font-size: 16px;
}

.DrupalRubric__rubricCard___3JAWJ {
  margin: 10px;
}

.DrupalRubric__venueAddress___1OpMG p {
  margin-bottom: 0;
  line-height: initial;
}

.DrupalRubric__bookedEventsContainer___DY852 {
  margin: 10px;
}

.DrupalRubric__eventsTitle____hx0u {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 10px;
}

.DrupalRubric__bookedEventSeriesTitle___1ALle {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.DrupalRubric__bookedEventSeriesInfo___3rrfE {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.DrupalRubric__singleFileContainer___36nvH {
  width: 100%;
  padding: 10px;
  height: auto;
}

.DrupalRubric__singleFileTitle___3Za8j {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  padding-bottom: 5px;
  position: relative;
  display: block;
}

.DrupalRubric__singleFileName___1bAc2 {
  width: 80%;
  position: relative;
  display: inline-block;
  float: left;
}

.DrupalRubric__singleFileDownloadButton___3kF7G {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  float: right;
  background-color: var(--color-buttonBackground);
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
}

.DrupalRubric__singleFileHeader___eEHqX {
  width: 100%;
  position: relative;
  display: block;
  height: 40px;
  vertical-align: top;
}

.DrupalRubric__singleFileInfo___1Xxz4 {
  width: 100%;
  position: relative;
  display: block;
}

.DrupalRubric__singleFileDivider___nCtXu {
  color: lightgrey;
  height: 2px;
}