.QDConnect__mainWrapper___JFXtT {
  font-family: sans-serif;
  padding: 20px 20px 20px 20px;
  word-wrap: break-word;
  height: -webkit-calc(100vh - 64px);
  height: -moz-calc(100vh - 64px);
  height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
}

.QDConnect__lowerWrapper___3MDXx {
  text-align: center;
}

.QDConnect__codeInput___3esdi {
  font-size: 20px;
}

.QDConnect__alertLink___1Yv9x {
  color: #000000;
  display: inline-block;
  margin-bottom: 20px;
}

.QDConnect__alertLink___1Yv9x:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: blue;
     -moz-text-decoration-color: blue;
          text-decoration-color: blue;
}

.QDConnect__title___36S7V {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  color: #0066b3;
  position: relative;
  padding: 20px 0 20px 0;
}

.QDConnect__button___3VqPF {
  display: block;
  width: auto !important;
}