.QuizRoundHeader__container___1gBwh {
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 18px;
  overflow-y: hidden;
  height: 50px;
  position: relative;
}

.QuizRoundHeader__names___1qUvK {
  max-width: -webkit-calc(100% - 125px);
  max-width: -moz-calc(100% - 125px);
  max-width: calc(100% - 125px);
  width: 100%;
}

.QuizRoundHeader__points___1o8gs {
  display: inline-block;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 16px;
  top: 17px;
}

.QuizRoundHeader__singlePlayerPoints___38nTn {
  display: inline-block;
  position: absolute;
  left: 0;
  font-size: 16px;
  top: 17px;
}

.QuizRoundHeader__opponent___2fOvr {
  display: inline-block;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  max-width: 25%;
}
.QuizRoundHeader__opponent___2fOvr.QuizRoundHeader__browser___xJ1JY {
  max-width: -webkit-calc((100% - 140px) / 2);
  max-width: -moz-calc((100% - 140px) / 2);
  max-width: calc((100% - 140px) / 2);
  font-size: 15px;
}

.QuizRoundHeader__colon___1HCqa {
  display: inline-block;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  max-width: 10px;
  margin: auto 4px auto 2px;
}

.QuizRoundHeader__answers___3bysH {
  display: inline-block;
  position: relative;
  float: right;
  width: 50%;
  max-width: 120px;
  text-align: right;
  margin-right: 2px;
}

.QuizRoundHeader__singlePlayerAnswers___48RK4 {
  display: inline-block;
  position: relative;
  float: right;
  width: 100%;
  text-align: center;
}

.QuizRoundHeader__answerStatus___1uAdY {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  overflow: hidden;
}

.QuizRoundHeader__answerStatusContainer___261jl {
  margin-left: 5px;
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  max-width: 19%;
}

.QuizRoundHeader__answerStatusInner___2_Bvc {
  vertical-align: top;
  text-align: center;
  font-size: 17px;
  line-height: 19px;
}

.QuizRoundHeader__answerStatusFeedback___1YbRP {
  width: 100%;
  height: 100%;
  position: relative;
}

.QuizRoundHeader__sequenceOuter___nhsB2 {
  float: right;
  position: relative;
  width: 0;
  z-index: 0;
}

.QuizRoundHeader__sequence___3piQz {
  position: absolute;
  height: 24px;
  top: 0;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  background-color: white;
}