.AudioPlayer__bar___1cI_l {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.AudioPlayer__barTime___3Qs79 {
  color: black;
  font-size: 16px;
  width: 40px;
}

.AudioPlayer__barProgress___1L8aa {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
     -moz-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  margin: 0 20px;
  height: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  background-color: grey;
}

.AudioPlayer__barProgressHandle___3jggH {
  position: relative;
  height: 16px;
  width: 16px;
  border: 1.5px solid var(--color-buttonBackground);
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background-color: white;
}

.AudioPlayer__controlsContainerNo10___3-thE {
  display: -ms-grid;
  display: grid;
  position: relative;
  width: 100%;
  padding: 20px;
  -ms-grid-columns: (1fr)[5];
      grid-template-columns: repeat(5, 1fr);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.AudioPlayer__controlsContainer___2exny {
  display: -ms-grid;
  display: grid;
  position: relative;
  width: 100%;
  padding: 20px;
  -ms-grid-columns: (1fr)[7];
      grid-template-columns: repeat(7, 1fr);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.AudioPlayer__playButton___2_H_e {
  background-color: var(--color-buttonBackground);
  fill: white !important;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  height: 30px !important;
  width: 30px !important;
  display: -ms-grid !important;
  display: grid !important;
}

.AudioPlayer__playButtonDisabled___1JLeB {
  background-color: grey;
  fill: white !important;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  height: 30px !important;
  width: 30px !important;
  display: -ms-grid !important;
  display: grid !important;
}

.AudioPlayer__playlistPlayButton___2LkHN {
  background-color: var(--color-buttonBackground);
  fill: white !important;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  height: 30px !important;
  width: 30px !important;
  display: inline-block !important;
  vertical-align: top !important;
  margin-right: 15px !important;
}

.AudioPlayer__playingTitle___1QlLf {
  font-size: 22px;
  font-weight: bold;
  color: var(--color-buttonBackground);
}

.AudioPlayer__audioPlayer___11mDU {
  margin-bottom: 20px;
  display: inline-block;
  width: 50%;
}

.AudioPlayer__audioFileName___2jA9P {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.AudioPlayer__audioFileInfo___3TI2S {
  font-size: 12px;
  display: block;
  padding-top: 10px;
}

.AudioPlayer__infoContainer___Vp55d {
  display: inline-block;
  width: -webkit-calc(100% - 90px);
  width: -moz-calc(100% - 90px);
  width: calc(100% - 90px);
}

.AudioPlayer__bigAudioPlayer___2HaYl {
  width: 100%;
  height: 50px;
  display: block;
  margin: 20px 0;
}

.AudioPlayer__audioItem___2cMlg {
  width: 100%;
  display: block;
  padding-bottom: 30px;
}

.AudioPlayer__playerContainer___32IaH {
  position: absolute;
  width: 100%;
  left: 50%;
  bottom: 160px;
  padding: 20px 5%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.AudioPlayer__playerContainerOverBackground___1ZLG8 {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  padding: 20px 5%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
       -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.AudioPlayer__playerContainerOverInfo___4xPXx {
  position: absolute;
  width: 100%;
  left: 50%;
  bottom: -10px;
  padding: 20px 5%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
}

.AudioPlayer__playerContainerNoImage___kGuOm {
  position: relative;
  width: 100%;
}

.AudioPlayer__audioContainer___1Xf_f {
  position: relative;
  width: 100%;
}

.AudioPlayer__backgroundImage___3x-lS {
  width: 100%;
  margin-bottom: 20px;
}